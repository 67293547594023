import React from 'react'
import Dot from '../../../Icons/YellowDot'

const SalariesChartComponent = ({
  number,
  title,
  color1,
  color2,
  text1,
  text2,
  text3,
  text4,
}) => {
  return (
    <div className={`salaries-chart-${number}`}>
      <p>{title}</p>
      <div className="salaries-chart-containers">
        <span className="salaries-chart-flex">
          <div className={color1}>
            <Dot />
          </div>
          <span className="gray-txt">{text1}</span>
          <span className="normal-txt">{text2}</span>
        </span>
        <span className="salaries-chart-flex">
          <div className={color2}>
            <Dot />
          </div>
          <span className="gray-txt">{text3}</span>
          <span className="normal-txt"> {text4}</span>
        </span>
      </div>
    </div>
  )
}

export default SalariesChartComponent

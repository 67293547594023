import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="4"
      fill="none"
      viewBox="0 0 16 4"
    >
      <rect width="4" height="4" fill="#D8D8D8" rx="2"></rect>
      <rect width="4" height="4" x="6" fill="#D8D8D8" rx="2"></rect>
      <rect width="4" height="4" x="12" fill="#D8D8D8" rx="2"></rect>
    </svg>
  );
}

export default Icon;

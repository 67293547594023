import React from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import InformationIcon from './InformationIcon.jsx'

const Tooltip = ({ header, information, TooltipIcon }) => {
  return (
    <ReactTooltip
      id="information"
      place="bottom"
      effect="solid"
      type="light"
    >
      <Flex>
        <TooltipIconContainer>
          {TooltipIcon ? <TooltipIcon /> : <InformationIcon />}
        </TooltipIconContainer>
        <TooltipHeader>{header}</TooltipHeader>
      </Flex>
      <InformationContainer>
        {information
          .split('.')
          .filter((i) => i)
          .map((row, index) => (
            <TooltipInformation key={index}>{row + '.'}</TooltipInformation>
          ))}
      </InformationContainer>
    </ReactTooltip>
  )
}

export default Tooltip

const TooltipHeader = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #3d3c3c;
`
const TooltipInformation = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ababab;
  margin-left: 45px;
  max-width: 360px;
`
const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`
const Flex = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`
const TooltipIconContainer = styled.div`
  background-color: #fff7e3;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 6px;
  path {
    fill: rgb(255, 182, 0) !important;
  }
`

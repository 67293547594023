import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="6"
      fill="none"
      viewBox="0 0 9 6"
    >
      <path
        fill="#252F40"
        stroke="#252F40"
        d="M1 1.406c0-.104.04-.208.119-.287a.4.4 0 01.57 0l2.789 2.808 2.79-2.808a.4.4 0 01.569 0 .407.407 0 010 .573L4.763 4.787a.4.4 0 01-.57 0L1.12 1.692A.405.405 0 011 1.406z"
      ></path>
    </svg>
  );
}

export default Icon;

import React from 'react'

function Icon({ ...rest }) {
  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      style={{ cursor: 'pointer' }}
    >
      <path
        fill="#FFCB4A"
        d="M9.043.043A10.017 10.017 0 002.938 2.93a9.982 9.982 0 000 14.14c1.59 1.582 3.515 2.528 5.832 2.86.464.066 2 .062 2.48-.004 3.895-.54 7.027-3.125 8.246-6.809.352-1.066.485-1.906.485-3.117s-.133-2.05-.485-3.117c-1.203-3.64-4.289-6.223-8.11-6.79-.476-.07-1.863-.1-2.343-.05zM11.27 1.66a8.432 8.432 0 015.761 3.684 8.442 8.442 0 01.73 7.953c-1.167 2.723-3.581 4.59-6.53 5.05-.583.094-1.903.09-2.477 0-3.719-.6-6.524-3.406-7.102-7.117-.093-.59-.093-1.87 0-2.46.375-2.395 1.676-4.454 3.657-5.778 1.18-.789 2.476-1.246 4.007-1.406.317-.031 1.575.016 1.954.074z"
      ></path>
      <path
        fill="#FFCB4A"
        d="M9.629 5.375c-.227.078-.414.25-.547.488-.102.184-.117.25-.117.504.004.352.082.527.344.77.511.468 1.3.316 1.64-.32.063-.118.086-.227.086-.45 0-.254-.015-.32-.117-.504a1.363 1.363 0 00-.25-.32c-.266-.219-.7-.29-1.04-.168zM9.75 8.418a.832.832 0 00-.457.43c-.066.148-.074.312-.074 2.835v2.68l.09.172a.777.777 0 001.336.078l.117-.168.011-2.7c.012-3.027.024-2.905-.265-3.167-.23-.207-.48-.262-.758-.16z"
      ></path>
    </svg>
  )
}

export default Icon

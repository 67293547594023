import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        fill="gray"
        fillRule="evenodd"
        d="M12.332 0L0 9.865V25h24.664V9.865L12.332 0zM2.242 22.758V10.987l10.09-8.072 10.09 8.072v11.77H2.242z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;

import React from "react";
import styled from "styled-components";

function Icon() {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="25"
      fill="none"
      viewBox="0 0 26 25"
    >
      <path
        fill="#FFCB4A"
        fillRule="evenodd"
        d="M13 0a6.945 6.945 0 00-6.937 6.937 6.935 6.935 0 003.012 5.717 11.956 11.956 0 00-4.56 2.86A11.922 11.922 0 001 24h1.875c0-5.583 4.542-10.125 10.125-10.125a6.945 6.945 0 006.938-6.938A6.945 6.945 0 0013 0zm0 12a5.068 5.068 0 01-5.062-5.062A5.068 5.068 0 0113 1.875a5.068 5.068 0 015.063 5.063A5.068 5.068 0 0113 12zm8.11 2.25v3.938h3.937v1.875h-3.938V24h-1.875v-3.938h-3.937v-1.875h3.937V14.25h1.875z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FFCB4A"
        d="M9.075 12.654l.049.142.28-.097-.244-.168-.085.123zm-4.56 2.86l-.106-.105.106.106zM1 24H.85v.15H1V24zm1.875 0v.15h.15V24h-.15zm18.234-5.813h-.15v.15h.15v-.15zm0-3.937h.15v-.15h-.15v.15zm3.938 3.938h.15v-.15h-.15v.15zm0 1.875v.15h.15v-.15h-.15zm-3.938 0v-.15h-.15v.15h.15zm0 3.937v.15h.15V24h-.15zm-1.875 0h-.15v.15h.15V24zm0-3.938h.15v-.15h-.15v.15zm-3.937 0h-.15v.15h.15v-.15zm0-1.875v-.15h-.15v.15h.15zm3.937 0v.15h.15v-.15h-.15zm0-3.937v-.15h-.15v.15h.15zM6.213 6.937A6.795 6.795 0 0113 .15v-.3a7.095 7.095 0 00-7.087 7.087h.3zm2.947 5.594a6.785 6.785 0 01-2.947-5.594h-.3a7.085 7.085 0 003.077 5.841l.17-.247zm-4.54 3.09a11.805 11.805 0 014.504-2.825l-.098-.283a12.105 12.105 0 00-4.617 2.896l.212.212zM1.15 24c0-3.166 1.232-6.14 3.47-8.38l-.211-.211A12.071 12.071 0 00.85 24h.3zm1.725-.15H1v.3h1.875v-.3zM13 13.725c-5.666 0-10.275 4.61-10.275 10.275h.3c0-5.5 4.475-9.975 9.975-9.975v-.3zm6.788-6.788A6.795 6.795 0 0113 13.725v.3a7.095 7.095 0 007.087-7.088h-.3zM13 .15a6.795 6.795 0 016.788 6.787h.3A7.095 7.095 0 0013-.15v.3zM7.788 6.938A5.218 5.218 0 0013 12.15v-.3a4.918 4.918 0 01-4.912-4.912h-.3zM13 1.725a5.218 5.218 0 00-5.212 5.213h.3A4.918 4.918 0 0113 2.025v-.3zm5.212 5.213A5.218 5.218 0 0013 1.725v.3a4.918 4.918 0 014.913 4.913h.3zM13 12.15a5.218 5.218 0 005.212-5.212h-.3A4.918 4.918 0 0113 11.85v.3zm8.26 6.037V14.25h-.3v3.938h.3zm3.787-.15h-3.938v.3h3.938v-.3zm.15 2.026v-1.875h-.3v1.875h.3zm-4.088.15h3.938v-.3h-3.938v.3zM21.26 24v-3.938h-.3V24h.3zm-2.025.15h1.875v-.3h-1.875v.3zm-.15-4.087V24h.3v-3.938h-.3zm-3.787.15h3.937v-.3h-3.937v.3zm-.15-2.026v1.875h.3v-1.875h-.3zm4.087-.15h-3.937v.3h3.937v-.3zm-.15-3.787v3.938h.3V14.25h-.3zm2.025-.15h-1.875v.3h1.875v-.3z"
      ></path>
    </StyledSvg>
  );
}

const StyledSvg = styled.svg`
flex-shrink: 0;
`

export default Icon;

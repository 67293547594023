import React, { useEffect, useState } from 'react'
import {
  AccountInfoStepContainer,
  BoldLabel,
  Button,
  ErrorMsg,
  Flex,
  Input,
  InputContainer,
  Label,
  TextArea,
} from '../AccountInfoStyles'
import HorizontalPicker from '../../../sharedComponents/HorizontalPicker.jsx'
import DragDrop from '../DragDrop'
import countries from '../../../../lib/helpers/countries'
import { useHistory } from 'react-router'
import { useForm } from 'react-hook-form'
import ProgressBar from '../ProgressBar.jsx'
import { useAuth } from '../../../../contexts/AuthContext.js'
import { storage } from '../../../../firebase.js'
import { foundationYear, employeesNumberOptions } from './inputOptions.js'
import ReactSelect from 'react-select'
import reactSelectStyles from '../../../sharedComponents/reactSelectStyles.js'
import { useMemo } from 'react'
import AboutModal from '../../../sharedComponents/AboutModal.jsx'
import { useRef } from 'react'
import styled from 'styled-components'

const send = (data) => {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/company`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => res.msg)
}

const AccountInfoCompanyProfile = () => {
  const history = useHistory()
  const { account, setAccountInfo, currentUser } = useAuth()
  const [msg, setMsg] = useState('')
  const [logoImage, setLogoImage] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formOfCooperation, setFormOfCooperation] = useState(
    account?.company?.formOfCooperation.split(',') || []
  )

  const defaultValues = useMemo(() => {
    return {
      companyName: account?.company?.companyName,
      companyYear: account?.company?.companyYear || 2022,
      country: account?.company?.country || 'Poland',
      employeesNumber: account?.company?.employeesNumber || '10-15',
      zipCode: account?.company?.zipCode,
      city: account?.company?.city,
      formOfCooperation: account?.company?.formOfCooperation.split(',') || [],
      aboutCompany: account?.company?.aboutCompany || '',
      street: account?.company?.street,
      houseNumber: account?.company?.houseNumber,
      apartmentNumber: account?.company?.apartmentNumber,
    }
  }, [account])

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, dirtyFields },
    reset,
  } = useForm({ mode: 'onChange', defaultValues })

  useEffect(() => {
    reset(defaultValues)
  }, [reset, defaultValues])

  useEffect(() => {
    if (account) {
      setFormOfCooperation(account?.company?.formOfCooperation.split(',') || [])
    }
  }, [account])

  const onSubmitHandler = async (data) => {
    setLoading(true)
    if (logoImage.length > 0) {
      const imageRef = storage.child(`logo/${account.id}/${logoImage[0].name}`)
      await imageRef.put(logoImage[0])
      const logoUrl = await imageRef.getDownloadURL()
      data.logoImage = logoUrl
    }
    data.userId = account?.id
    data.companyId = account?.company?.id
    data.formOfCooperation = data.formOfCooperation?.filter((i) => i)
    send(data)
      .then((msg) => setMsg(msg))
      .then(() => setLoading(false))
      .then(() => {
        setAccountInfo(currentUser).then(() =>
          history.push('/account/preview-company')
        )
      })
  }

  const containerRef = useRef()

  if (!account) return null //TODO return loading ~
  return (
    <>
      <AccountInfoStepContainer
        onSubmit={handleSubmit(onSubmitHandler)}
        title="Basic information"
        subtitle="Company's data"
        encType="multipart/form-data"
      >
        <LogoContainer ref={containerRef}>
          <Label>Company logotype </Label>
          <img
            id="img"
            style={{
              maxWidth: '40%',
              margin: 'auto',
              display: account?.company?.logoImage ? 'unset' : 'none',
            }}
            src={account?.company?.logoImage}
            alt="logo"
          />
          <DragDrop
            containerRef={containerRef}
            setFiles={setLogoImage}
            files={logoImage}
            type="logoImage"
          />
        </LogoContainer>
        <InputContainer>
          <BoldLabel required>Company name</BoldLabel>
          <Input
            error={errors.companyName}
            {...register('companyName', {
              required: { value: true, message: 'This field is required' },
            })}
            placeholder="e.g. Workeo"
          />
          {<ErrorMsg>{errors?.companyName?.message}</ErrorMsg>}
        </InputContainer>
        <Flex gap={'20px'}>
          <InputContainer>
            <BoldLabel required>Number of employees</BoldLabel>
            <ReactSelect
              styles={reactSelectStyles()}
              getOptionLabel={({ label }) => label}
              getOptionValue={({ label }) => label}
              onChange={(e) => setValue('employeesNumber', e.label)}
              defaultValue={{ label: defaultValues.employeesNumber }}
              options={employeesNumberOptions}
            />
          </InputContainer>
          <InputContainer>
            <BoldLabel required>Foundation year</BoldLabel>
            <ReactSelect
              styles={reactSelectStyles()}
              getOptionLabel={({ label }) => label}
              getOptionValue={({ label }) => label}
              onChange={(e) => setValue('companyYear', e.label)}
              defaultValue={{ label: defaultValues.companyYear }}
              options={foundationYear}
            />
          </InputContainer>
        </Flex>
        <Label>HQ adress</Label>
        <InputContainer>
          <BoldLabel>Country</BoldLabel>
          <ReactSelect
            styles={reactSelectStyles()}
            getOptionLabel={({ label }) => label}
            getOptionValue={({ label }) => label}
            placeholder={<div>test</div>}
            onChange={(e) => setValue('country', e.label)}
            defaultValue={{ label: defaultValues.country }}
            options={countries.map((i) => {
              return { id: i.value, label: i.label }
            })}
          />
        </InputContainer>
        <Flex gap={'10px'}>
          <InputContainer width="28%">
            <BoldLabel>ZIP code</BoldLabel>
            <Input {...register('zipCode')} placeholder="__-___" />
            {<ErrorMsg>{errors?.zipCode?.message}</ErrorMsg>}
          </InputContainer>
          <InputContainer width="100%">
            <BoldLabel>City</BoldLabel>
            <Input
              error={errors?.city}
              {...register('city')}
              placeholder="np. Poznań"
            />
          </InputContainer>
        </Flex>
        <Flex gap={'10px'}>
          <InputContainer width="51%">
            <BoldLabel>Street</BoldLabel>
            <Input {...register('street')} placeholder="np Warszawska" />
          </InputContainer>
          <InputContainer width="22%">
            <BoldLabel>House No.</BoldLabel>
            <Input
              error={errors?.houseNumber}
              {...register('houseNumber')}
              placeholder="np. 13"
            />
          </InputContainer>
          <InputContainer width="22%">
            <BoldLabel>Apartment</BoldLabel>
            <Input
              error={errors?.apartmentNumber}
              {...register('apartmentNumber')}
              placeholder="np. 5"
            />
          </InputContainer>
        </Flex>
        <InputContainer>
          <Label>What forms of cooperation do you offer?</Label>
          <HorizontalPicker
            value={formOfCooperation}
            values={['Contract of employment', 'Contract of mandate', 'B2B']}
            setHookFormValue={setValue}
            hookFormValue={'formOfCooperation'}
            setValue={setFormOfCooperation}
          />
        </InputContainer>
        <InputContainer>
          <Label>Company description:</Label>
          <TextArea
            {...register('aboutCompany')}
            placeholder="Lorem ipsum"
            cols="5"
            rows="12"
            readOnly
            onClick={() => setIsOpen(true)}
          />
          <AboutModal
            initValue={defaultValues.aboutCompany}
            open={isOpen}
            onClose={(value) => setValue('aboutCompany', value?.value)}
            overlayClick={() => setIsOpen(false)}
            btnText="Add projct description"
            modalTitle="Project description"
          />
        </InputContainer>
        <Button disabled={loading} type="submit">
          {loading ? <div className="spinner" /> : 'Save'}
        </Button>
        {msg === 'Saved' ? '' : msg}
      </AccountInfoStepContainer>
      <ProgressBar
        dirtyFields={dirtyFields}
        steps={[
          {
            title: 'Company profile settings',
            href: '',
            active: 1,
          },
          {
            title: 'Preview',
          },
        ]}
      />
    </>
  )
}

export default AccountInfoCompanyProfile

const LogoContainer = styled(InputContainer)`
  gap: 30px;
`

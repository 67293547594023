import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { useFilter } from '../../../../../contexts/FilterContext.js'
import Modal from '../../../../sharedComponents/Modal.jsx'
import WhiteRectangle from '../../../../Icons/WhiteRectangle.jsx'
import PickedRectangle from '../../../../Icons/PickedRectangle.jsx'
import DownArrow from '../../../../Icons/FilterArrows.jsx'
import media from '../../../../../lib/media.js'
import { useEffect } from 'react'
const FilterButton = ({ type, values, disabled }) => {
  const [isOpen, setIsOpen] = useState(false)
  const {
    change,
    valueLocation,
    valueLevel,
    valueType,
    valuePosition,
    valueTechnology,
  } = useFilter()
  const pickType = useCallback(() => {
    switch (type) {
      case 'valueLocation':
        return valueLocation
      case 'valueLevel':
        return valueLevel
      case 'valueType':
        return valueType
      case 'valuePosition':
        return valuePosition
      case 'valueTechnology':
        return valueTechnology
      default:
        return null
    }
  }, [
    valueLevel,
    valueLocation,
    valuePosition,
    valueTechnology,
    type,
    valueType,
  ])
  const [picked, setPicked] = useState(pickType())

  //clear pick after reset
  useEffect(() => {
    setPicked(pickType())
  }, [pickType])

  const filterElementsList = values.map((element, index) => (
    <StyledListElement
      active={picked === element}
      key={index}
      id={element}
      onClick={(e) => setPicked(e?.target?.innerText)}
    >
      {picked === element ? <PickedRectangle /> : <WhiteRectangle />}
      {element}
    </StyledListElement>
  ))

  if (!values.includes(picked)) {
    setPicked(pickType())
  }

  const onClose = () => {
    change(picked, type)
    if (type === 'valuePosition') {
      change('All technologies', 'valueTechnology')
    }
    setIsOpen(false)
  }

  return (
    <>
      <StyledButton disabled={disabled} onClick={() => setIsOpen(true)}>
        {pickType()} <DownArrow />
      </StyledButton>
      <Modal
        title={type.slice(5)}
        info={'Choose known by the candidate'}
        open={isOpen}
        onClose={onClose}
        overlayClick={() => setIsOpen(false)}
        buttonText={"Choose"}
      >
        <StyledList>{filterElementsList}</StyledList>
      </Modal>
    </>
  )
}

const StyledButton = styled.button`
  background: #ffffff;
  border: 2px solid #ededed;
  box-sizing: border-box;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ababab;
  height: 42px;
  width: 200px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 17px;
  &:disabled {
    background: #ededed;
  }
  ${media.lessThan('xl')`
  height: 32px;
  font-size: 11px;
  line-height: 16px;
  `}
`
const StyledList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2px;
  border-radius: 10px;
  overflow: hidden;
`
const StyledListElement = styled.li`
  background: #f5f5f5;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #ababab;
  cursor: pointer;
  width: 370px;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  gap: 10px;
  ${({ active }) =>
    active &&
    `
    background: #FFF7E3;
    color: #FFB600;
`}
`

export default FilterButton

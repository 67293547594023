import React, { useContext, useState } from 'react'
const FilterContext = React.createContext()

export function useFilter() {
  return useContext(FilterContext)
}

export function FilterProvider({ children }) {
  const [valueLocation, setValueLocation] = useState('Poland')
  const [valueLevel, setValueLevel] = useState('All seniority levels')
  const [valueType, setValueType] = useState('All contracts')
  const [valuePosition, setValuePosition] = useState('All positions')
  const [valueTechnology, setValueTechnology] = useState('All technologies')
  const [filterRefresh, setFilterRefresh] = useState()

  let change = (value, whatToChange) => {
    switch (whatToChange) {
      case 'valueLocation':
        setValueLocation(value)
        break
      case 'valueLevel':
        setValueLevel(value)
        break
      case 'valueType':
        setValueType(value)
        break
      case 'valuePosition':
        setValuePosition(value)
        break
      case 'valueTechnology':
        setValueTechnology(value)
        break
      default:
        break
    }
  }

  const value = {
    valueLocation,
    valueLevel,
    valueType,
    valuePosition,
    valueTechnology,
    setValueLocation,
    setValueLevel,
    setValueType,
    setValuePosition,
    setValueTechnology,
    change,
    filterRefresh,
    setFilterRefresh,
  }
  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  )
}

import { useHistory } from 'react-router-dom'
import Avatar from '../../../Icons/BiggerAvatar'
import ReturnArrow from '../../../Icons/ReturnArrow'
import React from 'react'
import styled from 'styled-components'

const CandidatePageLeftPanel = ({
  id,
  name,
  technology,
  level,
  salaries,
  formOfCooperation,
  from,
}) => {
  const history = useHistory()
  const handleReturn = () => {
    history.goBack()
  }

  from =
    new Date(from).getTime() < new Date().getTime()
      ? 'ASAP'
      : new Date().getTime() > new Date().getTime() - 70000000000
      ? '1 month'
      : '3 months' // trzeba dokonczyc
  return (
    <div className="candidate-box">
      <div className="topline"></div>
      <div className="login-form-box-shape candidateAvatar">
        <Avatar />
      </div>
      <div className="name-info page-name-info">
        <span className="infobox">
          <p className="pagename">{name}</p>
          <p className="pagetechnology">{technology}</p>
        </span>
        <div className="line" />
        <span>
          <p className="pagegray">Salary expectations:</p>
          <p className="bold">{salaries}</p>
        </span>
        <span className="flex">
          <div>
            <p className="pagegray">Availability:</p>
            <p className={`bubbles Lead`}>{from}</p>
          </div>
          <div>
            <p className="pagegray">Level:</p>
            <p className={`bubbles Regular`}>{level}</p>
          </div>
          <div>
            <p className="pagegray">Contract:</p>
            <p className={`bubbles Yellow`}>{formOfCooperation}</p>
          </div>
        </span>
      </div>

      <div className="stack-info page-stack-info">
        <span>
          <p className="pagegray">E-mail</p>
          <p className="bold">
            <svg
              width="14"
              height="10"
              viewBox="0 0 14 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.4238 0.227192L6.64674 5.28199L0.870192 0.227192C1.1063 0.0974113 1.37334 0.0178223 1.66167 0.0178223H11.6318C11.9198 0.0178223 12.1867 0.0974113 12.4238 0.227192ZM13.0802 9.12547C13.2115 8.88777 13.2935 8.6176 13.2935 8.32618V1.67958C13.2935 1.35423 13.1962 1.05316 13.0347 0.796863L8.88288 4.4293L13.0802 9.12547ZM8.25734 4.97618L6.92011 6.14697C6.84189 6.21511 6.74449 6.24914 6.64674 6.24914C6.54893 6.24914 6.45153 6.21511 6.37331 6.14697L5.03578 4.97612L0.785397 9.73232C1.04016 9.89215 1.33874 9.9879 1.66164 9.9879H11.6318C11.9547 9.9879 12.2534 9.89215 12.5081 9.73232L8.25734 4.97618ZM0 1.67958C0 1.35421 0.0973699 1.05314 0.258849 0.796836L4.41019 4.42851L0.213397 9.12549C0.081589 8.88779 0 8.61763 0 8.32621V1.67958Z"
                fill="#FFB600"
              />
            </svg>{' '}
            xxxxxxxxxx@gmail.com
          </p>
        </span>
        <span>
          <p className="pagegray">Phone number</p>
          <p className="bold">
            <svg
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.9974 9.46992C12.0228 9.66478 11.9635 9.8342 11.8195 9.97819L10.1295 11.6554C10.0533 11.7402 9.95374 11.8122 9.83091 11.8715C9.70807 11.9308 9.58738 11.9689 9.46882 11.9858C9.46035 11.9858 9.43488 11.988 9.3925 11.9922C9.35021 11.9964 9.29514 11.9986 9.22737 11.9986C9.06638 11.9986 8.80586 11.9711 8.44584 11.916C8.08581 11.8609 7.64532 11.7253 7.12438 11.5094C6.60332 11.2934 6.01247 10.9693 5.35173 10.5373C4.691 10.1053 3.98786 9.51229 3.24239 8.75833C2.6494 8.17382 2.15807 7.61471 1.76839 7.08105C1.37872 6.54734 1.06528 6.05389 0.828085 5.60067C0.59087 5.14746 0.412973 4.73661 0.294376 4.36811C0.175778 3.99961 0.0953016 3.68194 0.0529453 3.41509C0.0105891 3.14825 -0.00635344 2.93858 0.00211781 2.7861C0.0105891 2.63362 0.0148247 2.54891 0.0148247 2.53196C0.0317672 2.41337 0.0698878 2.29265 0.129187 2.16982C0.188485 2.04699 0.260491 1.94745 0.345203 1.87121L2.03522 0.181192C2.15382 0.0625946 2.28936 0.0032959 2.44184 0.0032959C2.55196 0.0032959 2.64938 0.0350631 2.7341 0.0985975C2.81881 0.162132 2.89081 0.240491 2.95011 0.333675L4.30975 2.91317C4.38599 3.04871 4.40717 3.19696 4.37328 3.35791C4.3394 3.51886 4.26739 3.6544 4.15727 3.76453L3.53463 4.38717C3.51769 4.40411 3.50286 4.43164 3.49015 4.46976C3.47745 4.50788 3.47109 4.53965 3.47109 4.56506C3.50498 4.74296 3.58122 4.94627 3.69982 5.17499C3.80147 5.3783 3.95819 5.62609 4.16997 5.91835C4.38175 6.2106 4.68248 6.54732 5.07216 6.92852C5.45337 7.31824 5.79222 7.62105 6.08871 7.83711C6.38514 8.05304 6.63301 8.21194 6.83208 8.31359C7.03116 8.41525 7.18364 8.47666 7.28951 8.49778L7.44831 8.52957C7.46525 8.52957 7.49284 8.52319 7.53092 8.51051C7.56904 8.49778 7.59657 8.48298 7.61354 8.46601L8.33781 7.72901C8.49037 7.59349 8.66819 7.52572 8.87152 7.52572C9.01557 7.52572 9.12987 7.55112 9.21458 7.60197H9.22727L11.6797 9.05055C11.8576 9.16076 11.9635 9.30049 11.9974 9.46992Z"
                fill="#FFB600"
              />
            </svg>{' '}
            +xx xxx xxx xxx
          </p>
        </span>
      </div>

      <LinkStyled onClick={handleReturn}>
        <ReturnArrow /> Return
      </LinkStyled>
    </div>
  )
}

const LinkStyled = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #ababab;
  font-size: 16px;
  &:hover {
  color: rgb(255, 203, 74);

    svg {
      path {
        fill: rgb(255, 203, 74);
      }
    }
  }
`

export default CandidatePageLeftPanel

import React from 'react'
import Mark from '../../../Icons/YellowTick'
import SkillsFile from '../../../Icons/Folder'

const CandidatePageCenterPanel = ({
  stack,
  methodologies,
  databases,
  enviroment,
  changeIf,
}) => {
  let stackList = stack
    ?.filter((i) => i)
    ?.map((i, index) => {
      return (
        <li className="bubbles page-skills" key={index}>
          {i}
        </li>
      )
    })
  let methodologiesList = methodologies
    ?.filter((i) => i)
    ?.map((i, index) => {
      return (
        <li className="bubbles page-skills" key={index}>
          {i}
        </li>
      )
    })

  let databasesList = databases
    ?.filter((i) => i)
    ?.map((i, index) => {
      return (
        <li className="bubbles page-skills" key={index}>
          {i}
        </li>
      )
    })
  let preferences = enviroment?.join() + ',' + changeIf?.join()
  let srodowiskoPracyList = []
  if (preferences) {
    preferences = preferences?.split(',')
    srodowiskoPracyList = preferences?.map((i, index) => {
      return (
        <li key={index}>
          <Mark />
          {i}
        </li>
      )
    })
  }
  return (
    <div className="candidate-info-box">
      <span className="title-right">
        <div className="leftline"></div>
        <span>
          <p className="skill-title">SKILLS</p>
        </span>
        <div className="bottomline gray" />
      </span>
      <div className="info-section">
        <p className="skill-title">
          <SkillsFile />
          Technologies
        </p>
        <p className="skill-subtitle">The candidate's preferred technologies</p>
        {Boolean(stackList?.length) ? (
          <ul className="list">{stackList}</ul>
        ) : (
          <ul className="list">
            <li className="bubbles page-skills">Empty</li>
          </ul>
        )}

        <div className="line" />
        <p className="skill-title">
          <SkillsFile />
          Methodology
        </p>
        <p className="skill-subtitle">
          The candidate's preferred methodologies
        </p>
        {Boolean(methodologiesList?.length) ? (
          <ul className="list">{methodologiesList}</ul>
        ) : (
          <ul className="list">
            <li className="bubbles page-skills">Empty</li>
          </ul>
        )}
        <div className="line" />
        <p className="skill-title">
          <SkillsFile />
          Database
        </p>
        <p className="skill-subtitle">The candidate's preferred databases</p>
        {Boolean(databasesList?.length) ? (
          <ul className="list">{databasesList}</ul>
        ) : (
          <ul className="list">
            <li className="bubbles page-skills">Empty</li>
          </ul>
        )}

        <div className="preferences">
          {preferences?.length !== 2 && (
            <>
              <span className="skill-title">
                More preferences of the candidate
              </span>
              <ul className="pref-list">{srodowiskoPracyList}</ul>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default CandidatePageCenterPanel

import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="12"
      fill="none"
      viewBox="0 0 11 12"
    >
      <path
        fill="#6B6B6B"
        d="M7.944 9V7.2H3.667V4.8h4.277V3L11 6 7.944 9zM6.722 0c.324 0 .635.126.864.351.23.226.358.53.358.849v1.2H6.722V1.2h-5.5v9.6h5.5V9.6h1.222v1.2c0 .318-.128.623-.358.848-.229.226-.54.352-.864.352h-5.5c-.324 0-.635-.126-.864-.351A1.189 1.189 0 010 10.8V1.2C0 .882.129.577.358.351.588.126.898 0 1.222 0h5.5z"
      ></path>
    </svg>
  )
}

export default Icon

import React, { useRef, useState } from 'react';
import {useAuth} from '../../contexts/AuthContext'
import {Link, useHistory} from 'react-router-dom'
import LoginLock from '../Icons/Locked'
import PopAuthButton from './PopAuthButton';
import HiddenLogin from './HiddenLogin';


const Login = ({switchLogin}) => {
    const emailRef = useRef()
    const passwordRef = useRef()
    const {login} = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    const handleSubmit = async(e) =>{
        e.preventDefault()

        try{
            setError('')
            setLoading(true)
            await login(emailRef.current.value, passwordRef.current.value)
            setLoading(false)
            history.push('/')
        }catch{
            setError('Failed to Log In')
            setLoading(false)
        }
    }  
    return ( 
        <span className="flex-login">
            <HiddenLogin switchLogin={switchLogin} text="Sign Up"/>
<span className="center-login">
        <div className="login-form-box">
            <div className="login-form-box-line"/>
            <div className="login-form-box-shape">
                <LoginLock/>
            </div>
            <div className="login-form">
            <h2>Log In</h2>
            {error && <span className="login-error">{error}</span>}
            {history.state && <span className="reset-password-message">Account created succesful, you can Log in now</span>}
            <form onSubmit={handleSubmit}>
                <label htmlFor="email">E-mail</label>
                <input id="email" ref={emailRef} type="email" required placeholder="E-mail"></input>
                <label htmlFor="password">Password</label>
                <input id="password" ref={passwordRef} type="password" required placeholder="Password"></input>
            <div className="link-in"><Link to="/forgot-password">Forgot Password?</Link></div>
                <button disabled={loading} type="submit">Log In</button>
            </form>

        </div>
            <div className="authlogin">
                Quick Log In
            <PopAuthButton/>
                </div>
            </div>
        <div className="login-bottom-text">
            Need an accout? <Link to="#" onClick={switchLogin}>Sign Up</Link>
        </div>
</span>
</span>
     );
}
 
export default Login;
import React from 'react'
import { createPortal } from 'react-dom'
import styled, { keyframes } from 'styled-components'
import Logo from './LogoIcon.jsx'

const Loading = () => {
  return createPortal(
    <LoadingContainer>
      <Center>
        <Logo />
        <LoadingBar />
      </Center>
    </LoadingContainer>,
    document.getElementById('portal')
  )
}

export default Loading

const width = keyframes`
from {
  transform: scaleX(1.1);
}

to {
  transform: scaleX(.7);
}
`

const LoadingContainer = styled.div`
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(15, 11, 46, 0.8);
  mix-blend-mode: normal;
`
const Center = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
const LoadingBar = styled.div`
height: 6px;
width: 115px;
background: #FFC63A;
border-radius: 15px;
margin-top: 10px;
animation: ${width} 1s infinite ease-in-out;
animation-direction: alternate;
`

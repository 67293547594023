import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="11"
      fill="none"
      viewBox="0 0 14 11"
    >
      <path
        fill="#FFCB4A"
        fillRule="evenodd"
        d="M7.6 6.377a3.246 3.246 0 001.306-2.611A3.273 3.273 0 005.642.502 3.273 3.273 0 002.38 3.766c0 1.033.49 2.012 1.305 2.61-1.577.708-2.72 2.176-2.937 3.917h1.088c.272-1.85 1.904-3.264 3.808-3.264 1.903 0 3.535 1.415 3.807 3.264h1.088c-.218-1.74-1.36-3.21-2.937-3.916zm-1.957-.435a2.182 2.182 0 01-2.176-2.176c0-1.197.979-2.176 2.176-2.176 1.196 0 2.175.98 2.175 2.176 0 1.196-.979 2.176-2.175 2.176zm7.07-1.632c0 .707-.271 1.414-.706 1.958a3.268 3.268 0 011.795 2.883h-1.088c0-1.034-.762-1.904-1.74-2.122a.53.53 0 01-.436-.544c0-.163.054-.326.217-.435.49-.38.87-1.033.87-1.74 0-1.034-.706-1.958-1.957-2.176a1.93 1.93 0 00-.218-.49c-.109-.217-.218-.435-.38-.598 1.903-.217 3.644 1.306 3.644 3.264z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default Icon

import React from 'react'
import styled from 'styled-components'
import WhiteRectangle from '../../../Icons/WhiteRectangle.jsx'
import PickedRectangle from '../../../Icons/PickedRectangle.jsx'
import { ACTIONS } from '../reducer.js'

const HorizontalPicker = ({ value, dispatch, values, name }) => {
  const onClick = (item) => {
    if (value?.includes(item)) {
      dispatch({ type: ACTIONS.REMOVE_FROM_ARRAY, key: name, value: item })
    } else {
      dispatch({ type: ACTIONS.ADD_TO_ARRAY, key: name, value: item })
    }
  }
  return (
    <Container>
      {values.map((i, index) => {
        const isActive = value?.includes(i)
        return (
          <Block
            key={index}
            type="button"
            active={isActive}
            id={i}
            onClick={() => onClick(i)}
          >
            {isActive ? <PickedRectangle /> : <WhiteRectangle />}
            {i}
          </Block>
        )
      })}
    </Container>
  )
}

export default HorizontalPicker

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  gap: 1px;
`
const Block = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  background: #f5f5f5;
  text-align: left;
  padding: 0 12px;
  height: 50px;
  letter-spacing: 1px;
  color: #ababab;
  font-size: 13px;
  border: none;
  font-weight: 500;
  transition-property: background-color, color;
  transition-duration: 0.3s;
  ${({ active }) =>
    active &&
    `
    background-color: #FFF7E3;
    color: #FFB600;
`}
`

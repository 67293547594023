import React, { useRef } from 'react'
import LogoDrop from '../../Icons/DragDropSvg'

const DragDrop = ({ type, setFiles, multi, containerRef }) => {
  const upFileRef = useRef(null)
  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    let dt = e.dataTransfer
    let files = dt.files
    handleFiles(files)
  }
  const handleFiles = (files) => {
    files = [...files]
    setFiles(files)
    files.forEach(previewFile)
  }
  const previewFile = (file) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    if (multi) {
      reader.onloadend = function () {
        let img = document.createElement('img')
        img.src = reader.result
        img.style.maxWidth = '100%'
        containerRef.current.appendChild(img)
      }
    } else {
      reader.onloadend = function () {
        const imgReplace = document.getElementById('img')
        const img = document.createElement('img')
        img.style.maxWidth = '40%'
        img.style.margin = 'auto'
        img.src = reader.result
        img.id = 'img'

        containerRef.current.replaceChild(img, imgReplace)
      }
    }
  }
  const handleClick = () => {
    upFileRef.current.click()
  }
  const handleUpload = (e) => {
    handleFiles(upFileRef.current.files)
    }
  const prevent = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }
  return (
    <div>
      <LogoDrop
        id="test"
        onClick={handleClick}
        onDragEnter={prevent}
        onDragLeave={prevent}
        onDragOver={prevent}
        onDrop={handleDrop}
      />
      <input
        ref={upFileRef}
        style={{ display: 'none' }}
        onChange={handleUpload}
        type="file"
        name={type}
        accept="image/png, image/jpeg, image/svg+xml"
        multiple={multi}
      />
    </div>
  )
}

export default DragDrop

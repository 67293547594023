import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="44"
      fill="none"
      viewBox="0 0 37 44"
    >
      <path
        fill="#D6D5D8"
        d="M1.924 16.174C-1.796 22.63.569 32.425 4.682 38.365 6.736 41.335 8.814 44 11.662 44l.162-.003c1.338-.054 2.305-.465 3.24-.863 1.049-.448 2.134-.91 3.836-.91 1.62 0 2.653.447 3.653.88.985.426 2 .867 3.498.839 3.208-.06 5.177-2.936 6.914-5.474a24.272 24.272 0 003.03-6.204l.012-.04a.361.361 0 00-.192-.442l-.026-.011c-.565-.231-5.52-2.429-5.571-8.42-.049-4.866 3.716-7.443 4.472-7.91l.035-.022a.36.36 0 00.102-.507c-2.599-3.803-6.582-4.376-8.185-4.445a7.23 7.23 0 00-.714-.035c-1.884 0-3.688.712-5.138 1.283-1 .395-1.864.735-2.46.735-.67 0-1.54-.345-2.546-.744-1.346-.534-2.872-1.14-4.487-1.14l-.114.002c-3.755.055-7.303 2.203-9.26 5.605z"
      ></path>
      <path
        fill="#D6D5D8"
        d="M26.645 0c-2.274.093-5.002 1.493-6.633 3.402-1.385 1.606-2.739 4.282-2.382 6.98a.36.36 0 00.33.312c.153.012.31.018.466.018 2.223 0 4.622-1.23 6.26-3.21 1.724-2.092 2.596-4.776 2.332-7.18A.363.363 0 0026.645 0z"
      ></path>
    </svg>
  );
}

export default Icon;

import React, { useState } from 'react'
import styled from 'styled-components'
import { createPortal } from 'react-dom'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { ACTIONS } from '../MainPage/NewJobOffer/reducer.js'

const htmlToEditor = (value) => {
  const blocksFromHtml = htmlToDraft(value)
  const { contentBlocks, entityMap } = blocksFromHtml
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  )
  return EditorState.createWithContent(contentState)
}

const AboutModal = ({
  open,
  onClose,
  overlayClick,
  initValue,
  modalTitle,
  btnText,
  name,
}) => {
  const [value, setValue] = useState(
    htmlToEditor(initValue) || EditorState.createEmpty()
  )
  if (!open) return null

  return createPortal(
    <>
      <Overlay onClick={overlayClick} />
      <ModalStyles>
        <StyledTitle>{modalTitle}</StyledTitle>
        <BreakLine />
        <Padding>
          <Center>
            <Editor
              toolbar={{
                options: ['inline', 'list', 'history'],
                list: {
                  options: ['unordered'],
                },
                inline: {
                  options: ['bold'],
                },
              }}
              editorState={value}
              toolbarClassName="editor-toolbar"
              wrapperClassName="editor-wrapper"
              editorClassName="editor-bg"
              onEditorStateChange={setValue}
            />
          </Center>
          <StyledButton
            onClick={() => {
              onClose({
                type: ACTIONS.UPDATE,
                key: name,
                value: draftToHtml(convertToRaw(value.getCurrentContent())),
              })
              overlayClick()
            }}
          >
            {btnText}
          </StyledButton>
        </Padding>
      </ModalStyles>
    </>,
    document.getElementById('portal')
  )
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0f0b2e;
  opacity: 0.7;
  z-index: 1000;
`

const ModalStyles = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 40px 0;
  z-index: 1000;
  border-top: 3px solid #ffcb4a;
`
const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #3d3c3c;
  margin-left: 40px;
`
const BreakLine = styled.div`
  margin: 30px 0;
  height: 1px;
  background: #ededed;
`
const Padding = styled.div`
  padding: 0 40px;
`
const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60vw;
`

const StyledButton = styled.button`
  background: #ffcb4a;
  border-radius: 10px;
  width: 100%;
  height: 50px;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #66511e;
  cursor: pointer;
  border: none;
  margin-top: 40px;
`
export default AboutModal

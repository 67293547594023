import React from 'react'
import ChartSalaryComponent from './Charts/ChartSalaryComponent'
import PieChart from './Charts/PieChart'
import AverageSalary from './AverageSalary/AverageSalary'
import Candidates from './Candidates/Candidates'
import CreateOffer from './CreateOffer/CreateOffer'
import TopMenu from '../../sharedComponents/TopMenu/TopMenu.jsx'
import DashboardIcon from '../../Icons/Dashboard.jsx'
import { useFilter } from '../../../contexts/FilterContext.js'
import { contract, seniority } from '../../../lib/helpers/filterHelper.js'
import { useMemo } from 'react'
import Loading from '../../sharedComponents/loading/Loading.jsx'
import ErrorPage from '../../sharedComponents/error/ErrorPage.jsx'
import useSWR from 'swr'

const Home = () => {
  const { valueLevel, valueType, valuePosition, valueTechnology } = useFilter()
  const queryParams = useMemo(
    () => ({
      len: true,
      avg: true,
      level: valueLevel !== 'All seniority levels' ? seniority(valueLevel) : '',
      type: valueType !== 'All contracts' ? contract(valueType) : '',
      position: valuePosition !== 'All positions' ? valuePosition : '',
      technology: valueTechnology !== 'All technologies' ? valueTechnology : '',
    }),
    [valueLevel, valueType, valueTechnology, valuePosition]
  )
  const token = localStorage.getItem('wrk_token')

  const { data, error } = useSWR([
    `${process.env.REACT_APP_BACKEND_URL}/api/all-offers`,
    token,
    queryParams,
  ])
  const loading = !data

  if (error) {
    return <ErrorPage />
  }

  return (
    <>
      {loading && <Loading />}
      <div className="dashboard">
        <TopMenu
          pageName={'Dashboard'}
          tooltipHeader={'Dashboard'}
          TooltipIcon={DashboardIcon}
          information={`Provides a key summary of current labor market data in Poland with filtering capabilities.
        In the lower right corner, the system, according to all the search criteria you entered, will present you with candidates who meet the requirements and are actively looking for work. Click ''show more'' to access the full listing in the candidate database tab. 
        In the lower left corner you will find a redirection to the panel for creating the recruitment process and advertisement.`}
        />

        <div className="home">
          <div className="salary-box">
            <AverageSalary
              minSalary={data?.avgOffers?.min}
              maxSalary={data?.avgOffers?.max}
              offersCount={data?.count}
            />
          </div>

          <div className="chartsalary-box">
            <ChartSalaryComponent
              offersData={data?.avgOffers}
              salaries={true}
              type="line"
            />
          </div>
          <div className="circlechart-box">
            <PieChart offersData={data?.lengthOffers} />
          </div>

          <div className="chart-box">
            <CreateOffer />
          </div>

          <div className="offers-box">
            <Candidates />
          </div>
        </div>
      </div>
    </>
  )
}

export default Home

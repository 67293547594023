import React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { useAuth } from '../../../contexts/AuthContext'
import RecruitmentCard from './RecruitmentCard'
import AddCircle from '../../Icons/PlusButton'
import { useHistory } from 'react-router'
import TopMenu from '../../sharedComponents/TopMenu/TopMenu.jsx'
import RecruitmentsIcon from '../../Icons/Recruitments.jsx'
import useSWR from 'swr'
import ErrorPage from '../../sharedComponents/error/ErrorPage.jsx'

const RecruitmentComponent = () => {
  const [activeStatus, setActiveStatus] = useState('ACTIVE')
  const { account } = useAuth()

  const history = useHistory()

  const token = localStorage.getItem('wrk_token')
  const { data, error } = useSWR(() =>
    account?.company
      ? [
          `${process.env.REACT_APP_BACKEND_URL}/api/recruitments/company/${account?.company?.id}`,
          token,
        ]
      : null
  )

  if (error) {
    return <ErrorPage />
  }

  const jobsComponent = data
    ?.filter((job) => {
      return activeStatus === 'ACTIVE'
        ? new Date(job?.end) >= new Date()
        : new Date(job?.end) < new Date()
    })
    ?.map((job, key) => {
      return (
        <RecruitmentCard
          key={key}
          applications={job.applications.length}
          positionName={job.positionName}
          location={job.city}
          reqExpLevel={job.reqExpLevel}
          minSalaryB2B={job.minSalaryB2B}
          maxSalaryB2B={job.maxSalaryB2B}
          minSalaryUoP={job.minSalaryUoP}
          maxSalaryUoP={job.maxSalaryUoP}
          id={job.id}
          end={job.end}
          currency={job.currency}
          active={activeStatus === 'ACTIVE'}
        />
      )
    })

  return (
    <div className="recruitments">
      <TopMenu
        noFilter
        pageName={'Recruitments'}
        tooltipHeader="Recruitments"
        TooltipIcon={RecruitmentsIcon}
        information={`In this panel, you will see a window with the first process you created. Click on the job title box that appears to go to the candidate management panel.`}
      />
      <Wrapper>
        <StatusContainer>
          <StatusItem
            active={activeStatus === 'ACTIVE'}
            onClick={() => setActiveStatus('ACTIVE')}
          >
            Active
          </StatusItem>
          <StatusItem
            active={activeStatus === 'FINISHED'}
            onClick={() => setActiveStatus('FINISHED')}
          >
            FINISHED
          </StatusItem>
        </StatusContainer>

        <AddButton
          onClick={() => {
            if (account?.company) {
              history.push('/create-job-offer')
            }
          }}
          disabled={!account?.company}
        >
          <AddCircle /> Create new
        </AddButton>
      </Wrapper>
      {!account?.company && (
        <ErrorMsg>
          Before adding a recruitment, complete your{' '}
          <ErrorLink onClick={() => history.push('/account/company')}>
            account information
          </ErrorLink>
        </ErrorMsg>
      )}
      <div className="recruitments-grid">{jobsComponent}</div>
    </div>
  )
}
const StatusContainer = styled.div`
  display: flex;
  gap: 50px;
`
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 1440px;
  margin: auto;
  margin-bottom: 23px;
`
const StatusItem = styled.span`
  cursor: pointer;
  height: 25px;
  letter-spacing: 1px;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${({ active }) => (active ? '#3d3c3c' : '#ABABAB')};
  border-bottom: ${({ active }) => active && '2px solid rgb(255, 203, 74)'};
  &:hover {
    color: #3d3c3c;
    border-bottom: 2px solid rgb(255, 203, 74);
  }
`
const AddButton = styled.button`
  color: #66511e;
  background-color: #ffcb4a;
  border-radius: 10px;
  padding: 7px 15px;
  border: none;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  justify-content: space-between;
  gap: 7px;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`
const ErrorMsg = styled.div`
  color: red;
  text-align: right;
`
const ErrorLink = styled.span`
  font-weight: 600;
  cursor: pointer;
`
export default RecruitmentComponent

import React from 'react'
import CandidatePageLeftPanel from './CandidatePageLeftPanel'
import CandidatePageCenterPanel from './CandidatePageCenterPanel'
import CandidatePageRightPanel from './CandidatePageRightPanel'
import TopMenu from '../../../sharedComponents/TopMenu/TopMenu.jsx'
import CandidateIcon from '../../../Icons/Candidate.jsx'
import { useMemo } from 'react'
import Loading from '../../../sharedComponents/loading/Loading.jsx'
import ErrorPage from '../../../sharedComponents/error/ErrorPage.jsx'
import useSWR from 'swr'

const CandidatePage = ({ match }) => {
  const query = useMemo(
    () => ({
      id: match.params.id,
    }),
    [match.params.id]
  )
  const token = localStorage.getItem('wrk_token')

  const { data, error } = useSWR([
    `${process.env.REACT_APP_BACKEND_URL}/api/candidates`,
    token,
    query,
  ])
  const loading = !data
  let scrollMain = document.querySelector('section.main-page')
  if (scrollMain) {
    scrollMain.scroll(0, 0)
  }
  if (error) {
    return <ErrorPage />
  }

  return (
    <>
      {loading && <Loading />}
      <div className="candidate-page">
        <TopMenu
          pageName={'Candidate'}
          tooltipHeader="Candidate"
          TooltipIcon={CandidateIcon}
          information={''}
          noFilter
        />
        <section>
          <div className="candidate-page-info">
            <CandidatePageLeftPanel
              id={match.params.id}
              name={`Candidate #${data?.candidates[0]?.id || ''}`}
              technology={data?.candidates[0]?.position}
              level={data?.candidates[0]?.experience}
              salaries={`${data?.candidates[0]?.salary || 0} PLN`}
              from={data?.candidates[0]?.openFrom}
              formOfCooperation={data?.candidates[0]?.formOfCooperation}
            />
            <CandidatePageCenterPanel
              databases={data?.candidates[0]?.databases || []}
              stack={data?.candidates[0]?.technologies}
              methodologies={
                data?.candidates[0]?.methodologies?.split(',') || []
              }
              enviroment={data?.candidates[0]?.workEnviroment?.split(',') || []}
              changeIf={data?.candidates[0]?.changeIf?.split(',') || []}
            />

            <CandidatePageRightPanel id={match.params.id} />
          </div>
        </section>
      </div>
    </>
  )
}

export default CandidatePage

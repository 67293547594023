const technologies = (position) => {
  switch (position) {
    case 'All positions':
      return ['All technologies']
    case 'Frontend':
      return ['All technologies', 'React', 'Vue', 'Angular', "Javascript"]
    case 'Backend':
      return [
        'All technologies',
        'Python',
        'Javascript',
        'Node',
        '.NET',
        'Java',
        'PHP',
        'Ruby',
        'Elixir',
      ]
    case 'Fullstack':
      return ['All technologies','Javascript', 'Node', "PHP"]
    case 'Mobile':
      return [
        'All technologies',
        'React Native',
        'Kotlin',
        'Swift',
        'Flutter',
        'Javascript',
        'Java',
      ]
    case 'QA':
      return ['All technologies']
    case 'Database':
      return [
        'All technologies',
        'MySQL',
        'PostgreSQL',
        'SQL Lite',
        'MSSQL',
        'MariaDB',
        'MongoDB',
        'Redis',
        'Oracle',
      ]
    case 'DevOPS':
      return ['All technologies']
    case 'UX/UI':
      return ['All technologies']
    case 'Project Manager':
      return ['All technologies']
    default:
      return ['All technologies']
  }
}

export default technologies

import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="13"
      fill="none"
      viewBox="0 0 14 13"
    >
      <path
        fill="#FFCB4A"
        fillRule="evenodd"
        d="M2.602 3.635a1.11 1.11 0 01-1.1-1.114 1.11 1.11 0 011.1-1.114h8.8v1.114h1.1V.293h-9.9c-1.21 0-2.2 1.003-2.2 2.228v7.24c0 1.56 1.21 2.786 2.75 2.786h10.45V3.634h-11zm9.9 7.798h-9.35c-.935 0-1.65-.724-1.65-1.671V4.415c.33.223.715.334 1.1.334h9.9v6.684z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;

import React from 'react'
import Logo from '../../../Icons/AppleLogo'
import More from '../../../Icons/Dots'
import Location from '../../../Icons/LocationGray'
import Wallet from '../../../Icons/Wallet'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import media from '../../../../lib/media.js'

const CreateOffer = () => {
  const history = useHistory()
  const handleClick = () => {
    //logic
    history.push('/recruitments')
  }
  return (
    <div className="db-create-offer">
      <span className="db-create-offer-title">
        Create job offer on Workeo.dev
      </span>
      <Flex>
        <div className="db-offer-card">
          <span className="db-offer-card-top">
            <div className="db-offer-card-logo">
              <Logo />
            </div>
            <div className="db-offer-card-title">
              <h2>Conceptual Designer</h2>
              <p>Apple Inc.</p>
            </div>
          </span>
          <span className="db-offer-card-mid">
            <span className="bubbles Senior">Senior</span>
            <span>
              <Wallet /> 7000-9000 PLN
            </span>
            <span>
              <Location className="svgfix" /> Cupertino, CA, USA
            </span>
          </span>
          <span className="db-offer-card-bottom">
            <span className="bubbles">UX design</span>
            <span className="bubbles">UI design</span>
            <span className="bubbles">Web Design</span>
            <span className="bubbles">
              <More />
            </span>
          </span>
        </div>
        <div className="db-offer-info">
          <span className="db-offer-info-top">
            This is a sample how your job offer might look like on our job
            board.
          </span>
          <div className="db-offer-info-bottom">
            <span>Press the button and fill the form!</span>
            <button onClick={handleClick} className="db-offer-info-button">
              Add offer
            </button>
          </div>
        </div>
      </Flex>
    </div>
  )
}

export default CreateOffer

const Flex = styled.div`
  margin-top: 25px;
  display: flex;
  column-gap: 25px;
  flex-wrap: wrap;
  justify-content: center;
  ${media.lessThan('md')`
  flex-direction: column;
    .db-offer-info{
      margin: auto;
    }
  `}
`

import React from "react";

function Icon({...props}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="29"
      fill="none"
      viewBox="0 0 28 29"
      {...props}
    >
      <rect
        width="26.934"
        height="27.601"
        x="0.325"
        y="0.739"
        fill="#FFF7E3"
        rx="13.467"
      ></rect>
      <path
        fill="#FFB600"
        stroke="#FFCB4A"
        d="M14.31 14.009V9.285a.525.525 0 00-.517-.53.525.525 0 00-.519.53v4.724H8.665a.525.525 0 00-.518.53c0 .294.232.532.518.532h4.61v4.723c0 .293.231.531.518.531.143 0 .272-.06.366-.155a.536.536 0 00.151-.376V15.07h4.61a.51.51 0 00.366-.156.536.536 0 00.152-.375.524.524 0 00-.518-.531h-4.61z"
      ></path>
    </svg>
  );
}

export default Icon;

import React, { useState } from 'react'
import RecruitmentIcon from '../../Icons/Recruitments'
import { useHistory } from 'react-router-dom'
import SalaryIcon from '../../Icons/Salary'
import LocationIcon from '../../Icons/Location'
import ApplicationsIcon from './Icons/Applications.jsx'
import CallendarIcon from './Icons/Callendar.jsx'
import styled from 'styled-components'
import truncateString from '../../../lib/helpers/truncateString.js'
import { useSWRConfig } from 'swr'
import { useAuth } from '../../../contexts/AuthContext.js'

const RecruitmentCard = ({
  id,
  location,
  positionName,
  reqExpLevel,
  minSalaryB2B,
  maxSalaryB2B,
  minSalaryUoP,
  maxSalaryUoP,
  applications,
  end,
  active, //todo: renew functionality if !active
  currency,
}) => {
  const history = useHistory()
  const { account } = useAuth()
  const { mutate } = useSWRConfig()
  const [disableActions, setDisableActions] = useState(false)

  const handleActions = async (id, renew) => {
    setDisableActions(true)
    const token = localStorage.getItem('wrk_token')
    const recId = { id }
    if (renew) {
      await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/recruitments`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer: ${token}`,
        },
        body: JSON.stringify(recId),
      })
    } else {
      await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/recruitments`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer: ${token}`,
        },
        body: JSON.stringify(recId),
      })
    }
    await mutate([
      `${process.env.REACT_APP_BACKEND_URL}/api/recruitments/company/${account?.company?.id}`,
      token,
    ])
    setDisableActions(false)
  }

  const handleClick = () => {
    history.push(`/recruitment/${id}`)
  }
  const salary = minSalaryB2B
    ? (minSalaryB2B?.toLocaleString() || 0) +
      ' - ' +
      (maxSalaryB2B?.toLocaleString() || 0)
    : (minSalaryUoP?.toLocaleString() || 0) +
      ' - ' +
      (maxSalaryUoP?.toLocaleString() || 0)

  return (
    <RecruitmentCardContainer onClick={handleClick}>
      <div className="topline-recr" />
      {disableActions && <Spinner className="spinner" />}
      <RecruitmentPositionContainer>
        <RecruitmentTitle>
          <RecruitmentIcon />
          {truncateString(positionName, 20)}
        </RecruitmentTitle>
      </RecruitmentPositionContainer>
      <InfoContainer>
        <span className={`bubbles ${reqExpLevel}`}>{reqExpLevel}</span>
        <GapTextSvg className="card-bold-info">
          <SalaryIcon /> {salary} {currency}
        </GapTextSvg>
        <GapTextSvg className="card-bold-info">
          <LocationIcon /> {location}
        </GapTextSvg>
      </InfoContainer>
      <ExpirationContainer>
        {active && (
          <>
            <Expiration>Expiration date</Expiration>
            <Flex>
              <DateContainer>
                <CallendarIcon />
                {new Date(end).toLocaleDateString('en-GB', {
                  dateStyle: 'long',
                })}
              </DateContainer>
            </Flex>
          </>
        )}
        <Separator />
        <Flex>
          <ApplicationsContainer>
            <ApplicationsIcon />
            {applications} applications
          </ApplicationsContainer>
          <ActionsContainer>
            <Action
              disabled={disableActions}
              onClick={(e) => {
                e.stopPropagation()
                history.push('/create-job-offer', { id, mode: 'edit' })
              }}
            >
              Edit
            </Action>
            <Separator />
            <Action
              disabled={disableActions}
              onClick={(e) => {
                e.stopPropagation()
                history.push('/create-job-offer', { id, mode: 'clone' })
              }}
            >
              Clone
            </Action>
            <Separator />
            <Action
              disabled={disableActions}
              onClick={(e) => {
                e.stopPropagation()
                handleActions(id, !active)
              }}
            >
              {active ? 'Finish' : 'Renew'}
            </Action>
          </ActionsContainer>
        </Flex>
      </ExpirationContainer>
    </RecruitmentCardContainer>
  )
}
const InfoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`
const GapTextSvg = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
`
const ApplicationsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 14px;
  color: #6b6b6b;
`
const RecruitmentTitle = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
`
const RecruitmentPositionContainer = styled.div`
  display: flex;
  align-items: center;
  color: #3d3c3c;
  font-size: 20px;
  font-weight: 600;
  justify-content: flex-start;
`
const RecruitmentCardContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 30px;
  padding-bottom: 18px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    .topline-recr {
      position: absolute;
      opacity: 1;
    }
    box-shadow: 0px 50px 50px rgba(0, 0, 0, 0.1);
    transition: all 0.5s;
    z-index: 1;
  }
  &:hover ${RecruitmentPositionContainer} {
    color: #ffcb4a;
  }
`
const ExpirationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
const Flex = styled.div`
  display: flex;
`
const Expiration = styled.div`
  font-size: 10px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #ababab;
  font-weight: 700;
`
const ActionsContainer = styled.span`
  margin-left: auto;
  display: flex;
  gap: 15px;
`
const Action = styled.button`
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  color: #6b6b6b;
  background-color: transparent;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  transition: 0.2s;
  &:hover {
    border-bottom: 1px solid #6b6b6bae;
  }
`
const Separator = styled.hr`
  border: 1px solid #ededed9d;
`
const DateContainer = styled.div`
  border: 1px solid #ededed;
  border-radius: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 12px;
  color: #6b6b6b;
  padding: 6px 15px;
`
const Spinner = styled.div`
  position: absolute;
  right: 20px;
`
export default RecruitmentCard

import React from 'react'
import { useHistory } from 'react-router-dom'
import CornerImage from '../../../Icons/AddOfferStep3CornerIcon'
import { Container, WhiteTop } from '../NewJobOfferStyledComponents.jsx'
import Button from '../../../sharedComponents/Button.jsx'
import HorizontalPicker from '../NewJobOffersComponents/HorizontalPicker.jsx'

const NewJobOfferStep3 = ({ enviroment, keyAdvantages, dispatch }) => {
  const history = useHistory()
  const nextStep = () => {
    history.push('/create-job-offer/4')
  }

  return (
    <div className="new-job-offer-left-container">
      <div className="topline-inf" />
      <div className="corner-image">
        <CornerImage />
      </div>
      <Container>
        <WhiteTop>
          <h1>Detailed information</h1>
          <p>Advantages of the offer and work environment</p>
        </WhiteTop>
        <div>
          <p className="new-job-title">Advantages that describe your offer:</p>
          <HorizontalPicker
            name="keyAdvantages"
            value={keyAdvantages}
            values={ADVANTAGES}
            dispatch={dispatch}
          />
        </div>
        <div>
          <p className="new-job-title">Work enviroment:</p>
          <HorizontalPicker
            name="enviroment"
            value={enviroment}
            values={ENVIROMENT}
            dispatch={dispatch}
          />
        </div>
        <Button onClick={nextStep}>CONTINUE</Button>
      </Container>
    </div>
  )
}

export default NewJobOfferStep3

const ENVIROMENT = [
  'Startup',
  'Software house',
  'Corporation',
  'Internal IT department',
  'Work in English in an international team',
  'Work in Polish',
  'IT product company',
]
const ADVANTAGES = [
  'Greenfield project',
  'An interesting project',
  'Newest technologies',
  'Writing new functionalities',
  'Work in an experienced team',
  'Sports card',
  'Training budget',
  'Medical insurance',
  'Remote work',
  'Clouds solutions in the project',
]

import React from 'react'
import styled from 'styled-components'
import Switch from 'react-switch'
import { ACTIONS } from '../reducer.js'

const BollPickerComponent = ({ txt, benefit, setBenefit, name }) => {
  return (
    <Flex>
      <p>{txt}</p>
      <Switch
        height={24}
        width={52}
        offColor="#E6E6E6"
        onColor="#FFCB4A"
        checked={!!benefit}
        uncheckedIcon={false}
        checkedIcon={false}
        activeBoxShadow=""
        onChange={() => setBenefit({ type: ACTIONS.TOGGLE, key: name })}
      />
    </Flex>
  )
}

const Flex = styled.span`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #3d3c3c;
`

export default BollPickerComponent

import styled from 'styled-components'

export default styled.button`
  background-color: #ffcb4a;
  border-radius: 10px;
  color: #66511e;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  width: 100%;
  height: 50px;
  border: none;
  outline: none;
  transition: 0.5s;
  &:hover {
    background: #ffb600;
    color: #fff7e3;
  }
`

import React, { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router'
import { useFilter } from '../../../contexts/FilterContext.js'
import { contract } from '../../../lib/helpers/filterHelper.js'
import TopMenu from '../../sharedComponents/TopMenu/TopMenu.jsx'
import Candidate from './Candidate/Candidate'
import CandidatesIcon from '../../Icons/Candidate.jsx'
import Loading from '../../sharedComponents/loading/Loading.jsx'
import ErrorPage from '../../sharedComponents/error/ErrorPage.jsx'
import useSWR from 'swr'

const LIMIT = 36

const CandidatesComponent = ({ match }) => {
  const history = useHistory()
  const page = match.params.page
  const { valueLevel, valueType, valuePosition, valueTechnology } = useFilter()

  useEffect(() => {
    history.push(`/candidates/page1`)
  }, [history, valueLevel, valueType, valuePosition, valueTechnology])

  const queryParams = useMemo(
    () => ({
      limit: LIMIT,
      offset: LIMIT * (page - 1),
      level: valueLevel !== 'All seniority levels' ? valueLevel : '',
      type: valueType !== 'All contracts' ? contract(valueType) : '',
      position: valuePosition !== 'All positions' ? valuePosition : '',
      technology: valueTechnology !== 'All technologies' ? valueTechnology : '',
    }),
    [valueLevel, valueType, valueTechnology, valuePosition, page]
  )
  const token = localStorage.getItem('wrk_token')

  const { data: candidatesFiltered, error } = useSWR([
    `${process.env.REACT_APP_BACKEND_URL}/api/candidates`,
    token,
    queryParams,
  ])
  const loading = !candidatesFiltered
  useEffect(() => {
    let clearClass = document.querySelectorAll('.pages-list-item')
    clearClass.forEach((i) => {
      i.classList.remove('active-page')
      if (+i.innerText === +page) {
        i.classList.add('active-page')
      }
    })
  }, [page, candidatesFiltered?.pages])

  if (error) {
    return <ErrorPage />
  }

  const changePage = (e) => {
    history.push(`/candidates/page${e.target.innerText}`)
  }

  const candidatesComponent = candidatesFiltered?.candidates
    //filter candidates from form
    ?.filter((i) => i.position)
    ?.map((i) => {
      return (
        <Candidate
          link={i.id}
          id={i.id}
          key={i.id}
          position={i.position}
          salary={i.salary}
          seniority={i.experience}
          openFrom={
            new Date(i.openFrom).getTime() < new Date().getTime()
              ? 'ASAP'
              : new Date().getTime() > new Date().getTime() - 70000000000
              ? '1 month'
              : '3 months'
          }
          contract={i.formOfCooperation}
          skills={i.technologies}
        />
      )
    })

  const pages = [...Array(candidatesFiltered?.pages).keys()]
  const pageList = pages
    .map((i) => (
      <li key={i} className="pages-list-item" onClick={changePage}>
        {i + 1}
      </li>
    ))
    .filter((_, index) => index - 2 <= page && index >= page - 4)

  return (
    <>
      {loading && <Loading />}
      <div className="candidates">
        <TopMenu
          pageName={`Talent Pool (${candidatesFiltered?.count || 0})`}
          tooltipHeader="Candidates"
          TooltipIcon={CandidatesIcon}
          information={`You are in a panel where you can find potential candidates with different specialties in the IT industry. Profiles are anonymized for data protection reasons. You will find filters that allow you to select candidates by location, job title, the technologies they work with, their level of experience and their preferred form of cooperation.  
        In addition, we have also included the ability to sort candidates by salary rate.`}
        />

        <div className="candidates-grid">{candidatesComponent}</div>
        {Boolean(candidatesFiltered?.pages) && (
          <ul className="pages-list">{pageList}</ul>
        )}
      </div>
    </>
  )
}

export default CandidatesComponent

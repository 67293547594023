import React from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import Avatar from '../../Icons/Avatar'
import DeleteIcon from './Icons/DeleteIcon'
import openInNewTab from '../../../lib/helpers/openInNewTab.js'
import media from '../../../lib/media.js'

const CandidateListItem = ({
  candidate,
  jobSlug,
  active,
  fromDatabase,
  status,
}) => {
  const history = useHistory()
  const { cv, email, id, name } = candidate
  const handleCvClick = () => {
    if (cv) {
      openInNewTab(cv)
    }
  }
  return (
    <InvitedListItem>
      <div className="topline-recr" />
      <InvitedName
        onClick={() => {
          if (fromDatabase) {
            history.push(`/candidate/${id}`)
          }
        }}
      >
        <Avatar />
        {fromDatabase
          ? name || `Candidate #${id}`
          : name || 'JobBoard Candidate'}
      </InvitedName>
      <InvitedColumn>
        <span>
          <SmallGrayText>Source</SmallGrayText>
          <Bubble className={fromDatabase ? 'APP' : 'FORM'}>
            {fromDatabase ? 'App' : 'Form'}
          </Bubble>
        </span>
      </InvitedColumn>
      <InvitedColumn>
        <span>
          <SmallGrayText>Email</SmallGrayText>
          <Email>{active ? email : 'xxxxxxxx@xxx.xxx'}</Email>
        </span>
      </InvitedColumn>
      <InvitedColumn buttons>
        {active && (
          <CvBlock cv={cv} onClick={handleCvClick}>
            cv
          </CvBlock>
        )}
        <DeleteIcon id={id} jobSlug={jobSlug} status={status} />
      </InvitedColumn>
    </InvitedListItem>
  )
}

const CvBlock = styled.div`
  border: 1px solid #f5f5f5;
  border-radius: 15px;
  padding: 5px 8px;
  font-weight: 700;
  font-size: 11px;
  text-align: center;
  height: 30px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  color: #66511e;
  background-color: #ffcb4a;
  letter-spacing: 1px;
  transition: 0.2s;
  text-transform: uppercase;

  &:hover {
    color: #fff7e3;
    background-color: #ffb600;
  }
  ${({ cv }) =>
    !cv &&
    `
    cursor: not-allowed;
    background-color: #ededed;
    color: #ABABAB;
    &:hover{
    color: #727272;
    background-color: #ABABAB;
  }
  `}
`
const InvitedName = styled.span`
  display: flex;
  align-items: center;
  color: #3d3c3c;
  font-weight: 600;
  gap: 15px;
  white-space: nowrap;
  min-width: 200px;
`
const InvitedColumn = styled.span`
  /* text-align: center; */
  display: flex;
  gap: 15px;
  ${media.lessThan('xl')`
  flex-direction: column;
  align-items: center;
  gap: 20px;
  `}
`
const InvitedListItem = styled.li`
  padding: 30px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  ${media.lessThan('xl')`
  flex-direction: column;
  gap: 20px;
  width: 300px;
  `}

  transition: transform 0.2s;
  &:hover {
    .topline-recr {
      opacity: 1;
    }
    ${InvitedName} {
      color: #ffcb4a;
    }
  }
  &:hover::before {
    opacity: 1;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
    opacity: 0;
    transition: opacity 0.5s;
    pointer-events: none;
  }
`
const SmallGrayText = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #ababab;
  text-align: center;
`
const Email = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #3d3c3c;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
`
const Bubble = styled.div`
  font-weight: 500;
  border: 1px solid #f5f5f5;
  border-radius: 15px;
  padding: 5px 8px;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  height: 30px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
`

export default CandidateListItem

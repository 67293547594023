import React from "react";
import styled from 'styled-components'

function Icon({...props}) {
  return (
    <StyledSvg
    {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="28"
      fill="none"
      viewBox="0 0 27 28"
    >
      <rect width="26.396" height="27.601" fill="#F5F5F5" rx="13.198"></rect>
      <path
        fill="#B8C1CB"
        fillRule="evenodd"
        d="M19.047 9.857L18.035 8.8a.973.973 0 00-1.418 0l-.97 1.014 2.27 2.372 1.13-1.182a.835.835 0 000-1.146zm-3.99.572l2.268 2.373-5.742 6.005-2.268-2.373 5.742-6.005zM8.44 20.09c-.185.048-.353-.126-.312-.32l.573-2.692 2.267 2.372-2.528.64z"
        clipRule="evenodd"
      ></path>
    </StyledSvg>
  );
}

export default Icon;

const StyledSvg = styled.svg`
&:hover{
  rect{
    transition: .2s;
    fill: #FFF7E3;
  }
  path{
    transition: .2s;
    fill: #FFCB4A;
  }
}
` 

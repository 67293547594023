export const initialValue = {
  positionName: '',
  reqExpLevel: '',
  minSalaryUoP: '',
  maxSalaryUoP: '',
  minSalaryB2B: '',
  maxSalaryB2B: '',
  currency: 'PLN',
  remotePossibility: '',
  teamSize: '',
  address: '',
  city: '',
  mustHaves: [],
  niceToHaves: [],
  internationalTeam: false,
  changeProject: false,
  chooseTools: false,
  paidHolidays: false,
  recruitmentProcessSteps: [],
  aboutOffer: '',
  projectDescription: '',
  keyAdvantages: [],
  enviroment: [],
  methodologies: [],
  projectVideo: '',
  projectStack: [],
  id: null,
}

export const ACTIONS = {
  UPDATE: 'input',
  ADD_SKILL: 'add-skill',
  REMOVE_SKILL: 'remove-skill',
  ADD_RECRUITMENT_STEP: 'add-recruitment-step',
  REMOVE_RECRUITMENT_STEP: 'remove-recruitment-step',
  ADD_TO_ARRAY: 'add-to-array',
  REMOVE_FROM_ARRAY: 'remove-from-array',
  TOGGLE: 'toggle',
  GET: 'get',
  COPY: 'copy',
  CLEAR: 'clear',
  SEND: 'send',
}
const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE:
      return {
        ...state,
        [action.key]: action.value,
      }
    case ACTIONS.ADD_SKILL: {
      const tempSkill = [...state[action.key]]
      tempSkill[action.id] = action.skillName + ':' + action.level
      return {
        ...state,
        [action.key]: tempSkill,
      }
    }
    case ACTIONS.REMOVE_SKILL: {
      const tempSkill = [...state[action.key]].filter(
        (item) => item.split(':')[0] !== action.skillName
      )
      return {
        ...state,
        [action.key]: tempSkill,
      }
    }
    case ACTIONS.ADD_RECRUITMENT_STEP: {
      const recruitmentProcessSteps = [...state.recruitmentProcessSteps]
      recruitmentProcessSteps[action.step] = action.value
      return {
        ...state,
        recruitmentProcessSteps,
      }
    }
    case ACTIONS.REMOVE_RECRUITMENT_STEP: {
      const recruitmentProcessSteps = [...state.recruitmentProcessSteps].filter(
        (item) => item !== action.value
      )
      return {
        ...state,
        recruitmentProcessSteps,
      }
    }
    case ACTIONS.TOGGLE:
      return {
        ...state,
        [action.key]: !state[action.key],
      }
    case ACTIONS.ADD_TO_ARRAY:
      return {
        ...state,
        [action.key]: [...state[action.key], action.value],
      }
    case ACTIONS.REMOVE_FROM_ARRAY:
      return {
        ...state,
        [action.key]: state[action.key].filter((i) => i !== action.value),
      }
    case ACTIONS.CLEAR:
      return initialValue
    case ACTIONS.GET:
      return {
        ...action.offer,
        mustHaves: action.offer.mustHaves?.split(','),
        niceToHaves: action.offer.niceToHaves?.split(','),
        recruitmentProcessSteps:
          action.offer.recruitmentProcessSteps?.split(',').filter((i) => i) ||
          [],
        aboutOffer: action.offer.aboutOffer ?? '',
        enviroment: action.offer.enviroment?.split(',').filter((i) => i) || [],
        keyAdvantages:
          action.offer.keyAdvantages?.split(',').filter((i) => i) || [],
        methodologies:
          action.offer.methodologies?.split(',').filter((i) => i) || [],
        projectStack:
          action.offer.projectStack?.split(',').filter((i) => i) || [],
      }
    case ACTIONS.COPY:
      return {
        ...action.offer,
        id: null,
        mustHaves: action.offer.mustHaves?.split(','),
        niceToHaves: action.offer.niceToHaves?.split(','),
        recruitmentProcessSteps:
          action.offer.recruitmentProcessSteps?.split(',').filter((i) => i) ||
          [],
        aboutOffer: action.offer.aboutOffer ?? '',
        enviroment: action.offer.enviroment?.split(',').filter((i) => i) || [],
        keyAdvantages:
          action.offer.keyAdvantages?.split(',').filter((i) => i) || [],
        methodologies:
          action.offer.methodologies?.split(',').filter((i) => i) || [],
        projectStack:
          action.offer.projectStack?.split(',').filter((i) => i) || [],
      }
    default:
      return state
  }
}
export default reducer

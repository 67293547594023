import React from 'react'
import { useEffect } from 'react'
import { useFilter } from '../../../../contexts/FilterContext'

const AverageSalary = ({ offersCount, minSalary, maxSalary }) => {
  const { valueLevel, valuePosition, valueLocation, valueType } = useFilter()

  const progressBar = (bar, percentage) => {
    const elem = document.getElementById(bar)
    let width = 0
    const id = setInterval(frame, 10)
    function frame() {
      if (width >= percentage || width >= 100) {
        clearInterval(id)
      } else {
        width++
        elem.style.width = width + '%'
      }
    }
  }


  useEffect(() => {
    //offers
    const avgOffers = 30000 // todo auto calculate
    const percentOferrs = (offersCount / avgOffers) * 100
    //salaries
    const avgSalary = 25000 //todo auto calculate
    const percentSalary = ((minSalary + avgSalary) / 2 / avgSalary) * 100
    progressBar('salaryBar', percentSalary || 0)
    progressBar('offersBar', percentOferrs || 0)
  }, [offersCount, minSalary])

  return (
    <div className="averagesalary">
      <div className="bar-card">
        <div className="techonology-card">
          <p className="basic-card-text">
            {valueLevel} {valuePosition}
          </p>
          <p className="subtext">
            {valueLocation} {valueType}
          </p>
        </div>
        <div className="progressBar">
          <p className="basic-card-text">{`${(
            minSalary || 0
          ).toLocaleString()} - ${(maxSalary || 0).toLocaleString()} PLN`}</p>
          <div id="myProgress">
            <div id="salaryBar"></div>
          </div>
        </div>
        <div className="amountOffers-card">
          <p className="basic-card-text">Competitive offers</p>
        </div>
        <div className="progressBar">
          <p className="basic-card-text">
            {Math.floor(offersCount || 0).toLocaleString()} offers
          </p>
          <div id="myProgress">
            <div id="offersBar"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AverageSalary
import React from 'react'
import styled from 'styled-components'
import { SubTitle, Title } from '../AccountInfoStyles.jsx'
import ProgressBar from '../ProgressBar.jsx'
import Edit from '../../../Icons/Edit.jsx'
import Tick from '../../../Icons/YellowTick.jsx'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../../../contexts/AuthContext.js'

const AccountInfoCompanyPreview = () => {
  const history = useHistory()
  const { account } = useAuth()
  const v = {
    companyName: account?.company?.companyName,
    companyYear: account?.company?.companyYear,
    country: account?.company?.country,
    employeesNumber: account?.company?.employeesNumber,
    zipCode: account?.company?.zipCode,
    city: account?.company?.city,
    formOfCooperation: account?.company?.formOfCooperation,
    aboutCompany: account?.company?.aboutCompany,
    street: account?.company?.street,
    houseNumber: account?.company?.houseNumber,
    apartmentNumber: account?.company?.apartmentNumber,
    logoImage: account?.company?.logoImage,
  }

  return (
    <>
      <Container>
        <Top>
          <div>
            <Title>Company profile</Title>
            <SubTitle>Settings</SubTitle>
          </div>
          <Edit
            style={{ cursor: 'pointer' }}
            onClick={() => history.push('/account/company')}
          />
        </Top>
        <Preview>
          {v?.logoImage && <LogoImage src={v?.logoImage} alt="logo" />}
          <PreviewTitleLabel>Company name:</PreviewTitleLabel>
          <PreviewTitle>{v?.companyName || '--'}</PreviewTitle>
          <PreviewTitleLabel>Foundation year:</PreviewTitleLabel>
          <PreviewTitle>{v?.companyYear || '--'}</PreviewTitle>
          <PreviewTitleLabel>Number of employees:</PreviewTitleLabel>
          <PreviewTitle>{v?.employeesNumber || '--'}</PreviewTitle>
          <PreviewTitleLabel>HQ adress:</PreviewTitleLabel>
          <PreviewStreet>
            {v?.country
              ? `${v?.country}, ${v?.zipCode} ${v.city},
            ${v?.street} ${v?.houseNumber}${
                  v?.apartmentNumber && '/' + v?.apartmentNumber
                }
            `
              : '--'}
          </PreviewStreet>
          <PreviewTitleLabel>Company description:</PreviewTitleLabel>
          <PreviewAbout dangerouslySetInnerHTML={{__html: v?.aboutCompany || '--'}}/>
          <PreviewTitleLabel>Forms of cooperation:</PreviewTitleLabel>
          {v?.formOfCooperation
            ? v?.formOfCooperation.split(',').map((i, index) => {
                return (
                  <GreyBlock key={index}>
                    <Tick />
                    {i}
                  </GreyBlock>
                )
              })
            : '--'}
        </Preview>
      </Container>
      <ProgressBar
        steps={[
          {
            title: 'Company profile settings',
            href: '/account/company',
          },
          {
            title: 'Preview',
            href: '',
            active: true,
          },
        ]}
      />
    </>
  )
}

const Container = styled.div`
box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background-color: #fff;
  width: 450px;
  overflow: hidden;
`
const Top = styled.div`
  border-left: 3px solid #ffcb4a;
  padding: 36px;
  border-bottom: 1px solid #ededed;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Preview = styled.div`
  padding: 40px;
`

const PreviewTitleLabel = styled.div`
  font-size: 15px;
  line-height: 22px;
  color: #ababab;
  margin-top: 20px;
  margin-bottom: 10px;
`
const PreviewTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #3d3c3c;
  min-height: 27px;
`
const PreviewStreet = styled.div`
font-weight: 500;
font-size: 16px;
line-height: 24px;
color: #3D3C3C;
`
const PreviewAbout = styled.div`
font-weight: 500;
font-size: 14px;
line-height: 21px;
color: #3D3C3C;
`
const GreyBlock = styled.div`
  background: #f5f5f5;
  padding: 16px 20px;
  border-radius: 10px;
  display: flex;
  gap: 20px;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #6b6b6b;
  margin: 1px 0;
`

const LogoImage = styled.img`
max-width: 30%;
`


export default AccountInfoCompanyPreview

import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="12"
      fill="none"
      viewBox="0 0 14 12"
    >
      <path
        fill="#3D3C3C"
        fillRule="evenodd"
        d="M2.182 3.273c-.6 0-1.091-.491-1.091-1.091S1.58 1.09 2.18 1.09h8.728v1.09H12V0H2.182C.982 0 0 .982 0 2.182v7.09A2.7 2.7 0 002.727 12h10.364V3.273H2.18zM12 10.909H2.727c-.927 0-1.636-.709-1.636-1.636V4.036c.327.219.709.328 1.09.328H12v6.545z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;

import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import openInNewTab from '../../../lib/helpers/openInNewTab.js'
import RecruitmentIcon from '../../Icons/Recruitments'
const RecruitmentSuccesPage = () => {
  const { state } = useLocation()

  return (
    <div className="recruitment-success-container">
      <div className="topline-recr" />
      <div className="recruitment-thank">
        {state.edited
          ? 'Recruitment edited successfully'
          : 'Thank you for adding recruitment'}
      </div>
      <div className="recruitment-success-info-container">
        <RecruitmentIcon />
        <div>
          {state.active
            ? 'Your recruitment has been published and is available now'
            : 'Your recruitment is not yet active on job board, you need to renew it. However, you can still check what it will look like on the job board after renewal'}
        </div>
      </div>
      <button
        onClick={() => openInNewTab('https://workeo.dev/offer/' + state.slug)}
      >
        Check recruitment
      </button>
      <Link to="/recruitments">Go to recruitments details</Link>
    </div>
  )
}

export default RecruitmentSuccesPage

import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="12"
      fill="none"
      viewBox="0 0 14 12"
    >
      <path
        fill="#FFB600"
        d="M3.949 8.82L1.4 6.272 0 7.673l3.362 3.362.004-.004.614.614 9.492-9.492L12.044.725 3.948 8.82z"
      ></path>
    </svg>
  );
}

export default Icon;

import React from 'react'
import styled from 'styled-components'

function Icon() {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 52 52"
    >
      <g clipPath="url(#clip0)">
        <path
          fill="url(#paint0_linear)"
          d="M25.837 51.126c14.017 0 25.38-11.363 25.38-25.38 0-14.018-11.363-25.38-25.38-25.38S.457 11.727.457 25.745c0 14.017 11.363 25.38 25.38 25.38z"
          opacity="0.5"
        ></path>
        <path
          fill="#F5F5F5"
          d="M25.837 51.126c14.017 0 25.38-11.363 25.38-25.38 0-14.018-11.363-25.38-25.38-25.38S.457 11.727.457 25.745c0 14.017 11.363 25.38 25.38 25.38z"
        ></path>
        <path
          fill="#FFC63A"
          d="M25.91 50.527a24.726 24.726 0 0013.656-4.087c-.915-5.004-4.4-5.289-4.4-5.289H15.954s-3.288.27-4.314 4.873a24.731 24.731 0 0014.27 4.503z"
        ></path>
        <path
          fill="#333"
          d="M25.646 37.052c6.623 0 11.992-5.37 11.992-11.992 0-6.623-5.37-11.992-11.992-11.992-6.623 0-11.992 5.369-11.992 11.992 0 6.623 5.369 11.992 11.992 11.992z"
        ></path>
        <path
          fill="#000"
          d="M21.775 35.048h7.684v6.148a3.842 3.842 0 11-7.682 0v-6.148h-.002z"
          opacity="0.1"
        ></path>
        <path
          fill="#FDB797"
          d="M22.019 34.763h7.2a.243.243 0 01.243.244v5.904a3.842 3.842 0 11-7.685 0v-5.904a.244.244 0 01.242-.244z"
        ></path>
        <path
          fill="#000"
          d="M21.79 37.95a11.06 11.06 0 007.683.021v-.945H21.79v.924z"
          opacity="0.1"
        ></path>
        <path
          fill="#FDB797"
          d="M25.646 38.464c6.082 0 11.014-4.93 11.014-11.014 0-6.082-4.931-11.014-11.014-11.014-6.083 0-11.014 4.931-11.014 11.014 0 6.083 4.93 11.014 11.014 11.014z"
        ></path>
        <path
          fill="#000"
          d="M17.8 17.432s4.642 9.422 17.736 3.95l-3.049-4.78-5.404-1.94-9.283 2.77z"
          opacity="0.1"
        ></path>
        <path
          fill="#333"
          d="M17.8 17.294s4.642 9.422 17.736 3.949l-3.049-4.78-5.404-1.94-9.283 2.77z"
        ></path>
        <path
          fill="#333"
          d="M17.76 16.655a6.362 6.362 0 011.577-2.565c2.166-2.141 5.718-2.593 7.54-5.035.436.678.099 1.72-.654 2.01 1.745-.01 3.767-.164 4.754-1.6a3.265 3.265 0 01-.55 3.448c1.546.073 3.2 1.12 3.297 2.665.067 1.03-.582 2.005-1.425 2.595-.843.59-1.87.862-2.877 1.084-2.94.649-13.582 3.367-11.662-2.602z"
        ></path>
        <path
          fill="#FDB797"
          d="M14.627 29.361c.566 0 1.025-.86 1.025-1.92 0-1.062-.459-1.922-1.025-1.922-.566 0-1.024.86-1.024 1.921s.459 1.921 1.024 1.921zM36.658 29.361c.566 0 1.025-.86 1.025-1.92 0-1.062-.459-1.922-1.025-1.922-.566 0-1.025.86-1.025 1.921s.46 1.921 1.025 1.921z"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="25.837"
          x2="25.837"
          y1="51.126"
          y2="0.365"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity="0.25"></stop>
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12"></stop>
          <stop offset="1" stopColor="gray" stopOpacity="0.1"></stop>
        </linearGradient>
        <clipPath id="clip0">
          <path
            fill="#fff"
            d="M0 0H50.761V50.761H0z"
            transform="translate(.457 .365)"
          ></path>
        </clipPath>
      </defs>
    </StyledSvg>
  )
}

export default Icon

const StyledSvg = styled.svg`
margin-right: 10px;
`

import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="8"
      fill="none"
      viewBox="0 0 26 8"
    >
      <path
        fill="#ABABAB"
        d="M4.383.12c.19-.16.488-.16.677 0a.362.362 0 010 .568L1.627 3.595h23.87c.264 0 .481.178.481.402 0 .224-.217.407-.48.407H1.627L5.06 7.306a.368.368 0 010 .574.536.536 0 01-.677 0L.137 4.284a.362.362 0 010-.568L4.383.12z"
      ></path>
    </svg>
  );
}

export default Icon;

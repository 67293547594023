import React from 'react'
import styled from 'styled-components'

const DropdownItem = ({ text, click, Icon }) => {
  return (
    <Item onClick={click}>
      <IconWrapper>{Icon && <Icon />}</IconWrapper> {text}
    </Item>
  )
}

const Item = styled.li`
  display: flex;
  align-items: center;
  padding-left: 18px;
  border-radius: 5px 5px 0px 0px;
  gap: 11px;
  font-weight: 500;
  font-size: 12px;
  color: #6B6B6B;
  height: 50px;
  cursor: pointer;
  &:hover {
    background: #f6f6f9;
  }
`
const IconWrapper = styled.span`
  width: 11px;

`

export default DropdownItem

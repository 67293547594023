import React from 'react'

import styled from 'styled-components'
import { useSWRConfig } from 'swr'

function Icon({ id, jobSlug: jobOfferId, status }) {
  const { mutate } = useSWRConfig()
  const handleRemove = async () => {
    const token = localStorage.getItem('wrk_token')

    const candidateToRemove = {
      jobOfferId: Number(jobOfferId),
      candidateId: id,
    }
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/applications`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${token}`,
      },
      body: JSON.stringify(candidateToRemove),
    }).then(() => {
      mutate([
        `${process.env.REACT_APP_BACKEND_URL}/api/recruitments/${jobOfferId}?status=${status}`,
        token,
      ])
    })
  }
  return (
    <StyledSvg
      onClick={handleRemove}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <rect width="30" height="30" fill="#F5F5F5" rx="15"></rect>
      <path
        fill="#F34938"
        d="M15.817 15l3.63-3.63a.577.577 0 10-.816-.816L15 14.184l-3.63-3.63a.577.577 0 10-.817.816l3.63 3.63-3.63 3.63a.577.577 0 10.816.816l3.63-3.63 3.63 3.63a.575.575 0 00.817 0 .577.577 0 000-.816L15.817 15z"
      ></path>
    </StyledSvg>
  )
}
const StyledSvg = styled.svg`
  &:hover {
    rect {
      transition: 0.2s;
      fill: #f3dddb;
    }
  }
`
export default Icon

const positions = [
  'All positions',
  'Frontend',
  'Backend',
  'Fullstack',
  'Mobile',
  'QA',
  'Database',
  'DevOPS',
  'UX/UI',
  'Project Manager',
]

export default positions

import styled from 'styled-components'

const RemoveButton = styled.button`
  cursor: pointer;
  position: relative;
  background-color: #F5F5F5;
  min-width: 26px;
  height: 26px;
  border: none;
  border-radius: 50% !important;
  &:hover {
    transition: 0.2s;
    background-color: #f3dddb;
  }
  &::after {
    content: '';
    width: 12px;
    height: 1px;
    background-color: #f34938;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::before {
    content: '';
    width: 1px;
    height: 12px;
    background-color: #f34938;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
`
export default RemoveButton

import React, { useRef } from 'react'
import styled from 'styled-components'
import { ReactComponent as Star } from '../../../../img/star.svg'
import { ACTIONS } from '../reducer.js'
import RemoveButton from './RemoveButton.jsx'

const SkillsPickerComponent = ({ type, id, setSkillsArray, name, item }) => {
  const skill = item.split(':')[0]
  const skillLevel = item.split(':')[1]

  const inputRef = useRef()

  const handleUpdate = (e, level) => {
    if (e) {
      inputRef.current.classList.remove('empty')
      setSkillsArray({
        type: ACTIONS.ADD_SKILL,
        key: name,
        skillName: e.target.value,
        level,
        id,
      })
      return
    }
    if (level && skill) {
      setSkillsArray({
        type: ACTIONS.ADD_SKILL,
        key: name,
        skillName: skill,
        level,
        id,
      })
    } else {
      inputRef.current.classList.add('empty')
    }
  }
  let ratePicker = [1, 2, 3, 4, 5].map((lv) => {
    return (
      <StarButton
        onClick={() => handleUpdate(null, lv)}
        tabIndex="-1"
        key={lv}
        id={lv}
        className="skill-star"
      >
        <Star className={`star ${lv > skillLevel ? '' : 'active'}`} />
      </StarButton>
    )
  })

  return (
    <SkillCard>
      <InputContainer>
        <Input
          ref={inputRef}
          value={skill || ''}
          maxLength={24}
          onChange={(e) => {
            e.persist()
            if (e.target.value !== '') {
              e.target.classList.remove('empty')
            }
            handleUpdate(e, 0)
          }}
          type="text"
          placeholder="eg React"
        />
        <div id={type + '' + id}>{ratePicker}</div>
      </InputContainer>
      {skill ? (
        <RemoveButton
          onClick={() => {
            setSkillsArray({
              type: ACTIONS.REMOVE_SKILL,
              skillName: item.split(':')[0],
              key: name,
            })
          }}
        />
      ) : null}
    </SkillCard>
  )
}

export default SkillsPickerComponent

const SkillCard = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
  background-color: white;
  border: 2px solid #ededed;
  border-radius: 10px;
  padding: 5px 14px;
  gap: 15px;
`
const InputContainer = styled.div`
  width: 100%;
`
const Input = styled.input`
  width: 100%;
  padding-top: 8px;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  color: #6b6b6b;
  &::placeholder {
    color: #ababab;
  }
`
const StarButton = styled.button`
  background: transparent;
  outline: none;
  border: none;
`

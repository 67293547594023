import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="29"
      fill="none"
      viewBox="0 0 29 29"
    >
      <path
        fill="url(#paint0_linear)"
        d="M14.5 29C22.508 29 29 22.508 29 14.5S22.508 0 14.5 0 0 6.492 0 14.5 6.492 29 14.5 29z"
        opacity="0.5"
      ></path>
      <path
        fill="#F5F5F5"
        d="M14.5 29C22.508 29 29 22.508 29 14.5S22.508 0 14.5 0 0 6.492 0 14.5 6.492 29 14.5 29z"
      ></path>
      <path
        fill="#FFC63A"
        d="M14.541 28.658a14.126 14.126 0 007.803-2.335C21.82 23.464 19.83 23.3 19.83 23.3H8.853s-1.878.155-2.465 2.784a14.129 14.129 0 008.153 2.573z"
      ></path>
      <path
        fill="#333"
        d="M14.39 20.96a6.851 6.851 0 100-13.703 6.851 6.851 0 000 13.702z"
      ></path>
      <path
        fill="#000"
        d="M12.18 19.815h4.39v3.512a2.195 2.195 0 01-2.195 2.195 2.195 2.195 0 01-2.195-2.195v-3.512z"
        opacity="0.1"
      ></path>
      <path
        fill="#FDB797"
        d="M12.319 19.652h4.113a.14.14 0 01.14.14v3.372a2.195 2.195 0 01-2.197 2.195 2.195 2.195 0 01-2.195-2.195v-3.373a.139.139 0 01.139-.14z"
      ></path>
      <path
        fill="#000"
        d="M12.188 21.473a6.319 6.319 0 004.39.012v-.54h-4.39v.527z"
        opacity="0.1"
      ></path>
      <path
        fill="#FDB797"
        d="M14.39 21.766a6.292 6.292 0 100-12.584 6.292 6.292 0 000 12.584z"
      ></path>
      <path
        fill="#000"
        d="M9.909 9.75s2.652 5.383 10.132 2.257L18.3 9.277l-3.087-1.109L9.91 9.75z"
        opacity="0.1"
      ></path>
      <path
        fill="#333"
        d="M9.909 9.671s2.652 5.383 10.132 2.256L18.3 9.197l-3.087-1.109L9.91 9.671z"
      ></path>
      <path
        fill="#333"
        d="M9.885 9.307a3.635 3.635 0 01.902-1.466c1.237-1.223 3.266-1.481 4.307-2.877.25.388.057.983-.374 1.15.997-.007 2.153-.095 2.717-.915a1.865 1.865 0 01-.315 1.97c.884.041 1.828.64 1.884 1.522.038.589-.333 1.145-.814 1.483-.482.337-1.068.492-1.644.619-1.68.37-7.76 1.923-6.663-1.486z"
      ></path>
      <path
        fill="#FDB797"
        d="M8.096 16.566c.323 0 .585-.492.585-1.098 0-.606-.262-1.098-.585-1.098-.323 0-.585.492-.585 1.098 0 .606.262 1.098.585 1.098zM20.682 16.566c.324 0 .586-.492.586-1.098 0-.606-.262-1.098-.586-1.098-.323 0-.585.492-.585 1.098 0 .606.262 1.098.585 1.098z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="14.5"
          x2="14.5"
          y1="29"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity="0.25"></stop>
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12"></stop>
          <stop offset="1" stopColor="gray" stopOpacity="0.1"></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Icon

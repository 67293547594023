import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="16"
      fill="none"
      viewBox="0 0 18 16"
    >
      <path
        fill="#FFCB4A"
        fillRule="evenodd"
        d="M16.776 3.363a2.028 2.028 0 00-1.439-.593H8.763a.54.54 0 01-.451-.235L7.27.979H2.036A2.033 2.033 0 000 3.005v10.872c0 1.117.913 2.026 2.036 2.026h13.3a2.033 2.033 0 002.037-2.026V4.795c0-.54-.212-1.05-.597-1.432zM1.357 3.005c0-.372.305-.675.679-.675h4.508l.639.954c.039.058.08.113.125.166a1.875 1.875 0 00-.122.162l-.655.947H2.036c-.235 0-.464.04-.679.115v-1.67zm13.98 11.548a.678.678 0 00.678-.676V4.795a.676.676 0 00-.678-.675H8.754c-.164 0-.346.1-.442.245l-.005.008-1.062 1.536H2.036a.676.676 0 00-.679.676v7.292c0 .373.305.676.679.676h13.3z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;

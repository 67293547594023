import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="12"
      fill="none"
      viewBox="0 0 13 12"
    >
      <path
        fill="#ABABAB"
        fillRule="evenodd"
        d="M.46 12h12.08c.258 0 .46-.201.46-.459V1.308a.454.454 0 00-.46-.458h-2.272V.459A.454.454 0 009.808 0a.454.454 0 00-.458.459V.85H3.729V.459A.454.454 0 003.269 0a.454.454 0 00-.458.459V.85H.459A.454.454 0 000 1.308v10.245c0 .246.213.447.46.447zM.917 1.767H2.81v.246c0 .257.202.458.46.458.257 0 .459-.201.459-.458v-.246h5.62v.246c0 .257.202.458.46.458.257 0 .459-.201.459-.458v-.246h1.825v1.185H.918V1.767zm11.164 2.102H.918v7.225h11.164V3.869z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;

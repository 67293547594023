import React from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import googlelogo from '../../img/googlelogo.png'
import facebooklogo from '../../img/facebooklogo.png'
const PopAuthButton = () => {
  const history = useHistory()
  const { googleAuth, setAccount, fbAuth } = useAuth()
  const handleClick = async (google) => {
    try {
      let email
      if (google) {
        const res = await googleAuth()
        email = res.user.email
      } else {
        const res = await fbAuth()
        email = res.user.email
      }

      const data = {
        login: email,
      }
      const acc = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/account`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      )

      const accInfo = await acc.json()
      setAccount(accInfo)

      history.push('/account/company')
    } catch (e) {}
  }
  return (
    <>
      <button className="google" onClick={() => handleClick(true)}>
        <img alt="googlelogo" src={googlelogo} />
        <p>Continue with Google</p>
      </button>
      {/* <button className="facebook" onClick={() => handleClick()}>
        <img alt="facebooklogo" src={facebooklogo} />
        Continue with Facebook
      </button> */}
    </>
  )
}

export default PopAuthButton

import React from 'react'

import LoginLock from '../Icons/Lock'
import LoginIcon from '../Icons/LoginIcon.jsx'
import DownArrow from '../Icons/CircleArrow'

const HiddenLogin = ({ text, switchLogin }) => {
  return (
    <div className="hidden-login" onClick={switchLogin}>
      {text === 'Log In' ? <LoginLock sm /> : <LoginIcon sm />}

      <span className="hidden-flex">
        <span className="hidden-text">{text}</span>
        <DownArrow />
      </span>
    </div>
  )
}

export default HiddenLogin

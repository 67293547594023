import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="22"
      fill="none"
      viewBox="0 0 30 22"
    >
      <path
        fill="gray"
        fillRule="evenodd"
        d="M15.4 13.2c1.833-1.345 2.933-3.544 2.933-5.867C18.333 3.3 15.033 0 11 0S3.667 3.3 3.667 7.333c0 2.323 1.1 4.522 2.933 5.867C3.056 14.789.489 18.089 0 22h2.444c.612-4.156 4.278-7.333 8.556-7.333s7.944 3.177 8.555 7.333H22c-.489-3.911-3.056-7.211-6.6-8.8zm-4.4-.978A4.903 4.903 0 016.11 7.333c0-2.689 2.2-4.889 4.889-4.889s4.889 2.2 4.889 4.89c0 2.688-2.2 4.888-4.89 4.888zm15.889-3.666c0 1.588-.611 3.177-1.589 4.4 2.444 1.222 4.033 3.788 4.033 6.477H26.89c0-2.322-1.711-4.278-3.911-4.767-.611-.122-.978-.61-.978-1.222 0-.366.122-.733.489-.977 1.1-.856 1.955-2.323 1.955-3.912 0-2.322-1.588-4.4-4.4-4.888v-.001c-.122-.367-.244-.733-.488-1.1-.245-.488-.49-.977-.856-1.344 4.278-.489 8.189 2.934 8.189 7.334z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default Icon

import React from 'react'
import styled, { keyframes } from 'styled-components'

function Icon() {
  return (
    <StyledLogo
      xmlns="http://www.w3.org/2000/svg"
      width="118"
      height="100"
      fill="none"
      viewBox="0 0 118 100"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M90.347 80.803c3.806 0 7.323 2.726 7.323 7.115 0 3.37-2.544 4.456-8.584 4.478h-2.964c.332 2.306 2.434 3.769 5.11 3.769 1.903 0 4.137-.377 5.597-1.73h.155v1.42c0 2.35-1.593 4.145-6.614 4.145-4.535 0-8.982-3.613-8.982-9.222 0-6.14 3.739-9.975 8.96-9.975zm-4.225 7.713l-.044.621h2.035c4.314 0 5.287-.487 5.287-1.773 0-1.84-1.35-2.75-3.45-2.75-2.191 0-3.718 1.796-3.828 3.902zM109.35 80.803c5.487 0 8.65 4.433 8.65 9.288 0 5.52-3.141 9.909-9.048 9.887-5.486 0-8.65-4.39-8.65-9.244 0-5.52 3.142-9.931 9.048-9.931zm-.398 4.078c-2.61 0-3.938 2.172-3.938 5.21 0 3.635 1.328 5.808 4.336 5.808 2.655 0 3.938-2.173 3.938-5.166 0-3.68-1.349-5.852-4.336-5.852z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M8.473 99.778h.62c1.636-2.216 3.76-7.891 4.335-10.684.509 2.793 1.903 6.184 3.009 8.224.62 1.13 1.703 2.438 3.12 2.438h.751c1.195-1.773 2.876-5.143 4.248-9.022 1.35-3.813 2.101-6.983 2.101-7.692 0-1.397-.907-1.973-2.212-1.973h-1.902c-.708 4.145-1.704 8.401-3.43 12.236-1.282-2.593-2.41-6.384-2.942-8.867-.442-2.017-1.836-2.593-3.23-2.593H11.46c-.885 3.88-1.792 7.825-3.45 11.46-1.527-3.325-2.368-6.805-2.92-9.93-.178-1.02-1.085-2.218-2.656-2.218H0c.819 6.119 2.566 11.44 5.22 16.117.598 1.152 1.881 2.504 3.253 2.504z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M37.542 80.803c5.486 0 8.65 4.433 8.65 9.288 0 5.52-3.142 9.909-9.048 9.887-5.487 0-8.65-4.39-8.65-9.244 0-5.52 3.141-9.931 9.048-9.931zm-.376 4.078c-2.61 0-3.938 2.172-3.938 5.21 0 3.635 1.305 5.808 4.336 5.808 2.655 0 3.938-2.173 3.938-5.166 0-3.68-1.35-5.852-4.336-5.852z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M64.486 75.084h-1.46v21.26c0 2.105.974 3.236 3.009 3.236h1.549v-8.978c1.99 2.682 4.026 5.409 6.37 7.825.775.776 1.97 1.264 2.987 1.264 1.858 0 2.61-.776 3.075-2.24-1.128-.376-1.858-.709-3.517-2.371-1.637-1.663-3.053-3.547-4.513-5.343 1.902-2.416 6.128-7.249 7.433-8.335v-.177h-2.61c-1.46 0-2.544.177-3.629 1.197-1.526 1.463-2.92 3.059-5.597 6.672v-10.95c0-1.796-.906-3.06-3.097-3.06zM54.332 99.58V89.028c0-2.793 1.129-3.902 3.164-3.902 1.194 0 1.969.266 2.654.554h.177v-1.64c0-1.397-.973-2.993-3.805-2.993-4.822 0-6.747 3.503-6.747 7.183v8.446c0 1.906 1.283 2.926 3.319 2.926h1.238v-.022z"
      ></path>
      <path
        fill="#FFC63A"
        d="M58.262 27.131h.176c5.361 0 9.73-4.339 9.73-9.686 0-5.348-4.347-9.687-9.73-9.687h-.353c-5.36 0-9.729 4.339-9.729 9.687 0 5.347 4.346 9.686 9.73 9.686h.176z"
      ></path>
      <path
        fill="#FFC63A"
        d="M25.28 35.943c1.897 0 3.684.46 5.25 1.293 0-.022.022-.022.022-.044a5.645 5.645 0 003.288 1.052c3.088 0 5.603-2.477 5.603-5.545 0-2.235-1.346-4.164-3.265-5.04a22.84 22.84 0 00-10.898-2.762c-12.31 0-22.305 9.906-22.305 22.113 0 12.208 9.994 22.114 22.305 22.114 4.059 0 7.876-1.074 11.14-2.959l.133-.066c.044-.022.11-.066.155-.087 3.485-1.995 6.75-5.786 9.839-11.375 2.714-4.931 6.619-7.451 11.693-7.605 5.074.154 8.979 2.674 11.692 7.605 3.089 5.589 6.376 9.38 9.84 11.375.044.022.11.065.154.087l.133.066a22.302 22.302 0 0011.14 2.959c12.311 0 22.305-9.906 22.305-22.114 0-12.207-9.994-22.113-22.304-22.113A22.84 22.84 0 0080.3 27.658c-1.919.877-3.265 2.806-3.265 5.041 0 3.068 2.515 5.545 5.604 5.545 1.235 0 2.36-.395 3.287-1.052 0 .022.022.022.022.044a11.143 11.143 0 015.25-1.293c6.156 0 11.142 4.953 11.142 11.045 0 6.115-4.986 11.046-11.141 11.046a11.13 11.13 0 01-8.913-4.427c0-.022-.022-.022-.022-.044a11.272 11.272 0 01-.883-1.38 1042.72 1042.72 0 00-4.699-7.233c-1.875-2.87-3.927-4.997-6.11-6.377l.176-.417c-.066.132-.11.285-.177.417-3.552-2.302-7.766-3.66-12.31-3.77a23.397 23.397 0 00-12.31 3.77c-.067-.132-.111-.285-.177-.417l.176.417c-2.184 1.38-4.236 3.506-6.11 6.377a506.101 506.101 0 00-4.7 7.232c-.265.483-.551.943-.882 1.381 0 .022-.022.022-.022.044a11.191 11.191 0 01-8.913 4.427c-6.155 0-11.142-4.953-11.142-11.046-.044-6.092 4.942-11.045 11.098-11.045zM90.847 19.374c5.373 0 9.729-4.337 9.729-9.687S96.22 0 90.847 0c-5.373 0-9.73 4.337-9.73 9.687s4.357 9.687 9.73 9.687zM25.677 19.374c5.373 0 9.729-4.337 9.729-9.687S31.05 0 25.676 0c-5.373 0-9.728 4.337-9.728 9.687s4.355 9.687 9.729 9.687z"
      ></path>
    </StyledLogo>
  )
}

export default Icon

const floating = keyframes`
from {
  transform: translateY(0px);
}

to {
  transform: translateY(-15px);
}
`

const StyledLogo = styled.svg`
  animation: ${floating} 1s infinite ease-in-out;
  animation-direction: alternate;
`

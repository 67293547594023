import React, { useState } from 'react'
import { useFilter } from '../../../../contexts/FilterContext'
import locationsList from './filters/locations.js'
import levelsList from './filters/levels.js'
import contractTypesList from './filters/contractTypes.js'
import positionsList from './filters/positions.js'
import styled from 'styled-components'
import FilterButton from './filters/FilterButton.jsx'
import media from '../../../../lib/media.js'
import technologies from './filters/technologies.js'

const Filter = () => {
  const { valuePosition, change } = useFilter()

  //candidates filter
  const reset = () => {
    change('Poland', 'valueLocation')
    change('All seniority levels', 'valueLevel')
    change('All contracts', 'valueType')
    change('All positions', 'valuePosition')
    change('All technologies', 'valueTechnology')
  }
  const [visible, setVisible] = useState(false)
  return (
    <>
      <MenuButton onClick={() => setVisible((prev) => !prev)}>
        {'Filtruj'}
      </MenuButton>
      <TopFilter visible={visible}>
        <FilterButton values={positionsList} type="valuePosition" />
        <FilterButton
          disabled={valuePosition === 'All positions'}
          values={technologies(valuePosition)}
          type="valueTechnology"
        />
        <FilterButton values={levelsList} type="valueLevel" />
        <FilterButton values={contractTypesList} type="valueType" />
        <FilterButton disabled values={locationsList} type="valueLocation" />
        <SearchButton onClick={reset}>Reset</SearchButton>
      </TopFilter>
    </>
  )
}

const SearchButton = styled.button`
  background: #ffcb4a;
  border-radius: 10px;
  border: none;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #66511e;
  padding: 5px 20px;
  height: 40px;
  cursor: pointer;
  ${media.lessThan('xl')`
  height: 32px;
  font-size: 11px;
  line-height: 16px;
  `}
`

const TopFilter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  ${media.lessThan('xl')`
  gap: 5px;
  `}
  ${media.lessThan('md')`
  opacity: 0;
  height: 0;
  transition: opacity .3s;
  ${({ visible }) =>
    visible &&
    `
  height: auto;
  opacity: 1;
  margin-top: 10px;
  `}
  flex-direction: column;
  `}
`
const MenuButton = styled.button`
  margin-left: auto;
  display: none;
  border: none;
  padding: 5px 10px;
  width: 100%;
  background-color: #ffcb4a;
  border-radius: 10px;
  cursor: pointer;
  ${media.lessThan('md')`
display: block;
`}
`

export default Filter

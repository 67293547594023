import React from 'react'
import styled from 'styled-components'
import { createPortal } from 'react-dom'

const Modal = ({
  open,
  children,
  onClose,
  overlayClick,
  title,
  info,
  buttonText,
}) => {
  if (!open) return null

  return createPortal(
    <>
      <Overlay onClick={overlayClick} />
      <ModalStyles>
        <StyledTitle>{title}</StyledTitle>
        <BreakLine />
        <Padding>
          <Info>{info}</Info>
          {children}
          <StyledButton onClick={onClose}>{buttonText}</StyledButton>
        </Padding>
      </ModalStyles>
    </>,
    document.getElementById('portal')
  )
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0f0b2e;
  opacity: 0.7;
  z-index: 1000;
`

const ModalStyles = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 40px 0;
  z-index: 1000;
  border-top: 3px solid #ffcb4a;
`
const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #3d3c3c;
  margin-left: 40px;
`
const BreakLine = styled.div`
  margin: 30px 0;
  height: 1px;
  background: #ededed;
`
const Padding = styled.div`
  padding: 0 40px;
`
const Info = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #3d3c3c;
  margin-bottom: 20px;
`

const StyledButton = styled.button`
  background: #ffcb4a;
  border-radius: 10px;
  width: 100%;
  height: 50px;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #66511e;
  cursor: pointer;
  border: none;
  margin-top: 40px;
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    color: #fff;
    background-color: #FFB600;
  }
`
export default Modal

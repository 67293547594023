import React, { useState } from 'react';
import Login from './Login';
import Signup from './Signup';

const MainLogin = () => {
    const [isLogin, setIsLogin] = useState(false)
    const switchIsLogin = () => {
        setIsLogin(prev => !prev)
    }
    return ( 
        <div className="login-form-bg">
            {isLogin ? <Login switchLogin={switchIsLogin}/> : <Signup switchLogin={switchIsLogin}/>}
        </div>
     );
}
 
export default MainLogin;
import React from 'react'
import styled from 'styled-components'
import CompanySvg from './CompanySvg.jsx'

const LocationsGridComponent = ({ offers }) => {
  let locations = [
    {
      location: 'Warsaw',
      offers: offers?.warsaw,
    },
    {
      location: 'Gdansk',
      offers: offers?.gdansk,
    },
    {
      location: 'Cracow',
      offers: offers?.cracow,
    },
    {
      location: 'Wroclaw',
      offers: offers?.wroclaw,
    },
    {
      location: 'Poznan',
      offers: offers?.poznan,
    },
    {
      location: 'Lodz',
      offers: offers?.lodz,
    },
    {
      location: 'Katowice',
      offers: offers?.katowice,
    },
    {
      location: 'Lublin',
      offers: offers?.lublin,
    },
  ]

  let locationsContainers = locations.map((location, index) => {
    return (
      <Box key={index}>
        <CompanySvg />
        <Offers>{location.offers}</Offers>
        <Location>{location.location}</Location>
        <Info>Amount of offers</Info>
      </Box>
    )
  })
  return <Container>{locationsContainers}</Container>
}
const Container = styled.div`
  justify-content: stretch;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, auto));
  grid-template-rows: 1fr 1fr;
  gap: 20px;
  min-height: 400px;
  padding-top: 17px;
`
const Box = styled.div`
  position: relative;
  background: #ffffff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 30px 40px;
`
const Offers = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  text-transform: uppercase;
  color: #ffcb4a;
`
const Location = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #3d3c3c;
`
const Info = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #ababab;
  opacity: 0.9;
`

export default LocationsGridComponent

import React, { useMemo, useState } from 'react'
import Chart from '../Home/Charts/ChartSalaryComponent'
import styled from 'styled-components'

import {
  expectactionCandidatesGeneral,
  expectactionCandidatesEnviroment,
} from './rightPanel/expectactionCandidates.js'
import LocationsGridComponent from './locationsGrid/LocationsGridComponent.jsx'
import TopMenu from '../../sharedComponents/TopMenu/TopMenu.jsx'
import SalariesGrid from './salariesGrid/SalariesGrid.jsx'
import CompaniesList from './rightPanel/CompaniesList.jsx'
import MarketDataIcon from '../../Icons/Salaries.jsx'
import { contract, seniority } from '../../../lib/helpers/filterHelper.js'
import { useFilter } from '../../../contexts/FilterContext.js'
import Loading from '../../sharedComponents/loading/Loading.jsx'
import ErrorPage from '../../sharedComponents/error/ErrorPage.jsx'
import useSWR from 'swr'

const MarketData = () => {
  const [generalPref, setGeneralPref] = useState('General')
  const { valueLevel, valueType, valuePosition, valueTechnology } = useFilter()
  const params = useMemo(
    () => ({
      candidates: {
        avg: true,
        level: valueLevel !== 'All seniority levels' ? valueLevel : '',
        type: valueType !== 'All contracts' ? contract(valueType) : '',
        position: valuePosition !== 'All positions' ? valuePosition : '',
        technology:
          valueTechnology !== 'All technologies' ? valueTechnology : '',
      },
      offers: {
        len: true,
        avg: true,
        contracts: true,
        companies: true,
        level:
          valueLevel !== 'All seniority levels' ? seniority(valueLevel) : '',
        type: valueType !== 'All contracts' ? contract(valueType) : '',
        position: valuePosition !== 'All positions' ? valuePosition : '',
        technology:
          valueTechnology !== 'All technologies' ? valueTechnology : '',
      },
    }),
    [valueLevel, valueType, valuePosition, valueTechnology]
  )
  const token = localStorage.getItem('wrk_token')

  const { data: candidatesData, error: errorCandidates } = useSWR([
    `${process.env.REACT_APP_BACKEND_URL}/api/candidates`,
    token,
    params.candidates,
  ])
  const loadingCandidates = !candidatesData
  const { data: offersData, error: errorOffers } = useSWR([
    `${process.env.REACT_APP_BACKEND_URL}/api/all-offers`,
    token,
    params.offers,
  ])
  const loadingOffers = !offersData
  const [salaries, setSalaries] = useState(true)

  if (errorOffers || errorCandidates) {
    return <ErrorPage />
  }

  const handleGeneralPrefClick = (e) => {
    switch (e.target.innerText) {
      case 'GENERAL':
        setGeneralPref('General')
        break
      case 'ENVIROMENT':
        setGeneralPref('Enviroment')
        break
      case 'COMPANIES':
        setGeneralPref('Companies')
        break
      default:
        break
    }
  }

  const generalList = expectactionCandidatesGeneral(
    candidatesData?.candidates || []
  )
    ?.sort((a, b) => {
      return b.percent - a.percent
    })
    ?.map((i, index) => {
      return (
        <li key={index}>
          <p>{i.percent || 0}%</p>
          {i.expectation || 0} <div style={{ width: `${i.percent || 0}%` }} />
        </li>
      )
    })

  const prefList = expectactionCandidatesEnviroment(
    candidatesData?.candidates || []
  )
    ?.sort((a, b) => {
      return b.percent - a.percent
    })
    ?.map((i, index) => {
      return (
        <li key={index}>
          <p>{i.percent || 0}%</p>
          {i.expectation || 0} <div style={{ width: `${i.percent || 0}%` }} />
        </li>
      )
    })

  return (
    <>
      {(loadingOffers || loadingCandidates) && <Loading />}
      <div className="salaries">
        <TopMenu
          pageName={`Market data`}
          classname="candidates-filter"
          tooltipHeader={'Market data'}
          TooltipIcon={MarketDataIcon}
          information={`In this panel you will find up-to-date information on salary rates for various positions in the largest cities in Poland. 
In the column on the right you will find a list of companies with ads with the indicated filters, as well as information about the preferences of candidates for the position. 
All posted data is updated once a week.`}
        />
        <div className="salaries-page">
          <div className="salaries-left">
            <div className="salaries-chart">
              <div className="salaries-chart-container">
                {salaries ? (
                  <>
                    <ChartHeader>
                      AVERAGE SALARY IN THE LARGEST POLISH CITIES
                    </ChartHeader>
                    <Chart
                      offersData={offersData?.avgOffers}
                      salaries={true}
                      type="bar"
                    />
                  </>
                ) : (
                  <>
                    <ChartHeader>
                      NUMBER OF JOB OFFERS IN MAJOR POLISH CITIES
                    </ChartHeader>
                    <Chart
                      offersData={offersData?.lengthOffers}
                      offers={true}
                      type="bar"
                    />
                  </>
                )}
                <Flex>
                  <Flex>
                    <Button
                      onClick={() => setSalaries((prev) => !prev)}
                      active={!salaries}
                    >
                      Offers
                    </Button>
                    <Button
                      onClick={() => setSalaries((prev) => !prev)}
                      active={salaries}
                    >
                      Salaries
                    </Button>
                  </Flex>
                </Flex>
              </div>
            </div>
            {salaries ? (
              <div className="salaries-charts">
                <SalariesGrid
                  minSalaryOffers={offersData?.avgOffers?.min}
                  maxSalaryOffers={offersData?.avgOffers?.max}
                  minSalaryCandidates={candidatesData?.avgCandidates?.min}
                  maxSalaryCandidates={candidatesData?.avgCandidates?.max}
                  //TODO: counting percentages on the backend
                  uopOffersPercent={39}
                  uopCandidatesPercent={49}
                  b2bOffersPercent={55}
                  b2bCandidatesPercent={47}
                />
              </div>
            ) : (
              <LocationsGridComponent offers={offersData?.lengthOffers} />
            )}
          </div>
          <div className="salaries-right">
            <div className="salaries-preferences">
              <p>
                {generalPref === 'Companies'
                  ? 'LIST OF COMPANIES WITH ANNOUNCEMENTS'
                  : "Candidates' expectations for a new job"}
              </p>
              <ul className="salaries-preferences-list">
                {generalPref === 'General' ? (
                  generalList
                ) : generalPref === 'Enviroment' ? (
                  prefList
                ) : (
                  <CompaniesList companies={offersData?.companies} />
                )}
              </ul>

              <div className="salaries-preferences-bottom">
                <span
                  onClick={handleGeneralPrefClick}
                  className={generalPref === 'General' ? 'active-pref' : ''}
                >
                  General
                </span>
                <span
                  onClick={handleGeneralPrefClick}
                  className={generalPref === 'Enviroment' ? 'active-pref' : ''}
                >
                  Enviroment
                </span>
                <span
                  onClick={handleGeneralPrefClick}
                  className={generalPref === 'Companies' ? 'active-pref' : ''}
                >
                  Companies
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
`
const Button = styled.button`
  border-radius: 5px;
  color: #ababab;
  border: none;
  margin-left: 20px;
  padding: 0 10px;
  cursor: pointer;
  ${({ active }) =>
    active &&
    `
  background: #ffcb4a;
  color: #ffffff;
  `}
`
const ChartHeader = styled.div`
  font-weight: 600;
  font-size: 12px;
  font-weight: 600;
  color: #3d3c3c;
  opacity: 0.9;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 14px;
`
export default MarketData

import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="17"
      fill="none"
      viewBox="0 0 23 17"
    >
      <path
        fill="#ABABAB"
        fillRule="evenodd"
        d="M14.056 5.667c0 1.794-.844 3.494-2.25 4.533 2.718 1.228 4.686 3.778 5.06 6.8h-1.873c-.469-3.211-3.28-5.667-6.56-5.667S2.343 13.79 1.874 17H0c.375-3.022 2.343-5.572 5.06-6.8-1.406-1.039-2.249-2.739-2.249-4.533C2.811 2.55 5.341 0 8.433 0s5.623 2.55 5.623 5.667zm-9.37 0c0 2.077 1.686 3.777 3.748 3.777 2.061 0 3.748-1.7 3.748-3.778 0-2.077-1.687-3.777-3.748-3.777-2.062 0-3.748 1.7-3.748 3.777z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#ABABAB"
        d="M19.51 10.52c.846-.932 1.375-2.142 1.375-3.353 0-3.352-3.384-5.959-7.085-5.587.317.28.528.652.74 1.025.212.279.317.558.423.838 2.432.372 3.807 1.955 3.807 3.724 0 1.21-.74 2.328-1.692 2.98a.817.817 0 00-.423.745c0 .465.317.838.846.93 1.903.373 3.384 1.863 3.384 3.632H23c0-2.048-1.375-4.004-3.49-4.935z"
      ></path>
    </svg>
  );
}

export default Icon;

import React, { useState } from 'react'
import { useHistory } from 'react-router'
import BoolPickerComponent from '../NewJobOffersComponents/BoolPickerComponent'
import SkillsPickerComponent from '../NewJobOffersComponents/SkillsPickerComponent'
import StepPickerComponent from '../NewJobOffersComponents/StepPickerComponent'
import CornerImage from '../../../Icons/AddOfferStep2CornerIcon'
import styled from 'styled-components'
import AboutModal from '../../../sharedComponents/AboutModal.jsx'
import { Container, WhiteTop } from '../NewJobOfferStyledComponents.jsx'
import Button from '../../../sharedComponents/Button.jsx'

const NewJobOfferStep2 = ({
  mustHaves,
  niceToHaves,
  internationalTeam,
  changeProject,
  chooseTools,
  paidHolidays,
  recruitmentProcessSteps,
  aboutOffer,
  dispatch,
}) => {
  const history = useHistory()
  const nextStep = () => {
    history.push('/create-job-offer/3')
  }
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="new-job-offer-left-container">
      <div className="topline-inf" />
      <div className="corner-image">
        <CornerImage />
      </div>
      <Container>
        <WhiteTop>
          <h1>Detailed information</h1>
          <p>Candidate Skills</p>
        </WhiteTop>
        <div>
          <Flex>
            <p className="new-job-title">Indicate the skills required:</p>
          </Flex>
          <Skills>
            {[...mustHaves, ':']
              .filter((i) => i)
              .map((item, index) => (
                <SkillsPickerComponent
                  name="mustHaves"
                  item={item}
                  id={index}
                  key={index}
                  type={'skills'}
                  setSkillsArray={dispatch}
                />
              ))}
          </Skills>
        </div>
        <div>
          <Flex>
            <p className="new-job-title">Indicate skills nice to have:</p>
          </Flex>
          <Skills>
            {[...niceToHaves, ':']
              .filter((i) => i)
              .map((item, index) => (
                <SkillsPickerComponent
                  name="niceToHaves"
                  key={index}
                  item={item}
                  id={index}
                  type={'moreSkills'}
                  setSkillsArray={dispatch}
                />
              ))}
          </Skills>
        </div>
        <div>
          <Flex>
            <p className="new-job-title">Recruitment process:</p>
          </Flex>
          <StepsContainer>
            {[...recruitmentProcessSteps, ''].map((item, index) => (
              <StepPickerComponent
                key={index}
                step={index}
                item={item}
                setStepsArray={dispatch}
              />
            ))}
          </StepsContainer>
        </div>
        <div>
          <p className="new-job-title">Job description:</p>
          <About
            readOnly
            value={aboutOffer}
            rows={10}
            placeholder="Write job description"
            onClick={() => setIsOpen(true)}
          />
          <AboutModal
            initValue={aboutOffer}
            name="aboutOffer"
            open={isOpen}
            onClose={dispatch}
            overlayClick={() => setIsOpen(false)}
            btnText="Add job description"
            modalTitle="Job description"
          />
        </div>
        <SwitchesContainer>
          <BoolPickerComponent
            name="internationalTeam"
            benefit={internationalTeam}
            setBenefit={dispatch}
            txt={'International Team:'}
          />
          <BoolPickerComponent
            name="changeProject"
            benefit={changeProject}
            setBenefit={dispatch}
            txt={'Change the project:'}
          />
          <BoolPickerComponent
            name="chooseTools"
            benefit={chooseTools}
            setBenefit={dispatch}
            txt={'Choice of work tools:'}
          />
          <BoolPickerComponent
            name="paidHolidays"
            benefit={paidHolidays}
            setBenefit={dispatch}
            txt={'Paid leave:'}
          />
        </SwitchesContainer>
        <div>
          <Button onClick={nextStep}>CONTINUE</Button>
        </div>
      </Container>
    </div>
  )
}

const Flex = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`
const About = styled.textarea`
  background: #ffffff;
  border: 2px solid #ededed;
  color: #ababab;
  border-radius: 10px;
  resize: none;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  outline: none;
`
const SwitchesContainer = styled.span`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
const Skills = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`

export default NewJobOfferStep2

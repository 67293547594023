import React from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import media from '../../lib/media.js'
function Icon() {
  const history = useHistory()
  return (
    <StyledLogo
      onClick={() => history.push('/')}
      xmlns="http://www.w3.org/2000/svg"
      width="59"
      height="50"
      fill="none"
      viewBox="0 0 59 50"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M45.021 40.376c1.895 0 3.646 1.36 3.646 3.551 0 1.681-1.267 2.224-4.274 2.235h-1.476c.165 1.15 1.212 1.88 2.545 1.88.947 0 2.06-.188 2.786-.863h.078v.708c0 1.173-.794 2.069-3.294 2.069-2.258 0-4.472-1.803-4.472-4.602 0-3.064 1.861-4.978 4.461-4.978zm-2.104 3.85l-.022.31h1.013c2.148 0 2.633-.244 2.633-.886 0-.918-.672-1.371-1.718-1.371-1.09 0-1.85.896-1.906 1.947zM54.483 40.376c2.732 0 4.308 2.213 4.308 4.635 0 2.755-1.565 4.945-4.506 4.934-2.732 0-4.307-2.19-4.307-4.613 0-2.755 1.564-4.956 4.505-4.956zm-.198 2.036c-1.3 0-1.96 1.084-1.96 2.6 0 1.813.66 2.897 2.158 2.897 1.322 0 1.961-1.084 1.961-2.577 0-1.836-.672-2.92-2.159-2.92z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M4.252 49.845h.309c.815-1.106 1.872-3.938 2.159-5.332.253 1.394.947 3.087 1.498 4.104.308.565.848 1.217 1.553 1.217h.374c.595-.885 1.433-2.566 2.115-4.502.672-1.903 1.047-3.485 1.047-3.839 0-.697-.452-.984-1.102-.984h-.947c-.352 2.069-.848 4.192-1.707 6.106-.64-1.294-1.201-3.186-1.465-4.425-.22-1.006-.915-1.294-1.609-1.294H5.74c-.44 1.936-.892 3.905-1.718 5.72-.76-1.66-1.179-3.397-1.454-4.957-.088-.508-.54-1.106-1.322-1.106H.033c.408 3.053 1.278 5.708 2.6 8.042.297.575.936 1.25 1.62 1.25z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M18.727 40.376c2.732 0 4.307 2.213 4.307 4.635 0 2.755-1.564 4.945-4.506 4.934-2.731 0-4.307-2.19-4.307-4.613 0-2.755 1.565-4.956 4.506-4.956zm-.188 2.036c-1.3 0-1.96 1.084-1.96 2.6 0 1.813.65 2.897 2.159 2.897 1.322 0 1.96-1.084 1.96-2.577 0-1.836-.672-2.92-2.159-2.92z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M32.144 37.522h-.727v10.609c0 1.05.484 1.615 1.498 1.615h.77v-4.48c.992 1.338 2.006 2.699 3.173 3.904.386.388.98.63 1.488.63.925 0 1.3-.386 1.53-1.116-.561-.188-.925-.354-1.75-1.184-.816-.83-1.521-1.77-2.248-2.666.947-1.206 3.051-3.617 3.701-4.16v-.088h-1.3c-.727 0-1.266.089-1.806.598-.76.73-1.454 1.526-2.787 3.33v-5.465c0-.896-.452-1.527-1.542-1.527zM27.087 49.746V44.48c0-1.394.562-1.947 1.576-1.947.595 0 .98.133 1.322.277h.088v-.819c0-.697-.485-1.493-1.895-1.493-2.401 0-3.36 1.748-3.36 3.584v4.215c0 .95.64 1.46 1.653 1.46h.616v-.011z"
      ></path>
      <path
        fill="#FFC63A"
        d="M29.044 13.594h.088c2.67 0 4.845-2.165 4.845-4.834a4.837 4.837 0 00-4.845-4.834h-.176c-2.67 0-4.844 2.166-4.844 4.834a4.837 4.837 0 004.844 4.834h.088z"
      ></path>
      <path
        fill="#FFC63A"
        d="M12.62 17.99c.946 0 1.836.23 2.615.646 0-.011.011-.011.011-.022a2.807 2.807 0 001.637.525c1.538 0 2.79-1.236 2.79-2.767 0-1.116-.67-2.078-1.625-2.516a11.354 11.354 0 00-5.427-1.378c-6.13 0-11.106 4.944-11.106 11.035 0 6.092 4.976 11.035 11.106 11.035 2.021 0 3.922-.536 5.548-1.477l.066-.032c.021-.011.054-.033.076-.044 1.736-.995 3.362-2.887 4.9-5.676 1.351-2.46 3.296-3.718 5.822-3.795 2.527.077 4.471 1.334 5.822 3.795 1.539 2.789 3.175 4.68 4.9 5.676.022.01.055.033.077.044l.066.032a11.087 11.087 0 005.547 1.477c6.13 0 11.107-4.943 11.107-11.035 0-6.091-4.977-11.035-11.106-11.035-1.956 0-3.823.504-5.427 1.378a2.773 2.773 0 00-1.626 2.516c0 1.53 1.252 2.767 2.79 2.767.615 0 1.176-.197 1.637-.525 0 .01.01.01.01.022a5.539 5.539 0 012.616-.646c3.064 0 5.547 2.472 5.547 5.512 0 3.052-2.483 5.512-5.547 5.512a5.537 5.537 0 01-4.439-2.209c0-.01-.01-.01-.01-.022a5.641 5.641 0 01-.44-.689 516.32 516.32 0 00-2.34-3.609c-.934-1.433-1.955-2.493-3.043-3.182l.088-.208c-.033.066-.055.142-.088.208a11.732 11.732 0 00-6.13-1.881 11.634 11.634 0 00-6.13 1.88c-.033-.065-.055-.142-.088-.207l.088.208c-1.087.689-2.109 1.75-3.043 3.182a253.72 253.72 0 00-2.34 3.61c-.131.24-.274.47-.439.688 0 .011-.011.011-.011.022a5.569 5.569 0 01-4.438 2.21c-3.065 0-5.548-2.473-5.548-5.513-.022-3.04 2.46-5.512 5.526-5.512zM45.27 9.722a4.84 4.84 0 004.844-4.833A4.84 4.84 0 0045.27.055a4.84 4.84 0 00-4.845 4.834 4.84 4.84 0 004.845 4.833zM12.819 9.722a4.84 4.84 0 004.844-4.833A4.84 4.84 0 0012.82.055a4.84 4.84 0 00-4.845 4.834 4.84 4.84 0 004.845 4.833z"
      ></path>
    </StyledLogo>
  )
}

const StyledLogo = styled.svg`
  margin: 20px;
  cursor: pointer;
  ${media.lessThan('md')`
  display: none;
  `}
`

export default Icon

export const percent = (array = [], where, what) =>
  Math.floor(
    (array?.map((i) => i[where])?.filter((i) => i?.includes(what))?.length /
      array?.length) *
      100
  )

export const expectactionCandidatesGeneral = (candidates) => [
  {
    expectation: '100% remote work',
    percent: percent(candidates, 'changeIf', '100% remote work'),
  },
  {
    expectation: 'Work in an experienced team',
    percent: percent(candidates, 'changeIf', 'Work in an experienced team'),
  },
  {
    expectation: 'Newest technologies',
    percent: percent(candidates, 'changeIf', 'Newest technologies'),
  },
  {
    expectation: 'Partially remote work',
    percent: percent(candidates, 'changeIf', 'Partially remote work'),
  },
  {
    expectation: 'Writing new functionalities',
    percent: percent(candidates, 'changeIf', 'Writing new functionalities'),
  },
  {
    expectation: 'An interesting project',
    percent: percent(candidates, 'changeIf', 'An interesting project'),
  },
  {
    expectation: 'Team focused on quality',
    percent: percent(candidates, 'changeIf', 'focused on quality'),
  },
  {
    expectation: 'Training budget',
    percent: percent(candidates, 'changeIf', 'Training budget'),
  },
  {
    expectation: 'Medical insurance',
    percent: percent(candidates, 'changeIf', 'Medical insurance'),
  },
  {
    expectation: 'Clouds solutions in the project',
    percent: percent(candidates, 'changeIf', 'Clouds solutions in the project'),
  },
  {
    expectation: 'Sports card',
    percent: percent(candidates, 'changeIf', 'Sports card'),
  },
]
export const expectactionCandidatesEnviroment = (candidates) => [
  {
    expectation: 'Larger company 50+',
    percent: percent(candidates, 'workEnviroment', 'Larger company 50+'),
  },
  {
    expectation: 'Small company up to 50 people',
    percent: percent(
      candidates,
      'workEnviroment',
      'Small company up to 50 people'
    ),
  },
  {
    expectation: 'International team',
    percent: percent(candidates, 'workEnviroment', 'international team'),
  },
  {
    expectation: 'Corporation',
    percent: percent(candidates, 'workEnviroment', 'Corporation'),
  },
  {
    expectation: 'IT product company',
    percent: percent(candidates, 'workEnviroment', 'IT product company'),
  },
  {
    expectation: 'Software House',
    percent: percent(candidates, 'workEnviroment', 'Software House'),
  },
  {
    expectation: 'Startup',
    percent: percent(candidates, 'workEnviroment', 'Startup'),
  },
  {
    expectation: 'Work in Polish',
    percent: percent(candidates, 'workEnviroment', 'Work in Polish'),
  },
  {
    expectation: 'Internal IT department',
    percent: percent(candidates, 'workEnviroment', 'Internal IT department'),
  },
]

import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useAuth } from '../../../contexts/AuthContext.js'
import Dropdown from '../../Dropdown/Dropdown.jsx'
import DropdownItem from '../../Dropdown/DropdownItem.jsx'
import ChangePasswordIcon from '../../Icons/LockDropdown'
import AccountInfoIcon from '../../Icons/AccountInfoDropdown'
import LogoutIcon from '../../Icons/Logout'
import AccountInfoArrow from '../../Icons/DownTick'
import InformationIcon from './InformationIcon.jsx'
import Tooltip from './Tooltip.jsx'

const PageTitle = ({ title, information, TooltipIcon, tooltipHeader }) => {
  const { currentUser, logout, account } = useAuth()
  const handleAccInfoClick = () => {
    if (account.company) {
      history.push('/account/preview-company')
    } else {
      history.push('/account/company')
    }
  }
  const handleChangePasswordClick = () => {
    history.push('/change-password')
  }

  const history = useHistory()
  const user = currentUser?.email

  return (
    <TopContainer>
      <Flex>
        <Title>{title}</Title>
        <InformationIcon data-tip data-for="information" />
        <Tooltip
          header={tooltipHeader}
          information={information}
          TooltipIcon={TooltipIcon}
        />
      </Flex>
      <Dropdown menu text={user} Arrow={AccountInfoArrow}>
        <DropdownItem
          Icon={AccountInfoIcon}
          click={handleAccInfoClick}
          text="Account information"
        />
        <DropdownItem
          Icon={ChangePasswordIcon}
          click={handleChangePasswordClick}
          text="Change password"
        />
        <DropdownItem Icon={LogoutIcon} click={logout} text="Log out" />
      </Dropdown>
    </TopContainer>
  )
}

const TopContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 20px 0;
  justify-content: space-between;
`

const Title = styled.span`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.4px;
  color: #3d3c3c;
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`

export default PageTitle

import React from 'react'
import { AuthProvider } from './contexts/AuthContext'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import AppRouting from './AppRouting'
import './styles/main.scss'
import { FilterProvider } from './contexts/FilterContext'
import { SWRConfig } from 'swr'
import axios from 'axios'
const App = () => {
  return (
    <Router>
      <SWRConfig
        value={{
          fetcher: (url, token, params) =>
            axios(url, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params,
            }).then((r) => r.data),
        }}
      >
        <AuthProvider>
          <FilterProvider>
            <Switch>
              <Route path="/" component={AppRouting} />
            </Switch>
          </FilterProvider>
        </AuthProvider>
      </SWRConfig>
    </Router>
  )
}

export default App

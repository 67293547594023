import React, { useState } from 'react';
import LoginLock from '../Icons/Locked'
import {useAuth} from '../../contexts/AuthContext'


const Payment = () => {
    const { subscribe } = useAuth()
    const [loading, setLoading] = useState(false)
    
    let handleClick = (price,plan) =>{
        setLoading(true)
        subscribe(price, plan)
    }
    return ( 
        <section className="login-form-bg payment-section">

        <div className="login-form-box payment">
            <div className="login-form-box-line"/>
            <div className="login-form-box-shape">
                <LoginLock/>
            </div>
            <div className="login-form plan">
            <span className="nameplan">Agency</span>
            <ul className="plan-benefits">
                <li>
                <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.706787" y="0.342728" width="25.5233" height="25.4809" rx="9" fill="#FFCB4A"/>
<path d="M11.4 14.9749L9.56777 13.1457L8.5603 14.1515L10.9782 16.5654L10.981 16.5626L11.4224 17.0033L18.2487 10.1884L17.2217 9.16302L11.4 14.9749Z" fill="white"/>
</svg>
                Aktualne dane o wynagrodzeniach
                </li>
                <li>
                <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.706787" y="0.342728" width="25.5233" height="25.4809" rx="9" fill="#FFCB4A"/>
<path d="M11.4 14.9749L9.56777 13.1457L8.5603 14.1515L10.9782 16.5654L10.981 16.5626L11.4224 17.0033L18.2487 10.1884L17.2217 9.16302L11.4 14.9749Z" fill="white"/>
</svg>
                    Nielimitowana liczba wyszukań
                </li>
                <li>
                <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.706787" y="0.342728" width="25.5233" height="25.4809" rx="9" fill="#FFCB4A"/>
<path d="M11.4 14.9749L9.56777 13.1457L8.5603 14.1515L10.9782 16.5654L10.981 16.5626L11.4224 17.0033L18.2487 10.1884L17.2217 9.16302L11.4 14.9749Z" fill="white"/>
</svg>
                    Dane historyczne
                </li>
                <li>
                <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.706787" y="0.342728" width="25.5233" height="25.4809" rx="9" fill="#FFCB4A"/>
<path d="M11.4 14.9749L9.56777 13.1457L8.5603 14.1515L10.9782 16.5654L10.981 16.5626L11.4224 17.0033L18.2487 10.1884L17.2217 9.16302L11.4 14.9749Z" fill="white"/>
</svg>
                    Trendy wynagrodzeń
                </li>
                <li>
                <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.706787" y="0.342728" width="25.5233" height="25.4809" rx="9" fill="#FFCB4A"/>
<path d="M11.4 14.9749L9.56777 13.1457L8.5603 14.1515L10.9782 16.5654L10.981 16.5626L11.4224 17.0033L18.2487 10.1884L17.2217 9.16302L11.4 14.9749Z" fill="white"/>
</svg>
                    Analiza benefitów
                </li>
                <li>
                <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.706787" y="0.342728" width="25.5233" height="25.4809" rx="9" fill="#FFCB4A"/>
<path d="M11.4 14.9749L9.56777 13.1457L8.5603 14.1515L10.9782 16.5654L10.981 16.5626L11.4224 17.0033L18.2487 10.1884L17.2217 9.16302L11.4 14.9749Z" fill="white"/>
</svg>
                    Generowanie raportów PDF
                </li>
                <li>
                <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.706787" y="0.342728" width="25.5233" height="25.4809" rx="9" fill="#FFCB4A"/>
<path d="M11.4 14.9749L9.56777 13.1457L8.5603 14.1515L10.9782 16.5654L10.981 16.5626L11.4224 17.0033L18.2487 10.1884L17.2217 9.16302L11.4 14.9749Z" fill="white"/>
</svg>
                    Generowanie raportów z Twoim logo
                </li>
            </ul>
                <span className="pay">Subscription price</span>
                <span className="price">€59.99</span>
                <button className="button-plan" disabled={loading} /*onClick={() => handleClick('price_1I3IaaJZUN3xCpysUVOm8bFR', 'Classic')}*/>Choose Plan</button>
            </div>
        </div>

        <div className="login-form-box payment">
            <div className="login-form-box-line"/>
            <div className="login-form-box-shape">
                <LoginLock/>
            </div>
            <div className="login-form plan">
            <span className="nameplan">Premium</span>
        
            <ul className="plan-benefits">
                <li>
                <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.706787" y="0.342728" width="25.5233" height="25.4809" rx="9" fill="#FFCB4A"/>
<path d="M11.4 14.9749L9.56777 13.1457L8.5603 14.1515L10.9782 16.5654L10.981 16.5626L11.4224 17.0033L18.2487 10.1884L17.2217 9.16302L11.4 14.9749Z" fill="white"/>
</svg>
                Aktualne dane o wynagrodzeniach
                </li>
                <li>
                <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.706787" y="0.342728" width="25.5233" height="25.4809" rx="9" fill="#FFCB4A"/>
<path d="M11.4 14.9749L9.56777 13.1457L8.5603 14.1515L10.9782 16.5654L10.981 16.5626L11.4224 17.0033L18.2487 10.1884L17.2217 9.16302L11.4 14.9749Z" fill="white"/>
</svg>
                    Nielimitowana liczba wyszukań
                </li>
                <li>
                <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.706787" y="0.342728" width="25.5233" height="25.4809" rx="9" fill="#FFCB4A"/>
<path d="M11.4 14.9749L9.56777 13.1457L8.5603 14.1515L10.9782 16.5654L10.981 16.5626L11.4224 17.0033L18.2487 10.1884L17.2217 9.16302L11.4 14.9749Z" fill="white"/>
</svg>
                    Dane historyczne
                </li>
                <li>
                <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.706787" y="0.342728" width="25.5233" height="25.4809" rx="9" fill="#FFCB4A"/>
<path d="M11.4 14.9749L9.56777 13.1457L8.5603 14.1515L10.9782 16.5654L10.981 16.5626L11.4224 17.0033L18.2487 10.1884L17.2217 9.16302L11.4 14.9749Z" fill="white"/>
</svg>
                    Trendy wynagrodzeń
                </li>
                <li>
                <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.706787" y="0.342728" width="25.5233" height="25.4809" rx="9" fill="#FFCB4A"/>
<path d="M11.4 14.9749L9.56777 13.1457L8.5603 14.1515L10.9782 16.5654L10.981 16.5626L11.4224 17.0033L18.2487 10.1884L17.2217 9.16302L11.4 14.9749Z" fill="white"/>
</svg>
                    Analiza benefitów
                </li>
                <li>
                <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.706787" y="0.342728" width="25.5233" height="25.4809" rx="9" fill="#FFCB4A"/>
<path d="M11.4 14.9749L9.56777 13.1457L8.5603 14.1515L10.9782 16.5654L10.981 16.5626L11.4224 17.0033L18.2487 10.1884L17.2217 9.16302L11.4 14.9749Z" fill="white"/>
</svg>
                    Generowanie raportów PDF
                </li>
                <li className="gray">
                <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="2.2251" y="1.8527" width="22.5233" height="22.4809" rx="7.5" fill="#F3DDDB" stroke="#F3DDDB" stroke-width="3"/>
<path d="M14.3546 13.0932L18.2149 9.23921C18.4548 8.99997 18.4548 8.61179 18.2149 8.37255C17.9751 8.1331 17.5867 8.1331 17.3468 8.37255L13.4866 12.2266L9.62607 8.37255C9.38624 8.1331 8.99784 8.1331 8.758 8.37255C8.51817 8.61179 8.51817 8.99997 8.758 9.23921L12.6185 13.0932L8.758 16.9473C8.51817 17.1865 8.51817 17.5747 8.758 17.8139C8.87792 17.9334 9.03508 17.9933 9.19204 17.9933C9.34899 17.9933 9.50616 17.9334 9.62607 17.8137L13.4866 13.9597L17.3468 17.8137C17.4668 17.9334 17.6239 17.9933 17.7809 17.9933C17.9378 17.9933 18.095 17.9334 18.2149 17.8137C18.4548 17.5745 18.4548 17.1863 18.2149 16.947L14.3546 13.0932Z" fill="#F34938"/>
</svg>

                    Generowanie raportów z Twoim logo
                </li>
            </ul>
            <span className="pay">Subscription price</span>
            <span className="price">€39.99</span>
                <button className="button-plan" disabled={loading} onClick={() => handleClick('price_1I3IZaJZUN3xCpysSvIrKiYr','Premium')}>Choose Plan</button>
            </div>
        </div>

        <div className="login-form-box payment">
            <div className="login-form-box-line"/>
            <div className="login-form-box-shape">
                <LoginLock/>
            </div>
            <div className="login-form plan">
            <span className="nameplan">Starter</span>

            <ul className="plan-benefits">
                <li>
                <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.706787" y="0.342728" width="25.5233" height="25.4809" rx="9" fill="#FFCB4A"/>
<path d="M11.4 14.9749L9.56777 13.1457L8.5603 14.1515L10.9782 16.5654L10.981 16.5626L11.4224 17.0033L18.2487 10.1884L17.2217 9.16302L11.4 14.9749Z" fill="white"/>
</svg>
 Aktualne dane o wynagrodzeniach
                </li>
                <li>
                <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.706787" y="0.342728" width="25.5233" height="25.4809" rx="9" fill="#FFCB4A"/>
<path d="M11.4 14.9749L9.56777 13.1457L8.5603 14.1515L10.9782 16.5654L10.981 16.5626L11.4224 17.0033L18.2487 10.1884L17.2217 9.16302L11.4 14.9749Z" fill="white"/>
</svg>
                    Nielimitowana liczba wyszukań
                </li>
                <li className="gray">
                <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="2.2251" y="1.8527" width="22.5233" height="22.4809" rx="7.5" fill="#F3DDDB" stroke="#F3DDDB" stroke-width="3"/>
<path d="M14.3546 13.0932L18.2149 9.23921C18.4548 8.99997 18.4548 8.61179 18.2149 8.37255C17.9751 8.1331 17.5867 8.1331 17.3468 8.37255L13.4866 12.2266L9.62607 8.37255C9.38624 8.1331 8.99784 8.1331 8.758 8.37255C8.51817 8.61179 8.51817 8.99997 8.758 9.23921L12.6185 13.0932L8.758 16.9473C8.51817 17.1865 8.51817 17.5747 8.758 17.8139C8.87792 17.9334 9.03508 17.9933 9.19204 17.9933C9.34899 17.9933 9.50616 17.9334 9.62607 17.8137L13.4866 13.9597L17.3468 17.8137C17.4668 17.9334 17.6239 17.9933 17.7809 17.9933C17.9378 17.9933 18.095 17.9334 18.2149 17.8137C18.4548 17.5745 18.4548 17.1863 18.2149 16.947L14.3546 13.0932Z" fill="#F34938"/>
</svg>

                    Dane historyczne
                </li>
                <li className="gray">
                <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="2.2251" y="1.8527" width="22.5233" height="22.4809" rx="7.5" fill="#F3DDDB" stroke="#F3DDDB" stroke-width="3"/>
<path d="M14.3546 13.0932L18.2149 9.23921C18.4548 8.99997 18.4548 8.61179 18.2149 8.37255C17.9751 8.1331 17.5867 8.1331 17.3468 8.37255L13.4866 12.2266L9.62607 8.37255C9.38624 8.1331 8.99784 8.1331 8.758 8.37255C8.51817 8.61179 8.51817 8.99997 8.758 9.23921L12.6185 13.0932L8.758 16.9473C8.51817 17.1865 8.51817 17.5747 8.758 17.8139C8.87792 17.9334 9.03508 17.9933 9.19204 17.9933C9.34899 17.9933 9.50616 17.9334 9.62607 17.8137L13.4866 13.9597L17.3468 17.8137C17.4668 17.9334 17.6239 17.9933 17.7809 17.9933C17.9378 17.9933 18.095 17.9334 18.2149 17.8137C18.4548 17.5745 18.4548 17.1863 18.2149 16.947L14.3546 13.0932Z" fill="#F34938"/>
</svg>

                    Trendy wynagrodzeń
                </li>
                <li className="gray">
                <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="2.2251" y="1.8527" width="22.5233" height="22.4809" rx="7.5" fill="#F3DDDB" stroke="#F3DDDB" stroke-width="3"/>
<path d="M14.3546 13.0932L18.2149 9.23921C18.4548 8.99997 18.4548 8.61179 18.2149 8.37255C17.9751 8.1331 17.5867 8.1331 17.3468 8.37255L13.4866 12.2266L9.62607 8.37255C9.38624 8.1331 8.99784 8.1331 8.758 8.37255C8.51817 8.61179 8.51817 8.99997 8.758 9.23921L12.6185 13.0932L8.758 16.9473C8.51817 17.1865 8.51817 17.5747 8.758 17.8139C8.87792 17.9334 9.03508 17.9933 9.19204 17.9933C9.34899 17.9933 9.50616 17.9334 9.62607 17.8137L13.4866 13.9597L17.3468 17.8137C17.4668 17.9334 17.6239 17.9933 17.7809 17.9933C17.9378 17.9933 18.095 17.9334 18.2149 17.8137C18.4548 17.5745 18.4548 17.1863 18.2149 16.947L14.3546 13.0932Z" fill="#F34938"/>
</svg>

                    Analiza benefitów
                </li>
                <li className="gray">
                <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="2.2251" y="1.8527" width="22.5233" height="22.4809" rx="7.5" fill="#F3DDDB" stroke="#F3DDDB" stroke-width="3"/>
<path d="M14.3546 13.0932L18.2149 9.23921C18.4548 8.99997 18.4548 8.61179 18.2149 8.37255C17.9751 8.1331 17.5867 8.1331 17.3468 8.37255L13.4866 12.2266L9.62607 8.37255C9.38624 8.1331 8.99784 8.1331 8.758 8.37255C8.51817 8.61179 8.51817 8.99997 8.758 9.23921L12.6185 13.0932L8.758 16.9473C8.51817 17.1865 8.51817 17.5747 8.758 17.8139C8.87792 17.9334 9.03508 17.9933 9.19204 17.9933C9.34899 17.9933 9.50616 17.9334 9.62607 17.8137L13.4866 13.9597L17.3468 17.8137C17.4668 17.9334 17.6239 17.9933 17.7809 17.9933C17.9378 17.9933 18.095 17.9334 18.2149 17.8137C18.4548 17.5745 18.4548 17.1863 18.2149 16.947L14.3546 13.0932Z" fill="#F34938"/>
</svg>

                    Generowanie raportów PDF
                </li>
                <li className="gray">
                <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="2.2251" y="1.8527" width="22.5233" height="22.4809" rx="7.5" fill="#F3DDDB" stroke="#F3DDDB" stroke-width="3"/>
<path d="M14.3546 13.0932L18.2149 9.23921C18.4548 8.99997 18.4548 8.61179 18.2149 8.37255C17.9751 8.1331 17.5867 8.1331 17.3468 8.37255L13.4866 12.2266L9.62607 8.37255C9.38624 8.1331 8.99784 8.1331 8.758 8.37255C8.51817 8.61179 8.51817 8.99997 8.758 9.23921L12.6185 13.0932L8.758 16.9473C8.51817 17.1865 8.51817 17.5747 8.758 17.8139C8.87792 17.9334 9.03508 17.9933 9.19204 17.9933C9.34899 17.9933 9.50616 17.9334 9.62607 17.8137L13.4866 13.9597L17.3468 17.8137C17.4668 17.9334 17.6239 17.9933 17.7809 17.9933C17.9378 17.9933 18.095 17.9334 18.2149 17.8137C18.4548 17.5745 18.4548 17.1863 18.2149 16.947L14.3546 13.0932Z" fill="#F34938"/>
</svg>
                    Generowanie raportów z Twoim logo
                </li>
            </ul>
            <span className="pay">Subscription price</span>
            <span className="price">€19.99</span>
                <button className="button-plan" disabled={loading} onClick={() => handleClick('price_1I3IaaJZUN3xCpysUVOm8bFR', 'Classic')}>Choose Plan</button>
            </div>
        </div>
        

        
        </section>
     );
}
 
export default Payment;
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useReducer } from 'react'
import { Switch, useLocation, useHistory } from 'react-router'
import { useAuth } from '../../../contexts/AuthContext.js'
import PrivateRoute from '../../../PrivateRoute'
import Loading from '../../sharedComponents/loading/Loading.jsx'
import NewJobOfferStep1 from './steps/NewJobOfferStep1'
import NewJobOfferStep2 from './steps/NewJobOfferStep2'
import NewJobOfferStep3 from './steps/NewJobOfferStep3'
import NewJobOfferStep4 from './steps/NewJobOfferStep4'
import ProgressBar from './ProgressBar'
import reducer, { initialValue, ACTIONS } from './reducer.js'

const NewJobOffer = () => {
  const [state, dispatch] = useReducer(reducer, initialValue)
  const [loading, setLoading] = useState(false)
  const { state: locationState } = useLocation()
  const { account } = useAuth()
  const history = useHistory()

  const send = () => {
    if (!state.positionName || !state.city) {
      history.push('/create-job-offer', {
        errors: { positionName: !state.positionName, city: !state.city },
      })
      return
    }

    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/recruitments`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...state,
        companyId: account?.company?.id,
        minSalaryB2B: state.minSalaryB2B || null,
        maxSalaryB2B: state.maxSalaryB2B || null,
        minSalaryUoP: state.minSalaryUoP || null,
        maxSalaryUoP: state.maxSalaryUoP || null,
        remotePossibility: state.remotePossibility || null,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'ok') {
          history.push('/success-recruitment', {
            slug: res.slug,
            edited: res.msg === 'updated',
            active: res.active,
          })
        } else {
          //TODO error info
          return
        }
      })
  }

  useEffect(() => {
    const token = localStorage.getItem('wrk_token')
    const getOfferToEdit = async (id, token, mode) => {
      const offer = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/recruitments/${id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((res) => res.json())
      dispatch({
        type: mode === 'edit' ? ACTIONS.GET : ACTIONS.COPY,
        offer,
      })
    }
    if (locationState?.id) {
      setLoading(true)
      // locationState.mode = 'edit' | 'clone'
      getOfferToEdit(locationState.id, token, locationState?.mode).then(() =>
        setLoading(false)
      )
    }
  }, [locationState])
  return (
    <>
      {loading && <Loading />}
      <div className="new-job-offer-container">
        <div className="new-job-offer-page">
          <Switch>
            <PrivateRoute exact path="/create-job-offer">
              <NewJobOfferStep1
                positionName={state.positionName}
                reqExpLevel={state.reqExpLevel}
                minSalaryUoP={state.minSalaryUoP}
                maxSalaryUoP={state.maxSalaryUoP}
                minSalaryB2B={state.minSalaryB2B}
                maxSalaryB2B={state.maxSalaryB2B}
                remotePossibility={state.remotePossibility}
                teamSize={state.teamSize}
                city={state.city}
                currency={state.currency}
                dispatch={dispatch}
              />
            </PrivateRoute>
            <PrivateRoute path="/create-job-offer/2">
              <NewJobOfferStep2
                mustHaves={state.mustHaves}
                niceToHaves={state.niceToHaves}
                internationalTeam={state.internationalTeam}
                changeProject={state.changeProject}
                chooseTools={state.chooseTools}
                paidHolidays={state.paidHolidays}
                recruitmentProcessSteps={state.recruitmentProcessSteps}
                aboutOffer={state.aboutOffer}
                dispatch={dispatch}
              />
            </PrivateRoute>
            <PrivateRoute path="/create-job-offer/3">
              <NewJobOfferStep3
                keyAdvantages={state.keyAdvantages}
                enviroment={state.enviroment}
                dispatch={dispatch}
              />
            </PrivateRoute>
            <PrivateRoute path="/create-job-offer/4">
              <NewJobOfferStep4
                projectDescription={state.projectDescription}
                methodologies={state.methodologies}
                projectVideo={state.projectVideo}
                projectStack={state.projectStack}
                send={send}
                dispatch={dispatch}
              />
            </PrivateRoute>
          </Switch>
          <ProgressBar />
        </div>
      </div>
    </>
  )
}

export default NewJobOffer

import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="11"
      fill="none"
      viewBox="0 0 11 11"
    >
      <path
        fill="#6B6B6B"
        fillRule="evenodd"
        d="M3.056 3.667a2.444 2.444 0 114.888 0 2.444 2.444 0 01-4.888 0zm4.78 2.825a3.667 3.667 0 10-4.674 0C1.215 7.178 0 8.707 0 10.389a.611.611 0 101.222 0c0-1.387 1.44-3.056 4.278-3.056 2.839 0 4.278 1.669 4.278 3.056a.611.611 0 101.222 0c0-1.682-1.212-3.21-3.163-3.897z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default Icon

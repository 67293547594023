import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="13"
      fill="none"
      viewBox="0 0 14 13"
    >
      <path
        fill="#FFCB4A"
        d="M2.874 4.072c-.61 0-1.108-.494-1.108-1.099 0-.604.499-1.1 1.108-1.1h8.86v1.1h1.107V.775H2.874C1.655.775.659 1.764.659 2.973v7.145c0 1.54 1.218 2.749 2.768 2.749H13.95V4.072H2.874zm9.967 7.695H3.427c-.94 0-1.66-.714-1.66-1.649V4.842c.331.22.72.33 1.107.33h9.967v6.595z"
      ></path>
    </svg>
  );
}

export default Icon;

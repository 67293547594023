import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="8"
      fill="none"
      viewBox="0 0 13 8"
    >
      <path
        fill="#FFCB4A"
        stroke="#FFCB4A"
        d="M1 1.653a.652.652 0 011.114-.462l4.52 4.52 4.519-4.52a.652.652 0 11.922.923l-4.98 4.98a.652.652 0 01-.923 0l-4.98-4.98A.65.65 0 011 1.653z"
      ></path>
    </svg>
  );
}

export default Icon;

import React, { useRef, useState } from 'react'
import Avatar from '../Icons/LittleAvatar'
import styled from 'styled-components'
import useOutsideClick from '../../hooks/useClickOutside'

const Dropdown = ({ children, text, Arrow }) => {
  const [dropdownActive, setDropDownActive] = useState(false)
  const dropdownRef = useRef()
  useOutsideClick(dropdownRef, () => {
    if (dropdownActive) {
      setDropDownActive(false)
    }
  })
  return (
    <DropdownStyled
      active={dropdownActive}
      ref={dropdownRef}
      onClick={() => setDropDownActive((prev) => !prev)}
    >
      <StyledButtonText>
        <Avatar /> {text} <Arrow />
      </StyledButtonText>
      {dropdownActive && <DropdownMenu>{children}</DropdownMenu>}
    </DropdownStyled>
  )
}

const DropdownStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${({ active }) => (active ? '#f6f6f9' : 'transparent')};
  border-radius: 10px;
  border: none;
  color: #ababab;
  font-weight: 500;
  padding: 8px;
  height: 35px;
  z-index: 5;
  cursor: pointer;
  &:hover {
    background-color: #f6f6f9;
  }
`
const StyledButtonText = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #252f40;
`
const DropdownMenu = styled.ul`
  position: absolute;
  top: 41px;
  right: 0;
  background: #ffffff;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 230px;
  overflow: hidden;
`

export default Dropdown

import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <rect width="18" height="18" fill="#FFCB4A" rx="9"></rect>
    </svg>
  );
}

export default Icon;

import React from "react";
import styled from "styled-components";

function Icon() {
  return (
    <FixedClick
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
    >
      <rect width="26" height="26" y="0" fill="#FFCB4A" rx="9"></rect>
      <path
        fill="#fff"
        d="M10.893 14.93l-1.867-1.866L8 14.09l2.463 2.463.003-.002.45.45 6.953-6.955L16.823 9l-5.93 5.93z"
      ></path>
    </FixedClick>
  );
}
const FixedClick = styled.svg`
pointer-events: none;
` 
export default Icon;

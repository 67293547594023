const reactSelectStyles = (height = 50) => ({
  option: (provided, state) => ({
    ...provided,
    color: '#ABABAB',
    fontSize: '14px',
    backgroundColor: state.isSelected ? '#F5F5F5' : '#FFFFFF',
    '&:hover': {
      backgroundColor: '#F6F6F9',
    },
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? '0 0 0 0.1px #FFCB4A' : 'none',
    border: state.isFocused ? '2px solid #FFCB4A' : '2px solid #ededed',
    '&:hover': {
      border: state.isFocused ? '2px solid #FFCB4A' : '2px solid #ededed',
    },
    height,
    borderRadius: '10px',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontWeight: '500',
    color: '#6b6b6b',
    fontSize: '14px',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
})

export default reactSelectStyles

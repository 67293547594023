import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      fill="none"
      viewBox="0 0 70 70"
    >
      <rect width="70" height="70" fill="#FFCB4A" rx="35"></rect>
      <path
        fill="#66511E"
        fillRule="evenodd"
        d="M27.857 23h13.714v9.143H27.857V23zm2.286 6.857h9.143v-4.571h-9.143v4.571zm-4.572 6.857h8V39h2.286v-2.286h8V39h2.286v-4.571H23.286V39h2.285v-2.286zm16 3.429V47h6.858v-6.857H41.57zm4.572 4.571h-2.286V42.43h2.286v2.285zm-8 2.286h-6.857v-6.857h6.857V47zm-2.286-4.571h-2.286v2.285h2.286V42.43zM21 46.999h6.857v-6.856H21V47zm2.286-4.57h2.285v2.285h-2.285V42.43z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;

import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import Step from '../../Icons/StepCircle'

const ProgressBar = () => {
  const history = useHistory()
  const pbRef = useRef(null)
  const stepRef = useRef(null)
  const step2Ref = useRef(null)
  const step3Ref = useRef(null)
  const step4Ref = useRef(null)
  const [prg, setPrg] = useState(0)
  useEffect(() => {
    if (history.location.pathname === '/create-job-offer/2') {
      stepRef.current.classList.remove('act')
      step2Ref.current.classList.add('act')
      step3Ref.current.classList.remove('act')
      step4Ref.current.classList.remove('act')
      setPrg(50)
    } else if (history.location.pathname === '/create-job-offer/3') {
      stepRef.current.classList.remove('act')
      step2Ref.current.classList.remove('act')
      step3Ref.current.classList.add('act')
      step4Ref.current.classList.remove('act')

      setPrg(75)
    } else if (history.location.pathname === '/create-job-offer/4') {
      stepRef.current.classList.remove('act')
      step2Ref.current.classList.remove('act')
      step3Ref.current.classList.remove('act')
      step4Ref.current.classList.add('act')

      setPrg(100)
    } else {
      stepRef.current.classList.add('act')
      step2Ref.current.classList.remove('act')
      step3Ref.current.classList.remove('act')
      step4Ref.current.classList.remove('act')

      setPrg(25)
    }
    pbRef.current.style.width = `${prg}%`
    pbRef.current.dataset.before = `${prg}`
  }, [history.location, prg])

  return (
    <div className="pb-container">
      <div className="pb-white">
        <p>Completed information</p>
        <div className="progress-bar-container">
          <div className="progress-bar">
            <div ref={pbRef} className="pb-fill" data-before="33" />
          </div>
          <ul className="pb-numbers">
            <li>0%</li>
            <li>{prg !== 100 ? '100%' : null}</li>
          </ul>
        </div>
      </div>
      <div className="pb-transparent">
        <ul>
          <li ref={stepRef} onClick={() => history.push('/create-job-offer/')}>
            <Step />
            Main information
          </li>
          <li
            ref={step2Ref}
            onClick={() => history.push('/create-job-offer/2')}
          >
            <Step />
            Candidate's skills
          </li>
          <li
            ref={step3Ref}
            onClick={() => history.push('/create-job-offer/3')}
          >
            <Step />
            Advantages and work environment
          </li>
          <li
            ref={step4Ref}
            onClick={() => history.push('/create-job-offer/4')}
          >
            <Step />
            Project information
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ProgressBar

import React, { useContext, useEffect, useState } from 'react'
import { auth, db } from '../firebase'
import firebase from 'firebase/compat/app'
import 'firebase/functions'
import { loadStripe } from '@stripe/stripe-js'
const promise = loadStripe(
  'pk_test_51HydJ3JZUN3xCpysZYzcyOXCrg5ifl4MBx7B1LYHwmTmu7cNArEh4rSDRVL1GMSpdf51pVLrmwQk7vlNbkTNyUEY00nzFSI7nl'
)
const taxRates = ['txr_1I1BeZJZUN3xCpysW6jUlUyR']
const AuthContext = React.createContext()
let googleprovider = new firebase.auth.GoogleAuthProvider()
let fbprovider = new firebase.auth.FacebookAuthProvider()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)
  const [paid, setPaid] = useState(false)
  const [stripeRole, setStripeRole] = useState('Free')
  const [account, setAccount] = useState()

  async function subscribe(price, plan) {
    const docRef = await db
      .collection('customers')
      .doc(currentUser.uid)
      .collection('checkout_sessions')
      .add({
        price,
        allow_promotion_codes: false,
        tax_rates: taxRates,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
        metadata: {
          tax_rate: '10% sales tax exclusive',
          stripeRole: plan,
        },
      })
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot(async (snap) => {
      const { error, sessionId } = snap.data()
      if (error) {
        // Show an error to your customer and then inspect your function logs.
        alert(`An error occured: ${error.message}`)
      }
      if (sessionId) {
        // We have a session, let's redirect to Checkout
        // Init Stripe
        const stripe = await promise
        await stripe.redirectToCheckout({ sessionId })
      }
    })
  }

  async function createBillingPortal() {
    const functionRef = firebase
      .app()
      .functions('europe-west1')
      .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink')
    const { data } = await functionRef({ returnUrl: window.location.origin })
    window.location.assign(data.url)
  }
  function fbAuth() {
    return auth.signInWithPopup(fbprovider)
  }
  function googleAuth() {
    return auth.signInWithPopup(googleprovider)
  }
  async function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password)
  }
  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password)
  }
  function logout() {
    return auth.signOut()
  }
  function changePassword(password) {
    return currentUser.updatePassword(password)
  }
  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }
  function checkPayment(user) {
    if (user) {
      db.collection('customers')
        .doc(user.uid)
        .collection('subscriptions')
        .where('status', 'in', ['trialing', 'active'])
        .onSnapshot(async (snapshot) => {
          if (snapshot.empty) {
            setPaid(false)
          } else setPaid(true)
        })
    } else {
      setPaid(false)
    }
  }

  async function setCustomClaimRole(user) {
    if (user) {
      await db
        .collection('customers')
        .doc(user.uid)
        .collection('subscriptions')
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            let role = doc.data().metadata.stripeRole
            setStripeRole(role)
          })
        })
    } else {
      setStripeRole('Free')
    }
  }
  async function setAccountInfo(user) {
    if (user) {
      const token = await user.getIdToken()
      const myHeaders = new Headers()
      myHeaders.append('Authorization', `Bearer ${token}`)

      await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/account`, {
        headers: myHeaders,
      })
        .then((res) => res?.json())
        .then((res) => setAccount(res))
    } else {
      setAccount(undefined)
    }
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setStripeRole('Free')
      setCurrentUser(user)
      checkPayment(user)
      setCustomClaimRole(user)
      setAccountInfo(user)
      setLoading(false)
    })
    return unsubscribe
  }, [])

  useEffect(() => {
    return auth.onIdTokenChanged((user) => {
      if (user) {
        user.getIdToken().then((idToken) => {
          if (idToken) {
            localStorage.setItem('wrk_token', idToken)
          }
        })
      } else {
        localStorage.removeItem('wrk_token')
      }
    })
  }, [])

  const value = {
    currentUser,
    googleAuth,
    fbAuth,
    signup,
    login,
    logout,
    resetPassword,
    paid,
    createBillingPortal,
    subscribe,
    stripeRole,
    account,
    setAccountInfo,
    setAccount,
    changePassword,
  }
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}

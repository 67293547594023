import React, { useRef, useState } from 'react';
import {useAuth} from '../../contexts/AuthContext'
import {Link} from 'react-router-dom'
import LoginLock from '../Icons/Locked'

const ForgotPassword = () => {
    const emailRef = useRef()
    const {resetPassword} = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState()

    const handleSubmit = async(e) =>{
        e.preventDefault()

        try{
            setMessage('')
            setError('')
            setLoading(true)
            await resetPassword(emailRef.current.value)
            setMessage('Check your inbox for further instructions')
        }catch{
            setError(`Failed account doesn't exist`)
        }
        setLoading(false)
    }
    return ( 
        <section className="login-form-bg">
            <span className="center-login">
        <div className="login-form-box">
            <div className="login-form-box-line"/>
            <div className="login-form-box-shape">
                <LoginLock/>
            </div>
            <div className="login-form">
            <h2>Reset Password</h2>
            {error && <span className="login-error">{error}</span>}
            {message && <span className="reset-password-message">{message}</span>}
            <form onSubmit={handleSubmit}>
                <label htmlFor="email">Email</label>
                <input id="email" ref={emailRef} type="email" required placeholder="example@mail.com"></input>
                <button disabled={loading} type="submit">Reset Password</button>
                </form>
            </div>
        </div>
    <div className="login-bottom-text">
        Need an accout? <Link to="/login">Sign Up</Link>
    </div>
</span>
</section>
     );
}
 
export default ForgotPassword;
import React, { useRef, useState } from 'react';
import {useAuth} from '../../contexts/AuthContext'
import LoginLock from '../Icons/Locked'

const ChangePassword = () => {
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const {changePassword} = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState()

    const handleSubmit = async(e) =>{
        e.preventDefault()
        if(passwordRef.current.value.length < 5){
            setError("Password must be 7 characters length")
            return
        }
        if(passwordRef.current.value === passwordConfirmRef.current.value){
            setLoading(true)
            await changePassword(passwordRef.current.value)
            setMessage("Password updated")
            setLoading(false)
        }else{
            setError("Passwords must match")
        }
        
    }
    return ( 
        <section className="login-form-bg">
            <span className="center-login">
        <div className="login-form-box">
            <div className="login-form-box-line"/>
            <div className="login-form-box-shape">
                <LoginLock/>
            </div>
            <div className="login-form">
            <h2>Change Password</h2>
            {error && <span className="login-error">{error}</span>}
            {message && <span className="reset-password-message">{message}</span>}
            <form onSubmit={handleSubmit}>
                <label htmlFor="password">Password</label>
                <input id="password" ref={passwordRef} type="password" placeholder="•••••••"/>
                <label htmlFor="password-confirm">Confirm Password</label>
                <input id="password-confirm" ref={passwordConfirmRef} type="password" placeholder="•••••••"/>
                <button disabled={loading} type="submit">Reset Password</button>
                </form>
            </div>
        </div>
</span>
</section>
)}
 
export default ChangePassword;
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import LeftBar from './components/LeftBar/LeftBar'

import Payment from './components/Login/Payment'

import Home from './components/MainPage/Home/Home'
import PrivateRoute from './PrivateRoute'
import CandidatesComponent from './components/MainPage/Candidates/CandidatesComponent'
import CandidatePage from './components/MainPage/Candidates/Candidate/CandidatePage'
import MarketData from './components/MainPage/marketData/MarketData'
import AccountInfo from './components/Login/AccountInfo/AccountInfo'
import MainLogin from './components/Login/MainLogin'
import ForgotPassword from './components/Login/ForgotPassword'
import ChangePassword from './components/Login/ChangePassword'
import NewJobOffer from './components/MainPage/NewJobOffer/NewJobOffer'
import RecruitmentComponent from './components/MainPage/Recruitments/RecruitmentsComponent'
import RecruitmentSuccesPage from './components/MainPage/Recruitments/RecruitmentSuccesPage'
import CandidatesInvited from './components/MainPage/Recruitments/CandidatesInvited'
import styled from 'styled-components'
import media from './lib/media.js'

const AppRouting = () => {
  return (
    <Main>
      <LeftBar />
      <Section>
        <Switch>
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute
            path="/candidates/page:page"
            component={CandidatesComponent}
          />
          <PrivateRoute path="/candidate/:id" component={CandidatePage} />
          <PrivateRoute path="/payment" component={Payment} />
          <PrivateRoute path="/salaries" component={MarketData} />
          <PrivateRoute path="/account" component={AccountInfo} />
          <PrivateRoute path="/change-password" component={ChangePassword} />
          <PrivateRoute
            path="/success-recruitment"
            component={RecruitmentSuccesPage}
          />
          <Route path="/login" component={MainLogin} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <PrivateRoute path="/recruitments" component={RecruitmentComponent} />
          <PrivateRoute path="/create-job-offer" component={NewJobOffer} />
          <PrivateRoute path="/recruitment/:id" component={CandidatesInvited} />
        </Switch>
      </Section>
    </Main>
  )
}

export default AppRouting

const Main = styled.main`
  background-color: #f5f5f5;
  display: flex;
  width: 100%;
  overflow-y: hidden;
  ${media.lessThan('lg')`
  flex-direction: column-reverse;
  `}
`
const Section = styled.section`
  background-color: #f5f5f5;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  ${media.lessThan('lg')`
  height: 90vh;
  `}
`

import React from 'react'
import { Bar, Line } from 'react-chartjs-2'
const ChartComponent = ({ type, offersData }) => {
  let data = {
    labels: [
      'Warsaw',
      'Gdansk',
      'Cracow',
      'Wroclaw',
      'Poznan',
      'Łódź',
      'Katowice',
      // 'Remote',
      // 'Szczecin',
      // 'Bydgoszcz',
      'Lublin',
      // 'Białystok',
    ],
    datasets: [
      {
        backgroundColor: type === 'line' ? '#ffcc4aac' : '#ffcc4a',
        pointRadius: 4,
        borderWidth: 0,
        hoverBorderWidth: 0,
        data: [
          offersData?.warsaw,
          offersData?.gdansk,
          offersData?.cracow,
          offersData?.wroclaw,
          offersData?.poznan,
          offersData?.lodz,
          offersData?.katowice,
          offersData?.lublin,
        ],
      },
    ],
  }

  let options =
    type === 'bar'
      ? {
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
          },
        }
      : {
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          legend: {
            display: false,
          },
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
          },
        }
  return (
    <div className="chart">
      <span className="chart-title">
        AVERAGE EARNINGS IN THE BIGGEST CITIES IN POLAND
      </span>
      <div className="line-container">
        {type === 'bar' ? (
          <Bar data={data} options={options} />
        ) : (
          <Line data={data} options={options} />
        )}
      </div>
    </div>
  )
}

export default ChartComponent
import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
    >
      <rect
        width="24"
        height="24"
        x="1"
        y="1"
        stroke="#FFCB4A"
        strokeWidth="2"
        rx="12"
      ></rect>
      <path
        fill="#FFCB4A"
        stroke="#FFCB4A"
        d="M7.043 10.943a.652.652 0 011.114-.462l4.52 4.52 4.519-4.52a.652.652 0 11.922.923l-4.98 4.981a.652.652 0 01-.923 0l-4.98-4.98a.65.65 0 01-.192-.462z"
      ></path>
    </svg>
  );
}

export default Icon;

import React from "react";
import styled from "styled-components";

function Icon({sm}) {
  return (
    <StyledSvg
    sm={sm}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 70 70"
    >
      <rect width="70" height="70" fill="#FFCB4A" rx="35"></rect>
      <path
        fill="#66511E"
        fillRule="evenodd"
        d="M40.403 36.712v2.744a9.334 9.334 0 016.288 2.633c.415.401.476 1.044.145 1.516-.323.46-.67.898-1.037 1.314a3.01 3.01 0 00-1.639-1.686l.105-.124a7.002 7.002 0 00-4.096-1.312h-.938a1.172 1.172 0 01-1.172-1.172V38.6a7.597 7.597 0 01-3.52.853 7.598 7.598 0 01-3.52-.853v2.025c0 .647-.525 1.172-1.172 1.172h-.938c-1.486 0-2.908.46-4.096 1.312a12.665 12.665 0 0015.214 3.295 3 3 0 001.205 2.02A15.009 15.009 0 0134.539 50c-4.888 0-9.485-2.39-12.297-6.395a1.172 1.172 0 01.145-1.516 9.334 9.334 0 016.288-2.633v-2.744a7.606 7.606 0 01-1.779-4.902v-4.167c0-4.214 3.429-7.643 7.643-7.643s7.643 3.429 7.643 7.643v4.167a7.606 7.606 0 01-1.78 4.902zm-5.864-14.368a5.305 5.305 0 00-5.3 5.3v4.166c0 1.367.52 2.664 1.461 3.654a5.254 5.254 0 003.839 1.645 5.253 5.253 0 003.839-1.646 5.275 5.275 0 001.46-3.653v-4.167c0-2.922-2.377-5.3-5.299-5.3z"
        clipRule="evenodd"
      ></path>
    </StyledSvg>
  );
}
const StyledSvg = styled.svg`
width: 70px;
height: 70px;
${({sm}) => sm && `
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -25px;
    height: 50px;
    width: 50px;
`}
`
export default Icon;

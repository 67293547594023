import React from 'react'
import { useFilter } from '../../../../contexts/FilterContext.js'
import SalariesChartComponent from './SalariesChartComponent.jsx'

const SalariesGrid = ({
  minSalaryOffers,
  maxSalaryOffers,
  minSalaryCandidates,
  maxSalaryCandidates,
  uopCandidatesPercent,
  b2bCandidatesPercent,
  b2bOffersPercent,
  uopOffersPercent,
}) => {
  const { valuePosition } = useFilter()
  return (
    <div className="salaries-charts-left">
      <SalariesChartComponent
        color1="orange"
        color2="green"
        number="1"
        text1="Salary"
        text2={`${minSalaryOffers ? minSalaryOffers.toLocaleString() : 0} - ${
          maxSalaryOffers ? maxSalaryOffers.toLocaleString() : 0
        } PLN`}
        text3={'Position'}
        text4={`${valuePosition} Developers`}
        title="SALARIES"
      />
      <SalariesChartComponent
        color1="orange"
        color2="green"
        number="2"
        text1="Salary"
        text2={`${
          minSalaryCandidates ? minSalaryCandidates.toLocaleString() : 0
        } - ${
          maxSalaryCandidates ? maxSalaryCandidates.toLocaleString() : 0
        } PLN`}
        text3={'Position'}
        text4={`${valuePosition} Developers`}
        title="EXPECTED SALARY OF CANDIDATES"
      />
      <SalariesChartComponent
        color1="dblue"
        color2="lblue"
        number="3"
        text1="Employment contract"
        text2={`${uopOffersPercent || 0}%`}
        text3="Form of cooperation B2B"
        text4={`${b2bOffersPercent || 0}%`}
        title="OFFERED FORMS OF CONTRACTS"
      />
      <SalariesChartComponent
        color1="dblue"
        color2="lblue"
        number="4"
        text1="Employment contract"
        text2={`${uopCandidatesPercent || 0}%`}
        text3="Form of cooperation B2B"
        text4={`${b2bCandidatesPercent || 0}%`}
        title="EXPECTED CONTRACT FORMS OF CANDIDATES"
      />
    </div>
  )
}

export default SalariesGrid

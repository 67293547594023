import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      fill="none"
      viewBox="0 0 70 70"
    >
      <rect width="70" height="70" fill="#FFCB4A" rx="35"></rect>
      <path
        fill="#66511E"
        fillRule="evenodd"
        d="M46.605 27.514a2.966 2.966 0 00-2.11-.874h-9.643a.79.79 0 01-.661-.348L32.663 24h-7.677A2.99 2.99 0 0022 26.986v16.028A2.99 2.99 0 0024.986 46h19.508a2.99 2.99 0 002.986-2.986V29.625c0-.797-.31-1.547-.875-2.111zm-22.614-.528c0-.549.446-.995.995-.995h6.611l.938 1.406c.057.085.118.167.183.245a2.769 2.769 0 00-.18.238l-.96 1.397H24.987c-.345 0-.68.058-.995.17v-2.461zm20.503 17.023a.996.996 0 00.995-.995V29.625a.989.989 0 00-.291-.703.989.989 0 00-.704-.292H34.84a.83.83 0 00-.65.36l-.007.012-1.557 2.265h-7.64a.99.99 0 00-.704.292.989.989 0 00-.291.704v10.751c0 .549.446.995.995.995h19.508z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;

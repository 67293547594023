import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="14"
      fill="none"
      viewBox="0 0 9 14"
    >
      <path
        fill="#FFCB4A"
        stroke="#FFCB4A"
        d="M1 9.415c0-.106.04-.212.119-.293a.398.398 0 01.573 0L4.5 11.998l2.808-2.876a.398.398 0 01.573 0 .422.422 0 010 .587l-3.094 3.17a.398.398 0 01-.573 0l-3.095-3.17A.418.418 0 011 9.415zM1 4.585c0 .106.04.212.119.293a.398.398 0 00.573 0L4.5 2.002l2.808 2.876a.398.398 0 00.573 0 .422.422 0 000-.587l-3.094-3.17a.398.398 0 00-.573 0l-3.095 3.17A.418.418 0 001 4.585z"
      ></path>
    </svg>
  );
}

export default Icon;

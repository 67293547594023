import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="30">
      <defs>
        <clipPath id="clip_0">
          <path
            d="M-1008.2 -114.28H835V922.52H-1008.2z"
            clipRule="evenodd"
          ></path>
        </clipPath>
      </defs>
      <g clipPath="url(#clip_0)">
        <path
          fill="#66511E"
          fillRule="evenodd"
          d="M22.138 24.046a11.469 11.469 0 001.572-5.801V14.87a3.379 3.379 0 00-3.375-3.375H8.24V6.431c0-1.798 1.514-3.261 3.375-3.261h1.181c1.861 0 3.375 1.463 3.375 3.261V8.12a1.125 1.125 0 102.25 0V6.431C18.422 3.392 15.9.92 12.797.92h-1.181c-3.102 0-5.625 2.472-5.625 5.511v5.064H4.135A3.379 3.379 0 00.76 14.87v3.375c0 6.327 5.148 11.475 11.475 11.475 1.844 0 3.677-.448 5.302-1.296a1.125 1.125 0 00-1.041-1.995 9.118 9.118 0 01-4.261 1.041c-5.087 0-9.225-4.138-9.225-9.225V14.87c0-.62.504-1.125 1.125-1.125h16.2c.62 0 1.125.505 1.125 1.125v3.375a9.218 9.218 0 01-1.263 4.662 1.125 1.125 0 101.94 1.139zm-8.78-1.188a1.125 1.125 0 01-2.25 0v-2.47a2.081 2.081 0 112.25.003v2.467z"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;

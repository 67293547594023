import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const width = 50 // TODO auto width based on steps

const ProgressBar = ({ steps }) => {

  const history = useHistory()

  return (
    <ProgressContainer>
      <BarContainer>
        <BarText>Completed information</BarText>
        <ProgressBarContainer>
          <BarEmpty
            data-showzero={width < 13 ? '' : '0%'}
            data-showfull={width > 80 ? '' : '100%'}
          >
            <BarFilled styledwidth={width} data-styledwidth={width + '%'} />
          </BarEmpty>
        </ProgressBarContainer>
      </BarContainer>
      <ProgressStepsContainer>
        {steps.map((step, index) => {
          return (
            <ProgressStep
            key={index}
              onClick={() => {
                if (step.href) {
                  history.push(step.href)
                }
              }}
            >
              <StepCircle active={step.active}>{index + 1}</StepCircle>
              {step.title}
            </ProgressStep>
          )
        })}
      </ProgressStepsContainer>
    </ProgressContainer>
  )
}

export default ProgressBar
// dodać position sticky/fixed
const ProgressContainer = styled.span`
  display: flex;
  flex-direction: column;
  gap: 50px;
`
const BarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  background-color: #fff;
  padding: 10px 30px 60px 30px;
  border-radius: 10px;
`
const BarText = styled.div`
  font-weight: bold;
  font-size: 10px;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #3d3c3c;
`
const ProgressBarContainer = styled.div``
const BarEmpty = styled.div`
  background: #f5f5f5;
  border-radius: 3px;
  height: 6px;
  width: 280px;
  position: relative;
  &::before {
    content: attr(data-showzero);
    position: absolute;
    left: 0;
    top: 12px;
    font-weight: 500;
    font-size: 12px;
    color: #ababab;
  }
  &::after {
    content: attr(data-showfull);
    position: absolute;
    right: 0;
    top: 12px;
    font-weight: 500;
    font-size: 12px;
    color: #ababab;
  }
`
const BarFilled = styled.div`
  width: ${({ styledwidth }) => styledwidth && styledwidth + '%'};
  background: #ffcb4a;
  border-radius: 3px;
  height: 6px;
  position: relative;
  transition: width 0.3s;
  &::before {
    content: attr(data-styledwidth);
    position: absolute;
    right: -16px;
    top: 12px;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    color: #ffcb4a;
  }
`
const ProgressStepsContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 15px;
  list-style: none;
`
const ProgressStep = styled.li`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: #3d3c3c;
  gap: 10px;
  cursor: pointer;
`
const StepCircle = styled.span`
  background-color: ${({ active }) => (active ? '#FFCB4A' : 'transparent')};
  color: ${({ active }) => (active ? '#fff' : '#FFCB4A')};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #ffcb4a;
  font-weight: 600;
  font-size: 13px;
  padding-top: 3px;
  text-align: center;
`

import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="16"
      fill="none"
      viewBox="0 0 12 16"
    >
      <path
        fill="#FFCB4A"
        fillRule="evenodd"
        d="M6.115.901C3.215.901.856 3.29.856 6.226c0 3.644 4.706 8.994 4.907 9.22a.47.47 0 00.704 0c.2-.226 4.907-5.576 4.907-9.22 0-2.936-2.36-5.325-5.259-5.325zm0 13.49c-1.584-1.905-4.312-5.664-4.312-8.165 0-2.407 1.934-4.366 4.312-4.366s4.312 1.959 4.312 4.366c0 2.501-2.728 6.26-4.312 8.165z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FFCB4A"
        d="M5.763 15.446l.112-.1-.112.1zm.704 0l-.112-.1.112.1zm4.907-9.22h.15-.15zm-5.259 8.165L6 14.487l.115.139.115-.14-.115-.095zm4.312-8.165h.15-.15zm-9.42 0c0-2.855 2.293-5.175 5.108-5.175v-.3C3.131.751.706 3.21.706 6.226h.3zm4.868 9.12a32.214 32.214 0 01-2.515-3.31c-.598-.907-1.188-1.917-1.628-2.924-.44-1.009-.726-2.004-.726-2.886h-.3c0 .94.303 1.98.751 3.006.45 1.028 1.049 2.054 1.652 2.968a32.5 32.5 0 002.541 3.345l.225-.199zm.48 0a.32.32 0 01-.48 0l-.225.2a.62.62 0 00.93 0l-.225-.2zm4.869-9.12c0 .882-.286 1.877-.726 2.886-.44 1.007-1.03 2.017-1.627 2.923a32.215 32.215 0 01-2.516 3.311l.225.2A32.5 32.5 0 009.12 12.2c.604-.914 1.203-1.94 1.652-2.968.448-1.026.75-2.065.75-3.006h-.3zM6.115 1.051c2.815 0 5.109 2.32 5.109 5.175h.3c0-3.017-2.425-5.475-5.409-5.475v.3zM1.653 6.226c0 1.294.702 2.89 1.583 4.382.885 1.5 1.969 2.922 2.764 3.879l.23-.192c-.788-.949-1.861-2.358-2.736-3.84-.878-1.487-1.54-3.022-1.54-4.229h-.3zM6.115 1.71c-2.462 0-4.462 2.028-4.462 4.516h.3c0-2.326 1.87-4.216 4.162-4.216v-.3zm4.462 4.516c0-2.488-2-4.516-4.462-4.516v.3c2.293 0 4.162 1.89 4.162 4.216h.3zm-4.347 8.26c.796-.956 1.879-2.379 2.764-3.878.882-1.493 1.583-3.088 1.583-4.382h-.3c0 1.207-.663 2.742-1.541 4.23-.875 1.48-1.947 2.89-2.736 3.839l.23.192z"
      ></path>
    </svg>
  );
}

export default Icon;

import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      fill="none"
      viewBox="0 0 15 15"
    >
      <g fill="#FFCB4A">
        <path d="M14.368 3.363l-.58-.58-.718.717a1.232 1.232 0 00-1.483.197l-.242.242V.385H2.704L.541 2.548v11.837h10.804V7.673l2.109-2.11a1.233 1.233 0 00.197-1.483l.717-.717zm-8.102 6.94l.582.582-1.01.427.428-1.009zm1.224.065l-.707-.707 4.514-4.514.707.707-4.514 4.514zM2.927 1.32v1.45h-1.45l1.45-1.45zm7.598 12.244H1.36V3.591h2.387V1.205h6.777V4.76L9.23 6.053H2.638v.82h5.773l-.82.82H2.638v.82H6.77L5.663 9.622l-.713 1.687-.573-.574-.916.916H2.18v.82h1.62l.576-.575.651.65 2.502-1.057 2.995-2.995v5.072zm2.349-8.581l-.29.29-.707-.707.29-.29a.41.41 0 01.58 0l.127.127c.16.16.16.42 0 .58z"></path>
        <path d="M3.793 4.412h4.3v.82h-4.3v-.82z"></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path
            fill="#fff"
            d="M0 0H14V14H0z"
            transform="translate(.454 .385)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
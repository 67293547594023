import React from 'react'
import CompanySvg from '../../../Icons/Company'
import truncateString from '../../../../lib/helpers/truncateString'

const CompaniesList = ({ companies }) => {
  const companyList = companies.map((company, index) => {
    return (
      <li key={index}>
        <CompanySvg />
        {truncateString(company.companyName, 20)}({company.total})
      </li>
    )
  })

  return <>{companyList}</>
}

export default CompaniesList

import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      fill="none"
      viewBox="0 0 50 50"
    >
      <path
        fill="#D7143A"
        d="M0 10C0 4.477 4.477 0 10 0h40v50H10C4.477 50 0 45.523 0 40V10z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M30.523 17c2.877 0 4.384.396 5.2 1.366.82.974.88 2.466.88 4.631v3.913c0 2.482-.39 3.915-1.308 4.792-.894.856-2.277 1.205-4.772 1.205H20.08c-5.14 0-6.08-2.02-6.08-5.997v-3.913c0-2.053 0-3.537.793-4.543C15.604 17.42 17.137 17 20.08 17h10.443zM23.77 27.972l4.743-2.478a.73.73 0 00-.002-1.293l-4.742-2.463a.729.729 0 00-1.066.647v4.941a.73.73 0 001.067.646z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default Icon

import React from 'react'
import styled from 'styled-components'
import Circle from '../../../Icons/YellowCircle'
import { ACTIONS } from '../reducer.js'
import RemoveButton from './RemoveButton.jsx'

const StepPickerComponent = ({ step, setStepsArray, item }) => {
  const handleInputChange = (e) => {
    setStepsArray({
      type: ACTIONS.ADD_RECRUITMENT_STEP,
      value: e.target.value,
      step,
    })
  }
  return (
    <StepCard>
      <CircleContainer>
        <Circle />
        <Counter className="step-counter">{step + 1}</Counter>
      </CircleContainer>
      <Input
        value={item}
        onChange={handleInputChange}
        type="text"
        placeholder="eg Interview with the recruiter"
      />
      {item && (
        <RemoveButton
          onClick={() =>
            setStepsArray({
              type: ACTIONS.REMOVE_RECRUITMENT_STEP,
              value: item,
            })
          }
        />
      )}
    </StepCard>
  )
}

export default StepPickerComponent

const StepCard = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  background-color: white;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
  height: 50px;
  border: 2px solid #ededed;
`
const CircleContainer = styled.div`
  position: relative;
  height: 20px;
`
const Counter = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 9px;
  font-weight: 600;
  color: #ffcb4a;
`
const Input = styled.input`
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  border: none;
  outline: none;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #6b6b6b;
  &::placeholder{
    color: #ABABAB;
  }
`

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useAuth } from '../../../../contexts/AuthContext'
import Modal from '../../../sharedComponents/Modal'
import PeopleSvg from './PeopleSvg'
import Icon from '../../../Icons/Recruitments'

const ChooseRecruitment = ({ id: candidateId }) => {
  const [data, setData] = useState([])
  const [activeOffers, setActiveOffers] = useState([])
  const [disabledButton, setDisabledButton] = useState(false)
  const { currentUser, account, setAccountInfo } = useAuth()
  const [message, setMessage] = useState('')
  const [phoneNumber, setPhoneNumber] = useState(
    account?.company?.phone || undefined
  )
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const onClose = () => {
    setLoading(true)
    const token = localStorage.getItem('wrk_token')
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/company/phone`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phoneNumber,
      }),
    })
      .then(setAccountInfo(currentUser))
      .then(() => setIsModalOpen(false))
      .finally(() => setLoading(false))
  }
  const toggleActive = (value) => {
    if (activeOffers.includes(Number(value))) {
      const temp = [...activeOffers]
      temp.splice(activeOffers.indexOf(Number(value)), 1)
      setActiveOffers(temp)
    } else {
      setActiveOffers((prev) => [...prev, Number(value)])
    }
  }

  const handleClick = async () => {
    activeOffers.forEach(async (i) => {
      await sendInvite(i)
    })
  }

  const sendInvite = async (jobOfferId) => {
    const token = localStorage.getItem('wrk_token')
    setDisabledButton(true)
    const data = {
      jobOfferId,
      candidateId,
    }
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/applications`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(() => setIsModalOpen(true))
      .catch((_) => {
        setMessage('error')
      })
      .finally(() => {
        setActiveOffers([])
        setDisabledButton(false)
      })
  }
  useEffect(() => {
    const token = localStorage.getItem('wrk_token')

    const loadData = () => {
      fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/recruitments/company/${account?.company.id}?onlyActive=1`,
        {
          headers: {
            Authorization: `Bearer: ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          const recruitmentsFiltered = response.filter(
            (jobOffer) =>
              !jobOffer.applications.filter(
                (application) => application.candidateId === Number(candidateId)
              ).length
          )
          setData(recruitmentsFiltered)
        })
    }
    if (currentUser && account?.company) {
      loadData()
    }
  }, [currentUser, account, candidateId, disabledButton])
  const recruitmentsList = data.map((recruitment, index) => (
    <Recruitment
      id={recruitment.id}
      active={activeOffers.includes(recruitment.id)}
      onClick={(e) => toggleActive(e.target.id)}
      key={index}
    >
      {recruitment.positionName}
    </Recruitment>
  ))
  return (
    <ChooseRecruitmentStyled>
      <StyledChooseRecruitmentText>
        <span>Choose the recruitment</span>{' '}
        <Flex>
          <PeopleSvg />{' '}
          <RecruitmentsLength>{recruitmentsList.length}</RecruitmentsLength>
        </Flex>
      </StyledChooseRecruitmentText>
      {Boolean(recruitmentsList.length) && (
        <>
          <RecruitmentsContainer>{recruitmentsList}</RecruitmentsContainer>
          <RecruitmentAddButton disabled={disabledButton} onClick={handleClick}>
            {disabledButton ? <div className="spinner" /> : 'Send invite'}
          </RecruitmentAddButton>
        </>
      )}
      <Modal
        title={'Thank you for inviting the candidate'}
        buttonText={loading ? <div className="spinner" /> : 'Send request'}
        open={isModalOpen}
        onClose={onClose}
        overlayClick={() => setIsModalOpen(false)}
      >
        <ModalBackground>
          <Icon />
          <div style={{ marginTop: '10px' }}>
            Our recruiter has already received information about your interest
            in the candidate and is in constant contact with this candidate.
          </div>
          <br />
          <div>
            We will get back to you with information on whether the candidate is
            interested in your offer.
          </div>
        </ModalBackground>
        <div>
          <ModalLabel>
            Phone Number
            <br />
            <ModalInput
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              type="tel"
              placeholder="123 456 789"
            />
          </ModalLabel>
        </div>
      </Modal>
      <InfoMessage>{message}</InfoMessage>
    </ChooseRecruitmentStyled>
  )
}

const ChooseRecruitmentStyled = styled.div`
  padding: 20px;
  width: 100%;
`
const StyledChooseRecruitmentText = styled.div`
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #ffcb4a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  width: 100%;
  border-bottom: 1px solid #ffcb4a;
`
const RecruitmentsContainer = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  border-radius: 10px;
  overflow: hidden;
`
const Recruitment = styled.li`
  font-weight: 500;
  font-size: 13px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  gap: 8px;
  background-color: '#fff';
  color: '#6b6b6b';
  cursor: pointer;
  &:hover {
    color: #ffcb4a;
    background-color: #fff7e3;
  }
  svg {
    cursor: pointer;
  }
  ${({ active }) =>
    active &&
    `
    color: #FFCB4A;
    background-color: #FFF7E3;
  `}
`

const RecruitmentAddButton = styled.button`
  background: #fff7e3;
  border-radius: 10px;
  width: 100%;
  border: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 11px;
  letter-spacing: 1px;
  color: #ffb600;
  height: 40px;
  cursor: pointer;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const InfoMessage = styled.span`
  color: green;
  font-size: 12px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
`
const RecruitmentsLength = styled.span`
  background: #f5f5f5;
  border-radius: 13.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 17px;
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  color: #ababab;
`
const Flex = styled.span`
  display: flex;
  align-items: center;
  gap: 5px;
`

const ModalBackground = styled.div`
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 30px 40px;
  max-width: 370px;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #3d3c3c;
`
const ModalLabel = styled.label`
  font-weight: 700;
  font-size: 10px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #3d3c3c;
  margin-top: 20px;
  display: block;
`
const ModalInput = styled.input`
  width: 100%;
  border: 2px solid #ededed;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  height: 50px;
  outline: none;
  padding: 0 15px;
  margin-bottom: -15px;
  &::placeholder {
    color: #ababab;
  }
`

export default ChooseRecruitment

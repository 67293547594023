import React from "react";
import styled from "styled-components";

function Icon() {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <rect width="28" height="28" fill="#FFF7E3" rx="13.5"></rect>
      <path
        fill="#FFCB4A"
        d="M20.636 18.667H22V20H7v-1.333h1.364v-10a.66.66 0 01.2-.472A.69.69 0 019.044 8h6.819c.18 0 .354.07.482.195a.66.66 0 01.2.472v10h2.727v-5.334h-1.364V12h2.046c.18 0 .354.07.482.195a.66.66 0 01.2.472v6zM9.727 9.333v9.334h5.455V9.333H9.727zm1.364 4h2.727v1.334h-2.727v-1.334zm0-2.666h2.727V12h-2.727v-1.333z"
      ></path>
    </StyledSvg>
  );
}
const StyledSvg = styled.svg`
position: absolute;
right: 25px;
top: 25px;
`
export default Icon;

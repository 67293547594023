import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <rect
        width="28"
        height="28"
        x="1"
        y="1"
        stroke="#FFCB4A"
        strokeWidth="2"
        rx="14"
      ></rect>
    </svg>
  );
}

export default Icon;

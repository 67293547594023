import React from 'react'
import styled from 'styled-components'

import DownArrow from '../../../Icons/YellowDownTick'
import ChooseRecruitment from './ChooseRecruitment'

const CandidatePageRightPanel = ({ id }) => {
  return (
    <div className="candidate-stack-box">
      <span className="title-right">
        <div className="leftline"></div>
        <span>
          <FixSvgPosition className="bold">
            <DownArrow/>
            Interesing candidate?
          </FixSvgPosition>
        </span>
        <div className="bottomline gray" />
      </span>

      <span className="candidate-form">
        <ChooseRecruitment id={id} />
      </span>
    </div>
  )
}

const FixSvgPosition = styled.p`
display: flex;
align-items: center;
gap: 10px;
`

export default CandidatePageRightPanel

import styled from 'styled-components'

export const LeftContainer = styled.div`
  width: 450px;
  position: relative;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  border-radius: 10px;
`
export const CornerImageContainer = styled.div`
  position: absolute;
  top: -35px;
  z-index: 11;
  left: 44px;
`
export const WhiteTop = styled.div`
  line-height: 20px;
  h1 {
    color: #3d3c3c;
    font-weight: 600;
    font-size: 18px;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    color: #ababab;
  }
`
export const FormContainer = styled.form`
  border-radius: 10px;
  padding: 52px 43px 36px 27px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 32px;
`
export const Container = styled.div`
  border-radius: 10px;
  padding: 52px 43px 36px 27px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 32px;
`
export const Title = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: #3d3c3c;
  margin-bottom: 15px;
`
export const Label = styled.label`
  font-weight: 700;
  font-size: 10px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #3d3c3c;
  ${({ required }) =>
    required &&
    `
    &::after {
    content: ' *';
    font-weight: bold;
    color: #FFCB4A;
  }
  `}
`

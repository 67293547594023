import React from 'react'
import { Switch } from 'react-router'
import styled from 'styled-components'

import media from '../../../lib/media.js'
import PrivateRoute from '../../../PrivateRoute'
import AccountInfoCompanyProfile from './company/AccountInfoCompanyProfile'
import AccountInfoCompanyPreview from './company/AccountInfoCompanyPreview.jsx'

const AccountInfo = () => {


  return (
    <AccountInfoContainerStyled>
      <AccountInfoTitle>Account settings</AccountInfoTitle>
      <StepContainer>

            <Switch>
              <PrivateRoute path="/account/company">
                <AccountInfoCompanyProfile />
              </PrivateRoute>
              <PrivateRoute path="/account/preview-company">
                <AccountInfoCompanyPreview/>
              </PrivateRoute>
            </Switch>



      </StepContainer>
    </AccountInfoContainerStyled>
  )
}

export default AccountInfo

const AccountInfoContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 940px;
  margin: 40px auto;
`
const StepContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  ${media.lessThan('lg')`
flex-direction: column;
gap: 50px;
align-items: center;
`}
`
const AccountInfoTitle = styled.h1`
  font-weight: 600;
  font-size: 26px;
  color: #3d3c3c;
  width: 100%;
  justify-self: flex-start;
  ${media.lessThan('lg')`
text-align: center;
`}
`

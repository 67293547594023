export const seniority = (level) =>
  level === 'Regular' ? 'mid' : level === 'Lead' ? 'senior' : level
export const contract = (contract) => {
  if (contract === 'B2B Contract') return 'B2B'
  if (contract === 'Employment contract') return 'umowa o pracę'
  if (contract === 'Contract of mandate') return 'umowa zlecenie'
  return contract
}
export const locations = (location) => {
  if (location === 'Warsaw') return 'Warszawa'
  if (location === 'Cracow') return 'Kraków'
  if (location === 'Poznan') return 'Poznań'
  if (location === 'Gdansk') return 'Gdańsk'
  if (location === 'Remote') return 'Zdalna'
  return location
}
import React from 'react'
import styled from 'styled-components'
import WorkeoLogo from '../../Icons/WorkeoLogo.jsx'

const ErrorPage = () => {
  return (
    <ErrorContainer>
      <WorkeoLogo/>
      <p>Ooops... Something went wrong..</p>
      <p>Please try refresh the page</p>
    </ErrorContainer>
  )
}

export default ErrorPage

const ErrorContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 100%;
`
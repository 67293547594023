import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 18 17"
    >
      <rect
        width="15.651"
        height="14.875"
        x="1.288"
        y="1"
        stroke="#FFCB4A"
        strokeWidth="2"
        rx="7.438"
      ></rect>
    </svg>
  );
}

export default Icon;

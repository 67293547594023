import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      fill="none"
      viewBox="0 0 70 70"
    >
      <rect width="70" height="70" fill="#FFCB4A" rx="35"></rect>
      <path
        fill="#66511E"
        fillRule="evenodd"
        d="M43.339 26.661A12.418 12.418 0 0034.5 23c-3.339 0-6.478 1.3-8.839 3.661A12.418 12.418 0 0022 35.5c0 3.339 1.3 6.478 3.661 8.839A12.418 12.418 0 0034.5 48c3.339 0 6.478-1.3 8.839-3.661A12.418 12.418 0 0047 35.5c0-3.339-1.3-6.478-3.661-8.839zm1.677 8.026l-4.974 4.974-1.383-1.383 5.912-5.913c.232.743.383 1.52.445 2.322zm-7.738 2.21l6.467-6.468a10.593 10.593 0 00-1.15-1.681l-6.733 6.733 1.416 1.416zm-1.801-11.898c.736.068 1.45.211 2.135.423l-2.135 2.135v-2.558zm4.075 1.245l-4.075 4.075v2.785l5.734-5.734a10.585 10.585 0 00-1.659-1.126zM34.5 46.047c-5.816 0-10.547-4.731-10.547-10.547 0-5.486 4.211-10.007 9.57-10.501v10.905l7.709 7.709a10.499 10.499 0 01-6.732 2.434zm6.923-5.005l1.19 1.19a10.514 10.514 0 002.216-4.596l-3.406 3.406z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;

import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      fill="none"
      viewBox="0 0 70 70"
    >
      <rect width="70" height="70" fill="#FFCB4A" rx="35"></rect>
      <path
        fill="#66511E"
        fillRule="evenodd"
        d="M47.615 50.008h3.389a.996.996 0 110 1.992H18.996a.996.996 0 110-1.992H22.4V33.162c0-1.264.697-2.41 1.82-2.991l8.464-4.378V23.81l-1.058-.435a.996.996 0 11.757-1.843l.3.123v-1.303l-1.057-.434a.996.996 0 01.757-1.843l17.021 6.996a.996.996 0 11-.757 1.843l-1.031-.424v1.303l1.789.735a.996.996 0 01-.758 1.843l-1.031-.424v20.06zM45.623 25.67l-10.948-4.5v1.303l10.948 4.5v-1.303zm-16.09 24.337v-3.169a.996.996 0 00-1.992 0v3.169h-3.15v-6.165h4.146a.996.996 0 000-1.992H24.39v-1.664h4.146a.996.996 0 100-1.992H24.39v-1.717h4.146a.996.996 0 100-1.992H24.39v-1.324c0-.517.285-.985.743-1.222l7.549-3.904v21.972h-3.15zm13.604-3.169v3.169h2.486v-20.88l-10.948-4.5v25.38h2.486v-3.169a.996.996 0 011.992 0v3.169h1.992v-3.169a.996.996 0 011.992 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;

const locations = [
    "Poland",
    "Warsaw",
    "Gdansk",
    "Poznan",
    "Cracow",
    "Olsztyn",
    "Wroclaw",
    "Katowice",
    "Łódź",
    "Gliwice",
    "Gdynia",
    "Remote",
]

export default locations
import React from "react";
import styled from "styled-components";

function Icon() {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      sm
      fill="none"
      viewBox="0 0 50 50"
    >
      <rect width="50" height="50" fill="#FFCB4A" rx="25"></rect>
      <path
        fill="#66511E"
        fillRule="evenodd"
        d="M33.505 27.176v-2.51a2.514 2.514 0 00-2.512-2.512h-8.998v-3.768c0-1.338 1.126-2.426 2.511-2.426h.879c1.385 0 2.511 1.088 2.511 2.426v1.257a.837.837 0 101.674 0v-1.257c0-2.26-1.877-4.1-4.185-4.1h-.879c-2.308 0-4.185 1.84-4.185 4.1v3.768H18.94a2.514 2.514 0 00-2.511 2.511v2.511c0 4.708 3.83 8.538 8.538 8.538a8.561 8.561 0 003.944-.964.837.837 0 00-.774-1.484 6.784 6.784 0 01-3.17.774 6.872 6.872 0 01-6.864-6.864v-2.51c0-.462.375-.838.837-.838h12.053c.462 0 .837.376.837.837v2.511a6.858 6.858 0 01-.94 3.47.837.837 0 101.445.846 8.532 8.532 0 001.17-4.316zm-7.703 3.432a.837.837 0 01-1.674 0v-1.837a1.547 1.547 0 01.838-2.85 1.549 1.549 0 01.836 2.852v1.835z"
        clipRule="evenodd"
      ></path>
    </StyledSvg>
  );
}

const StyledSvg = styled.svg`
width: 70px;
height: 70px;
${({sm}) => sm && `
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -25px;
    height: 50px;
    width: 50px;
`}
`

export default Icon;

import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Logo from '../Icons/WorkeoLogo'
import Dashboard from '../Icons/Dashboard'
import Candidates from '../Icons/Candidate'
import Salaries from '../Icons/Salaries'
import Recruitments from '../Icons/Recruitments'
import styled from 'styled-components'
import media from '../../lib/media.js'
import { useAuth } from '../../contexts/AuthContext.js'
const ROUTES = {
  DASHBOARD: 'dashboard',
  CANDIDATES: 'candidates',
  MARKET_DATA: 'recruitments',
  RECRUITMENTS: 'market-data',
}
const LeftBar = () => {
  const [activeRoute, setActiveRoute] = useState('')
  const { currentUser } = useAuth()
  const history = useHistory()
  const path = history.location.pathname

  useEffect(() => {
    path === '/' && setActiveRoute(ROUTES.DASHBOARD)
    path.includes('candidate') && setActiveRoute(ROUTES.CANDIDATES)
    path === '/salaries' && setActiveRoute(ROUTES.MARKET_DATA)
    path.includes('recruitment') && setActiveRoute(ROUTES.RECRUITMENTS)
  }, [path])

  if (!currentUser) return null

  return (
    <section className="left-bar">
      <LeftBarStyled>
        <Logo />
        <NavList>
          <li>
            <Link
              className={activeRoute === ROUTES.DASHBOARD ? 'active' : ''}
              to="/"
            >
              <Dashboard />
              Dashboard
            </Link>
          </li>
          <li>
            <Link
              className={activeRoute === ROUTES.CANDIDATES ? 'active' : ''}
              to="/candidates/page1"
            >
              <Candidates />
              Talent Pool
            </Link>
          </li>
          <li>
            <Link
              className={activeRoute === ROUTES.RECRUITMENTS ? 'active' : ''}
              to="/recruitments"
            >
              <Recruitments />
              Recruitments
            </Link>
          </li>
          <li>
            <Link
              className={activeRoute === ROUTES.MARKET_DATA ? 'active' : ''}
              to="/salaries"
            >
              <Salaries />
              Market data
            </Link>
          </li>
        </NavList>
      </LeftBarStyled>
    </section>
  )
}

export default LeftBar

const LeftBarStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.lessThan('lg')`
  flex-direction: row;
  height: 10vh;
  min-height: 70px;
`}
`
const NavList = styled.ul`
  list-style: none;
  position: relative;
  top: 30px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  a {
    margin: 15px 0px;
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #b2b2b2;
    font-size: 11px;
    &:hover {
      color: white;
      path {
        fill: rgb(255, 182, 0);
      }
    }
    svg {
      margin-bottom: 5px;
    }
    path {
      fill: #808080;
      transition: 0.3s;
    }
  }
  ${media.lessThan('lg')`
  height: 50px;
  top: 0;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  
`}
`

import React from 'react'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import useSWR from 'swr'
import { useFilter } from '../../../../contexts/FilterContext.js'
import { contract } from '../../../../lib/helpers/filterHelper.js'
import CandidatesListComponent from './CandidatesListComponent'

const Candidates = () => {
  const { valueLevel, valueType, valuePosition, valueTechnology } = useFilter()
  const queryParams = useMemo(
    () => ({
      limit: 5,
      level: valueLevel !== 'All seniority levels' ? valueLevel : '',
      type: valueType !== 'All contracts' ? contract(valueType) : '',
      position: valuePosition !== 'All positions' ? valuePosition : '',
      technology: valueTechnology !== 'All technologies' ? valueTechnology : '',
    }),
    [valueLevel, valueType, valuePosition, valueTechnology]
  )
  const token = localStorage.getItem('wrk_token')
  
  const { data, error } = useSWR([
    `${process.env.REACT_APP_BACKEND_URL}/api/candidates`,
    token,
    queryParams,
  ])
  if (error) {
    return null
  }

  const candidatesList = data?.candidates?.map((candidate) => (
    <CandidatesListComponent
      id={candidate.id}
      level={candidate.experience}
      position={candidate.position}
      key={candidate.id}
    />
  ))

  return (
    <div className="candidates-card">
      <p className="candidates-title">Candidates looking for job</p>
      <ul className="candidates-list">{candidatesList}</ul>
      <Link to="/candidates/page1">Show more</Link>
    </div>
  )
}

export default Candidates

import React from 'react'
import { Link } from 'react-router-dom'
import CandidateListAvatar from '../../../Icons/CandidateListAvatar'
import truncateString from '../../../../lib/helpers/truncateString'
const CandidatesListComponent = ({ level, position, id }) => {
  return (
    <li>
      <Link to={`/candidate/${id}`}>
        <CandidateListAvatar />
        {truncateString(`Candidate #${id} ${level} ${position}`, 50)}
      </Link>
    </li>
  )
}

export default CandidatesListComponent
import React from 'react'
import { Link } from 'react-router-dom'
import Avatar from '../../../Icons/Avatar'
import OpenFrom from './icons/OpenFrom.jsx'
import Contract from './icons/Contract.jsx'
import Salaries from './icons/Salaries.jsx'
const Candidate = ({
  link,
  id,
  position,
  salary,
  seniority,
  contract,
  skills,
  openFrom,
}) => {
  const name = 'Candidate #' + id
  const salaries = `${salary} PLN`
  const stack =
    skills?.length === 1 && skills[0] === '' ? (
      <span key={0} className="stackbubble bubbles">
        Empty
      </span>
    ) : (
      skills?.map((i, index) => {
        if (index < 7) {
          return (
            <span key={index} className="stackbubble bubbles">
              {i}
            </span>
          )
        } else return null
      }
      )
    )
  let moreStack =
    skills?.length > 8 && skills[0] !== '' ? (
      <div className="bubbles stackbubble more">
        {`${skills.length - 7} more..`}
        <span className="tooltiptext">
          {skills
            .map((i, index) => <p key={index}>{i}</p>)
            .filter((i, index) => index >= 7)}
        </span>
      </div>
    ) : null //add methodologies (?)
  return (
    <Link to={`/candidate/${link}`}>
      <section className={`candidate`}>
        <div className="topline"></div>
        <div className="name-info">
          <Avatar />
          <span>
            <p className="name">{name}</p>
            <p className="technology">{position}</p>
          </span>
        </div>
        <div className="info">
          <span className={`bubbles ${seniority}`}>{seniority}</span>
          <span className="bubbles">
            <Salaries/>
            {salaries}
          </span>
          <span className="bubbles">
            <Contract/>
            {contract}
          </span>
          <span className="bubbles">
           <OpenFrom/>
            {openFrom}
          </span>
        </div>
        <div className="stack-info">
          <p>technology stack</p>
          <span className="stack">
            {stack} {moreStack}
          </span>
          <span className="goto"></span>
        </div>
      </section>
    </Link>
  )
}

export default Candidate
import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      fill="none"
      viewBox="0 0 100 100"
    >
      <path
        fill="url(#paint0_linear)"
        d="M50 100c27.614 0 50-22.386 50-50S77.614 0 50 0 0 22.386 0 50s22.386 50 50 50z"
        opacity="0.5"
      ></path>
      <path
        fill="#F5F5F5"
        d="M50 100c27.614 0 50-22.386 50-50S77.614 0 50 0 0 22.386 0 50s22.386 50 50 50z"
      ></path>
      <path
        fill="#FFC63A"
        d="M50.143 98.82a48.71 48.71 0 0026.904-8.052C75.244 80.911 68.38 80.35 68.38 80.35H30.529s-6.477.531-8.5 9.599a48.72 48.72 0 0028.114 8.87z"
      ></path>
      <path
        fill="#333"
        d="M49.623 72.274c13.048 0 23.625-10.578 23.625-23.625S62.67 25.024 49.623 25.024C36.576 25.024 26 35.602 26 48.65s10.577 23.625 23.624 23.625z"
      ></path>
      <path
        fill="#000"
        d="M41.998 68.327h15.138v12.11a7.57 7.57 0 01-12.918 5.352 7.569 7.569 0 01-2.217-5.352v-12.11H42z"
        opacity="0.1"
      ></path>
      <path
        fill="#FDB797"
        d="M42.478 67.765h14.184a.48.48 0 01.48.48v11.63a7.57 7.57 0 11-15.14.002V68.245a.48.48 0 01.476-.48z"
      ></path>
      <path
        fill="#000"
        d="M42.026 74.043a21.787 21.787 0 0015.137.041v-1.862H42.026v1.821z"
        opacity="0.1"
      ></path>
      <path
        fill="#FDB797"
        d="M49.623 75.056c11.983 0 21.698-9.714 21.698-21.698 0-11.983-9.715-21.697-21.698-21.697s-21.698 9.714-21.698 21.697c0 11.984 9.715 21.698 21.698 21.698z"
      ></path>
      <path
        fill="#000"
        d="M34.168 33.622s9.144 18.561 34.94 7.782l-6.006-9.417-10.646-3.82-18.288 5.455z"
        opacity="0.1"
      ></path>
      <path
        fill="#333"
        d="M34.168 33.35s9.144 18.561 34.94 7.779l-6.006-9.417-10.646-3.82-18.288 5.458z"
      ></path>
      <path
        fill="#333"
        d="M34.087 32.092a12.534 12.534 0 013.108-5.053c4.268-4.22 11.265-5.11 14.854-9.92.86 1.335.195 3.388-1.29 3.961 3.439-.023 7.423-.324 9.367-3.152a6.432 6.432 0 01-1.084 6.791c3.047.143 6.303 2.206 6.495 5.25.132 2.03-1.146 3.95-2.806 5.113-1.66 1.163-3.684 1.697-5.668 2.134-5.794 1.28-26.756 6.634-22.976-5.124z"
      ></path>
      <path
        fill="#FDB797"
        d="M27.917 57.123c1.115 0 2.018-1.694 2.018-3.785 0-2.09-.903-3.785-2.018-3.785-1.115 0-2.019 1.695-2.019 3.785s.904 3.785 2.019 3.785zM71.318 57.123c1.115 0 2.019-1.694 2.019-3.785 0-2.09-.904-3.785-2.019-3.785-1.115 0-2.019 1.695-2.019 3.785s.904 3.785 2.02 3.785z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="50"
          x2="50"
          y1="100"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity="0.25"></stop>
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12"></stop>
          <stop offset="1" stopColor="gray" stopOpacity="0.1"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Icon;

import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="12"
      fill="none"
      viewBox="0 0 9 12"
    >
      <path
        fill="#6B6B6B"
        d="M8.299 4.97h-.49V3.267C7.81 1.466 6.325 0 4.5 0 2.675 0 1.19 1.466 1.19 3.267V4.97H.702A.697.697 0 000 5.662V11.307c0 .383.314.693.701.693h7.598c.387 0 .701-.31.701-.692V5.663a.697.697 0 00-.701-.693zM5.246 9.928H3.754L4.02 8.67a.88.88 0 01-.417-.745.89.89 0 01.896-.884.89.89 0 01.896.884.88.88 0 01-.417.745l.267 1.257zM6.628 4.97H2.372V3.267c0-1.158.954-2.101 2.128-2.101 1.174 0 2.128.943 2.128 2.101V4.97z"
      ></path>
    </svg>
  )
}

export default Icon

import React from 'react'
import { Pie } from 'react-chartjs-2'
import styled from 'styled-components'
const PieChart = ({ offersData }) => {
  let backgroundColor = [
    'rgba(36,192,246, 1)', //warszawa
    'rgba(215,210,252, 1)', //gdansk
    'rgba(255,112, 88, 1)', //krakow
    'rgba(78,185,111,1)', //wroclaw
    'rgba(59,151,210,1)', //poznan
    'rgba(148,91,164, 1)', //łódź
    'rgba(172,179,186, 1)', //katowice
    'rgba(255, 203, 60, 1)', // zdalnie - zolty
    'rgba(255, 160, 60, 1)', //poznan
    'rgba(148, 100, 16, 1)', //szczecin
    'rgba(59, 150, 100, 1)', //bydgoszcz
    'rgba(255, 112, 88, 1)', //lublin
    'rgba(35, 100, 200, 1)', //białystok
  ]
  let labels = [
    'Warsaw',
    'Gdansk',
    'Cracow',
    'Wroclaw',
    'Poznan',
    'Łódź',
    'Katowice',
    // 'Remote',
    // 'Szczecin',
    // 'Bydgoszcz',
    'Lublin',
    // 'Białystok',
  ]
  const data = {
    labels,
    datasets: [
      {
        label: 'Offers',
        data: [
          offersData?.warsaw,
          offersData?.gdansk,
          offersData?.cracow,
          offersData?.wroclaw,
          offersData?.poznan,
          offersData?.lodz,
          offersData?.katowice,
          offersData?.lublin
          // offersData?.remote,
        ],
        borderWidth: 1,
        backgroundColor,
        borderColor: 'rgba(255, 255, 255, 1)',
      },
    ],
  }
  const options = {
    maintainAspectRatio: false,
    title: {
      display: false,
    },
    layout: {
      padding: {
        // left:25,
        right: 25,
        bottom: 5,
      },
    },
    legend: {
      // display: false,   todo minwidth - false
      position: 'right',
      labels: {
        fontFamily: 'Poppins',
        fontSize: 10,
      },
    },
  }
  return (
    <ColumnFlex className="chart">
      <ChartTitle>NUMBER OF OFFERS IN OTHER CITIES</ChartTitle>
      <div className="pie-container">
        <Pie data={data} options={options} />
      </div>
    </ColumnFlex>
  )
}
const ColumnFlex = styled.div`
  flex-direction: column;
  padding: 12px 0;
`
const ChartTitle = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: #3d3c3c;
  margin-bottom: 10px;
  opacity: .9;
`
export default PieChart
import React from "react";
import styled from "styled-components";

function Icon() {
  return (
    <FixedClick
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
    >
      <rect
        width="23"
        height="23"
        x="1.5"
        y="1.5"
        fill="#fff"
        stroke="#fff"
        strokeWidth="3"
        rx="7.5"
      ></rect>
    </FixedClick>
  );
}
const FixedClick = styled.svg`
pointer-events: none;
` 

export default Icon;

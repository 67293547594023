import styled from 'styled-components'
import React from 'react'

export const AccountInfoStepContainer = ({
  children,
  title,
  subtitle,
  noPadding,
  onSubmit,
}) => {
  return (
    <AccountInfoStepContainerStyled onSubmit={onSubmit}>
      <TopLine />
      <Padding noPadding={noPadding}>
        <TitleContainer padding={noPadding}>
          <Title>{title}</Title>
          <SubTitle>{subtitle}</SubTitle>
        </TitleContainer>
        {children}
      </Padding>
    </AccountInfoStepContainerStyled>
  )
}

export const IconInput = ({ Icon, placeholder, value, setValue }) => {
  return (
    <Flex center>
      <Icon />
      <IconInputStyled placeholder={placeholder} value={value} onChange={(e) => setValue(e.target.value)} />
    </Flex>
  )
}

const AccountInfoStepContainerStyled = styled.form`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 450px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
`
const TopLine = styled.div`
  background: #ffcb4a;
  border-radius: 10px;
  width: 100%;
  height: 3px;
`
const Padding = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  ${({ noPadding }) =>
    noPadding &&
    `
  padding: 0;
  padding-top: 40px;
  `}
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${({ padding }) =>
    padding &&
    `
  padding-left: 40px;
  padding-right: 40px;
  `}
`

export const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #3d3c3c;
`
export const SubTitle = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: #ababab;
`

export const ErrorMsg = styled.div`
font-weight: 500;
font-size: 12px;
color: #F34938;
padding-left: 10px;
`

export const Label = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #3d3c3c;
`

export const BoldLabel = styled.label`
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #3d3c3c;
  font-weight: bold;
  font-size: 10px;

  ${({ required }) =>
    required &&
    `
    &::after {
    content: ' *';
    font-weight: bold;
    font-size: 10px;
    vertical-align: top;
    color: #FFCB4A;
  }
  `}
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: ${({ width = '100%' }) => width};
  ${({ padding }) =>
    padding &&
    `
  padding-left: 40px;
  padding-right: 40px;
  `}
`

export const Input = styled.input`
  background: #ffffff;
  border: 2px solid #ededed;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  color: #6b6b6b;
  height: 50px;
  padding: 0 20px;
  outline: none;
  &::placeholder {
    color: #ababab;
  }
  &:focus{
    border-color: #ffcb4a;
  }
  ${({error}) => error && `
  border-color: #F34938 !important;
  `}
`
const IconInputStyled = styled(Input)`
  border-radius: 0px 10px 10px 0px;
  outline: none;
  border-left: none;
  flex: 1;
`

export const TextArea = styled.textarea`
  background: #ffffff;
  color: #ababab;
  border: 2px solid #ededed;
  box-sizing: border-box;
  border-radius: 10px;
  resize: vertical;
  padding: 14px 20px;
  outline: none;
  cursor: pointer;
  &:focus{
    border-color: #ffcb4a;
  }
`

export const GreyBackground = styled.div`
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  gap: 30px;
  padding: 30px 40px;
`

export const Button = styled.button`
  background: #ffcb4a;
  border-radius: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  height: 50px;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #ffb600;
    color: #fff;
  }
`
export const Flex = styled.div`
  display: flex;
  ${({ gap }) =>
    gap &&
    `
    gap: ${gap};
`}
  ${({ spaceBetween }) =>
    spaceBetween &&
    `
    justify-content: space-between;
`}
${({ center }) =>
    center &&
    `
align-items: center;
`}
  ${({ padding }) =>
    padding &&
    `
  padding-left: 40px;
  padding-right: 40px;
  `}
`

import React, { useEffect } from 'react'
import styled from 'styled-components'
import WhiteRectangle from '../Icons/WhiteRectangle.jsx'
import PickedRectangle from '../Icons/PickedRectangle.jsx'

const HorizontalPicker = ({
  value,
  setValue,
  values,
  setHookFormValue,
  hookFormValue,
}) => {
  const onClick = (item) => {
    if (value?.includes(item)) {
      setValue((prev) => prev.filter((i) => i !== item))
    } else {
      setValue((prev) => [...prev, item])
      if (hookFormValue) {
        setHookFormValue(`${hookFormValue}.${value.length}`, item)
      }
    }
  }
  useEffect(() => {
    if (hookFormValue) {
      setHookFormValue(hookFormValue, value)
    }
  }, [value, hookFormValue, setHookFormValue])
  return (
    <Container>
      {values.map((i, index) => {
        const isActive = value?.includes(i)
        return (
          <Block
            key={index}
            type="button"
            active={isActive}
            id={i}
            onClick={() => onClick(i)}
          >
            {isActive ? <PickedRectangle /> : <WhiteRectangle />}
            {i}
          </Block>
        )
      })}
    </Container>
  )
}

export default HorizontalPicker

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  gap: 1px;
`
const Block = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  background: #f5f5f5;
  text-align: left;
  padding: 0 12px;
  height: 50px;
  letter-spacing: 1px;
  color: #ababab;
  font-size: 13px;
  border: none;
  font-weight: 500;
  transition-property: background-color, color;
  transition-duration: 0.3s;
  ${({ active }) =>
    active &&
    `
    background-color: #FFF7E3;
    color: #FFB600;
`}
`

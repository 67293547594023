import React from 'react'
import { useState } from 'react'

import Wallet from '../../Icons/Wallet'
import Location from '../../Icons/LocationGray'
import Recruitments from '../../Icons/Recruitments'
import ReturnArrow from '../../Icons/ReturnArrow'
import { useHistory } from 'react-router'

import truncateString from '../../../lib/helpers/truncateString'
import styled from 'styled-components'
import CandidateListItem from './CandidateListItem'
import TopMenu from '../../sharedComponents/TopMenu/TopMenu.jsx'
import RecruitmentsIcon from '../../Icons/Recruitments.jsx'
import media from '../../../lib/media.js'
import useSWR from 'swr'
import ErrorPage from '../../sharedComponents/error/ErrorPage.jsx'
import Loading from '../../sharedComponents/loading/Loading.jsx'

const INVITED = 1
const ACTIVE = 2
const REJECTED = 3

const CandidatesInvited = ({ match }) => {
  const [status, setStatus] = useState(ACTIVE)
  const history = useHistory()
  const token = localStorage.getItem('wrk_token')

  const { data: recruitmentData, error } = useSWR([
    `${process.env.REACT_APP_BACKEND_URL}/api/recruitments/${match.params.id}?status=${status}`,
    token,
  ])

  const loading = !recruitmentData

  if (error) {
    return <ErrorPage />
  }

  const candidatesList = recruitmentData?.applications?.map(
    (application, index) => (
      <CandidateListItem
        key={index}
        candidate={application.candidate}
        jobSlug={match.params.id}
        status={application.statusId}
        active={application.statusId === 2}
        fromDatabase={Boolean(application.candidate.position)}
      />
    )
  )

  const salary = recruitmentData?.minSalaryB2B
    ? (recruitmentData?.minSalaryB2B?.toLocaleString() || 0) +
      ' - ' +
      (recruitmentData?.maxSalaryB2B?.toLocaleString() || 0)
    : (recruitmentData?.minSalaryUoP?.toLocaleString() || 0) +
      ' - ' +
      (recruitmentData?.maxSalaryUoP?.toLocaleString() || 0)

  return (
    <>
      {loading && <Loading />}
      <div className="candidates-invited-container">
        <TopMenu
          noFilter
          pageName={'Recruitments panel'}
          tooltipHeader="Recruitments"
          information={''}
          TooltipIcon={RecruitmentsIcon}
        />
        <Flex>
          <FlexColumn>
            <div className="recruitment-card-invited">
              <div>
                <span className="recruitment-position-name">
                  <Recruitments />
                  {truncateString(recruitmentData?.positionName, 14)}
                </span>
              </div>
              <div className="info-recr-invited-container">
                <GapTextSvg>
                  <Wallet className="svg-color" />{' '}
                  {truncateString(`${salary} ${recruitmentData?.currency}`, 15)}
                </GapTextSvg>

                <GapTextSvg>
                  <Location className="svg-color" />{' '}
                  {truncateString(recruitmentData?.city, 10)}
                </GapTextSvg>
              </div>
              <div className="exp-level-container">
                <span className="exp-level">Experience level</span>
                <span className={`bubbles ${recruitmentData?.reqExpLevel}`}>
                  {recruitmentData?.reqExpLevel}
                </span>
              </div>
            </div>
            <LinkStyled onClick={() => history.goBack()}>
              <ReturnArrow /> Return
            </LinkStyled>
          </FlexColumn>

          <CandidatesInvitedList>
            <TopInvited>
              <span>Candidates</span>
              <InvitedStatusContainer>
                <StatusItem
                  onClick={() => setStatus(ACTIVE)}
                  active={status === ACTIVE}
                >
                  ACTIVE
                </StatusItem>
                <StatusItem
                  onClick={() => setStatus(INVITED)}
                  active={status === INVITED}
                >
                  Invited
                </StatusItem>
                <StatusItem
                  onClick={() => setStatus(REJECTED)}
                  active={status === REJECTED}
                >
                  REJECTED
                </StatusItem>
              </InvitedStatusContainer>
            </TopInvited>
            <div>
              <InvitedList>{candidatesList}</InvitedList>
            </div>
          </CandidatesInvitedList>
        </Flex>
      </div>
    </>
  )
}
const Flex = styled.div`
  display: flex;
  margin-top: 50px;
  gap: 50px;
  ${media.lessThan('2xl')`
  justify-content: center;
  flex-direction: column;
  `}
`
const InvitedList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  ${media.lessThan('xl')`
    flex-direction: row;
  align-items: center;
  justify-content: space-around;
    flex-wrap: wrap;
  `}
`
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`
const LinkStyled = styled.button`
  text-decoration: none;
  font-size: 1rem;
  color: #ababab;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  &:hover {
    color: rgb(255, 203, 74);
    svg {
      path {
        fill: rgb(255, 203, 74);
      }
    }
  }
`
const GapTextSvg = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
  svg {
    path {
      fill: #ffcb4a;
    }
  }
`
const CandidatesInvitedList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
`
const TopInvited = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 15px;
  color: #3d3c3c;
`

const InvitedStatusContainer = styled.span`
  display: flex;
  gap: 40px;
  color: #ababab;
  text-transform: uppercase;
`
const StatusItem = styled.span`
  cursor: pointer;
  height: 25px;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 700;
  color: ${({ active }) => active && '#3d3c3c'};
  border-bottom: ${({ active }) => active && '2px solid rgb(255, 203, 74)'};

  &:hover {
    color: #3d3c3c;
    border-bottom: 2px solid rgb(255, 203, 74);
  }
`

export default CandidatesInvited

export const foundationYear = (() => Array.from(Array(100).keys()).map(i => {
  return {
    id: i+1,
    label: new Date().getFullYear()-i
  }
}))();

export const employeesNumberOptions = [
    {
      id: 1,
      label: '5-10',
    },
    {
      id: 2,
      label: '10-15',
    },
    {
      id: 3,
      label: '15-30',
    },
    {
      id: 4,
      label: '30-50',
    },
    {
      id: 5,
      label: '>50'
    }
  ]
import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="17"
      fill="none"
      viewBox="0 0 13 17"
    >
      <path
        fill="#3D3C3C"
        fillRule="evenodd"
        d="M6.432 1A5.439 5.439 0 001 6.432c0 3.718 4.862 9.175 5.068 9.406a.49.49 0 00.728 0c.207-.23 5.069-5.688 5.069-9.406A5.439 5.439 0 006.432 1zm0 13.762c-1.636-1.944-4.454-5.778-4.454-8.33a4.46 4.46 0 014.454-4.454 4.46 4.46 0 014.455 4.454c0 2.552-2.818 6.386-4.455 8.33z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#3D3C3C"
        d="M6.068 15.838l.112-.1-.112.1zm.728 0l-.111-.1.111.1zm5.069-9.406h.15-.15zm-5.433 8.33l-.114.096.114.136.115-.136-.115-.096zm4.455-8.33h.15-.15zm-9.737 0A5.289 5.289 0 016.432 1.15v-.3A5.589 5.589 0 00.85 6.432h.3zm5.03 9.305a32.91 32.91 0 01-2.599-3.378c-.617-.924-1.227-1.954-1.681-2.982-.455-1.03-.75-2.045-.75-2.945h-.3c0 .96.313 2.02.776 3.067.463 1.048 1.082 2.094 1.706 3.027a33.23 33.23 0 002.625 3.412l.223-.2zm.505 0a.34.34 0 01-.505 0l-.223.201a.64.64 0 00.951 0l-.223-.2zm5.03-9.305c0 .9-.295 1.916-.75 2.945-.454 1.028-1.064 2.058-1.681 2.982a32.91 32.91 0 01-2.6 3.379l.224.2a33.229 33.229 0 002.625-3.412c.623-.933 1.242-1.979 1.706-3.027.463-1.047.776-2.107.776-3.067h-.3zM6.432 1.15a5.289 5.289 0 015.283 5.282h.3A5.589 5.589 0 006.432.85v.3zM1.828 6.432c0 1.32.725 2.947 1.635 4.47.915 1.53 2.033 2.98 2.855 3.956l.23-.193c-.816-.968-1.924-2.406-2.827-3.917-.908-1.518-1.593-3.084-1.593-4.316h-.3zm4.604-4.604a4.61 4.61 0 00-4.604 4.604h.3a4.31 4.31 0 014.304-4.304v-.3zm4.605 4.604a4.61 4.61 0 00-4.605-4.604v.3a4.31 4.31 0 014.305 4.304h.3zm-4.49 8.426c.822-.976 1.94-2.427 2.855-3.956.91-1.523 1.635-3.15 1.635-4.47h-.3c0 1.232-.685 2.798-1.593 4.316-.903 1.511-2.011 2.949-2.826 3.917l.23.193z"
      ></path>
    </svg>
  );
}

export default Icon;

import React from 'react'
import styled from 'styled-components'
import Filter from '../../MainPage/Home/Filter/Filter.js'
import PageTitle from './PageTitle.jsx'

const TopMenu = ({ pageName, noFilter, information, TooltipIcon, tooltipHeader }) => {
  return (
    <TopContainer>
      <PageTitle title={pageName} information={information} tooltipHeader={tooltipHeader} TooltipIcon={TooltipIcon} />
      {noFilter ? null : <Filter />}
    </TopContainer>
  )
}

const TopContainer = styled.div`
  margin: 20px auto;
  max-width: 1440px;
`

export default TopMenu

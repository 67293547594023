import React, { useState } from 'react'
import styled from 'styled-components'
import {
  CornerImageContainer,
  FormContainer,
  LeftContainer,
  Title,
  WhiteTop,
} from '../NewJobOfferStyledComponents'
import Step4Svg from '../NewJobOffersComponents/Step4Svg'
import YoutubeSvg from '../NewJobOffersComponents/YoutubeSvg'
import ProjectDescriptionModal from '../../../sharedComponents/AboutModal.jsx'
import Button from '../../../sharedComponents/Button.jsx'
import { ACTIONS } from '../reducer.js'

const ListItem = ({ value, removeFunction, type, index }) => {
  return (
    <ListItemStyled>
      {value}
      <RemoveButton
        onClick={(e) => {
          e.preventDefault()
          removeFunction(type, value)
        }}
      />
    </ListItemStyled>
  )
}

const NewJobOfferStep4 = ({
  projectDescription,
  methodologies,
  projectVideo,
  projectStack,
  send,
  dispatch,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [technologyStackValue, setTechnologyStackValue] = useState('')
  const [methodologiesValue, setMethodologiesValue] = useState('')
  const [disabledButton, setDisabledButton] = useState(false)

  const addToArray = (type) => {
    if (type === 'projectStack') {
      if (technologyStackValue === '') return
      dispatch({
        type: ACTIONS.ADD_TO_ARRAY,
        value: technologyStackValue,
        key: type,
      })
      setTechnologyStackValue('')
    }
    if (type === 'methodologies') {
      if (methodologiesValue === '') return
      dispatch({
        type: ACTIONS.ADD_TO_ARRAY,
        value: methodologiesValue,
        key: type,
      })
      setMethodologiesValue('')
    }
  }
  const removeFromArray = (type, value) => {
    if (type === 'projectStack') {
      dispatch({
        type: ACTIONS.REMOVE_FROM_ARRAY,
        key: type,
        value,
      })
    }
    if (type === 'methodologies') {
      dispatch({
        type: ACTIONS.REMOVE_FROM_ARRAY,
        key: type,
        value,
      })
    }
  }
  const handleEnterPress = (e, type) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      addToArray(type)
    }
  }

  const methodologiesList = methodologies?.map((i, index) => (
    <ListItem
      key={index}
      value={i}
      removeFunction={removeFromArray}
      index={index}
      type="methodologies"
    />
  ))
  const technologyStackList = projectStack?.map((i, index) => (
    <ListItem
      key={index}
      value={i}
      removeFunction={removeFromArray}
      index={index}
      type="projectStack"
    />
  ))

  return (
    <LeftContainer>
      <div className="topline-inf" />
      <CornerImageContainer>
        <Step4Svg />
      </CornerImageContainer>
      <FormContainer>
        <WhiteTop>
          <h1>Detailed information</h1>
          <p>Project information</p>
        </WhiteTop>
        <SectionWrapper>
          <Title>Project description:</Title>
          <ProjectDescription
            readOnly
            value={projectDescription}
            rows={10}
            placeholder="Write project description"
            onClick={() => setIsOpen(true)}
          />
          <ProjectDescriptionModal
            name="projectDescription"
            initValue={projectDescription}
            open={isOpen}
            onClose={dispatch}
            overlayClick={() => setIsOpen(false)}
            btnText="Add project description"
            modalTitle="Project description"
          />
        </SectionWrapper>
        <SectionWrapper>
          <Title>Project video youtube url:</Title>
          <YoutubeInputWrapper>
            <YoutubeSvg />
            <YoutubeInput
              name="projectVideo"
              placeholder="https://youtube.com/..."
              value={projectVideo}
              onChange={(e) =>
                dispatch({
                  type: ACTIONS.UPDATE,
                  key: e.target.name,
                  value: e.target.value,
                })
              }
            />
          </YoutubeInputWrapper>
        </SectionWrapper>
        <SectionWrapper>
          <Title>Methodologies used in the project:</Title>
          <InputListContainer>
            <Input
              onKeyPress={(e) => handleEnterPress(e, 'methodologies')}
              value={methodologiesValue}
              onChange={(e) => setMethodologiesValue(e.target.value)}
              type="text"
              placeholder="Agile"
            />
            <AddButton
              type="button"
              onClick={() => addToArray('methodologies')}
            />
          </InputListContainer>
          <List>{methodologiesList}</List>
        </SectionWrapper>
        <SectionWrapper>
          <Title>Project technology stack:</Title>

          <InputListContainer>
            <Input
              onKeyPress={(e) => handleEnterPress(e, 'projectStack')}
              value={technologyStackValue}
              onChange={(e) => setTechnologyStackValue(e.target.value)}
              type="text"
              placeholder="React"
            />
            <AddButton
              type="button"
              onClick={() => addToArray('projectStack')}
            />
          </InputListContainer>
          <List>{technologyStackList}</List>
        </SectionWrapper>
        <Button
          disabled={disabledButton}
          onClick={(e) => {
            e.preventDefault()
            setDisabledButton(true)
            send()
          }}
        >
          FINISH
        </Button>
      </FormContainer>
    </LeftContainer>
  )
}

const ProjectDescription = styled.textarea`
  background: #ffffff;
  border: 2px solid #ededed;
  color: #ababab;
  box-sizing: border-box;
  border-radius: 10px;
  resize: none;
  padding: 14px;
  cursor: pointer;
  outline: none;
`
const YoutubeInputWrapper = styled.div`
  display: flex;
  align-items: center;
`
const InputStyled = styled.input`
  background: #ffffff;
  border: 2px solid #ededed;
  box-sizing: border-box;
  border-radius: 10px;
  height: 50px;
  width: 370px;
  padding: 0 20px;
`
const YoutubeInput = styled(InputStyled)`
  border-radius: 0px 10px 10px 0px;
  height: 47px;
  outline: none;
  &:focus {
    border-color: #ffcb4a;
  }
`
const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const List = styled.ul`
  list-style: none;
`
const ListItemStyled = styled.li`
  border: 2px solid #ededed;
  color: #6b6b6b;
  font-weight: 500;
  font-size: 13px;
  min-height: 50px;
  height: fit-content;
  width: 100%;
  padding: 10px 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  position: relative;
  &:nth-last-child(-n + 1) {
    border-radius: 0px 0px 10px 10px;
    border-bottom: 2px solid #ededed;
  }
`
const Input = styled.input`
  border-radius: 10px 10px 0px 0px;
  border-bottom: none;
  outline: none;
  font-weight: 500;
  width: 100%;
  background: #ffffff;
  border: 2px solid #ededed;
  box-sizing: border-box;
  color: #6b6b6b;
  font-weight: 500;
  font-size: 13px;
  height: 50px;
  width: 100%;
  padding: 0 20px;
  &::placeholder {
    color: #ababab;
  }
`
const RemoveButton = styled.button`
  cursor: pointer;
  position: absolute;
  background-color: #ffffff;
  width: 26px;
  height: 26px;
  right: 10px;
  top: 50%;
  border: none;
  transform: translateY(-50%);
  border-radius: 50% !important;
  &:hover {
    transition: 0.2s;
    background-color: #f3dddb;
  }
  &::after {
    content: '';
    width: 12px;
    height: 1px;
    background-color: #f34938;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::before {
    content: '';
    width: 1px;
    height: 12px;
    background-color: #f34938;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
`
const AddButton = styled(RemoveButton)`
  background-color: #f5f5f5;
  &:hover {
    transition: 0.2s;
    background-color: #fff7e3;
    &::after {
      transition: 0.2s;
      background-color: #ffb600;
      transform: translate(-50%, -50%);
    }
    &::before {
      transition: 0.2s;
      background-color: #ffb600;
      transform: translate(-50%, -50%);
    }
  }
  &::after {
    background-color: #ababab;
    transform: translate(-50%, -50%);
  }
  &::before {
    background-color: #ababab;
    transform: translate(-50%, -50%);
  }
`
const InputListContainer = styled.span`
  position: relative;
`
export default NewJobOfferStep4

import React, { useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import CornerImage from '../../../Icons/Step1Company'
import ReactSelect from 'react-select'
import {
  CornerImageContainer,
  FormContainer,
  Label,
  LeftContainer,
  WhiteTop,
} from '../NewJobOfferStyledComponents'
import styled from 'styled-components'
import Switch from 'react-switch'
import reactSelectStyles from '../../../sharedComponents/reactSelectStyles.js'
import Button from '../../../sharedComponents/Button.jsx'
import { ACTIONS } from '../reducer.js'
import { useEffect } from 'react'

const currencyOptions = [
  {
    value: 'PLN',
    label: 'PLN',
  },
  {
    value: 'EUR',
    label: 'EUR',
  },
  {
    value: 'USD',
    label: 'USD',
  },
  {
    value: 'CHF',
    label: 'CHF',
  },
]

const NewJobOfferStep1 = ({
  positionName,
  reqExpLevel,
  minSalaryUoP,
  maxSalaryUoP,
  minSalaryB2B,
  maxSalaryB2B,
  remotePossibility,
  teamSize,
  city,
  currency,
  dispatch,
}) => {
  const history = useHistory()

  const [isRemote, setIsRemote] = useState(!!remotePossibility)
  const { state: locationState } = useLocation()
  const positionRef = useRef(null)
  const cityRef = useRef(null)

  useEffect(() => {
    setIsRemote(!!remotePossibility)
  }, [remotePossibility])

  const handleSubmit = (e) => {
    e.preventDefault()
    history.push('/create-job-offer/2')
  }
  const handleInput = (e) => {
    dispatch({
      type: ACTIONS.UPDATE,
      key: e.target.name,
      value: e.target.value,
    })
  }
  useEffect(() => {
    if (locationState?.errors?.positionName) {
      positionRef?.current?.focus()
    }
    if (locationState?.errors?.city) {
      cityRef?.current?.focus()
    }
  }, [locationState?.errors])
  return (
    <LeftContainer>
      <div className="topline-inf" />
      <CornerImageContainer>
        <CornerImage />
      </CornerImageContainer>
      <FormContainer onSubmit={handleSubmit}>
        <WhiteTop>
          <h1>Basic information</h1>
          <p>Job description</p>
        </WhiteTop>
        <div>
          <Label required htmlFor="position">
            Job title
          </Label>
          <InputWithGrayBox
            error={locationState?.errors?.positionName}
            ref={positionRef}
            id="position"
            name="positionName"
            value={positionName}
            onChange={handleInput}
            className="new-job-offer-name"
            type="text"
            placeholder="Enter name"
            required
          />
        </div>
        <div className="new-job-input-container">
          <div className="new-job-flex-sm">
            <Label htmlFor="teamSize">Team size</Label>
            <InputWithGrayBox
              id="teamSize"
              placeholder="e.g 5-10"
              name="teamSize"
              value={teamSize}
              onChange={handleInput}
            />
          </div>
          <div className="new-job-flex-md">
            <Label htmlFor="location" required>
              JOB LOCATION
            </Label>
            <InputWithGrayBox
              error={locationState?.errors?.city}
              ref={cityRef}
              id="city"
              placeholder="Warsaw"
              name="city"
              value={city}
              onChange={handleInput}
              type="text"
              required
            />
          </div>
        </div>
        <div>
          <p className="new-job-title">Level of experience</p>
          <div className="new-job-buttons-container" id="seniority">
            {['Junior', 'Regular', 'Senior'].map((seniorityItem, index) => (
              <button
                key={index}
                className={seniorityItem === reqExpLevel ? 'activeBtn' : ''}
                type="button"
                name="reqExpLevel"
                onClick={({ target }) =>
                  dispatch({
                    type: ACTIONS.UPDATE,
                    key: target.name,
                    value: target.innerText,
                  })
                }
              >
                {seniorityItem}
              </button>
            ))}
          </div>
        </div>
        <SalaryContainer>
          <div>
            <Flex space>
              <div>
                <SalaryLabel htmlFor="uopMin">Employment contract</SalaryLabel>
                <SalaryGray>Gross salary</SalaryGray>
              </div>
              <div>
                <ReactSelect
                  defaultValue={currencyOptions[0]}
                  name="currency"
                  onChange={(val) =>
                    dispatch({
                      type: ACTIONS.UPDATE,
                      key: 'currency',
                      value: val.label,
                    })
                  }
                  options={currencyOptions}
                  styles={reactSelectStyles(40)}
                />
              </div>
            </Flex>
            <SmallInputContainer className="new-job-salary-input-container">
              <Flex col>
                <SmText>From</SmText>
                <Flex>
                  <SmallInput
                    type="number"
                    value={minSalaryUoP}
                    name="minSalaryUoP"
                    onChange={(e) => {
                      dispatch({
                        type: ACTIONS.UPDATE,
                        key: e.target.name,
                        value: e.target.value,
                      })
                    }}
                    id="uopMin"
                    placeholder="Enter"
                  />
                  <span className="new-job-salary-gray-box">{currency}</span>
                </Flex>
              </Flex>
              <Flex col>
                <SmText>To</SmText>
                <Flex>
                  <SmallInput
                    type="number"
                    value={maxSalaryUoP}
                    name="maxSalaryUoP"
                    onChange={(e) => {
                      dispatch({
                        type: ACTIONS.UPDATE,
                        key: e.target.name,
                        value: e.target.value,
                      })
                    }}
                    id="uopMax"
                    placeholder="Enter"
                  />
                  <span className="new-job-salary-gray-box">{currency}</span>
                </Flex>
              </Flex>
            </SmallInputContainer>
          </div>
          <div>
            <SalaryLabel htmlFor="b2bMin">Monthly salary on B2B</SalaryLabel>
            <SalaryGray>Net salary</SalaryGray>
            <SmallInputContainer className="new-job-salary-input-container">
              <Flex col>
                <SmText>From</SmText>
                <Flex>
                  <SmallInput
                    type="number"
                    value={minSalaryB2B}
                    name="minSalaryB2B"
                    onChange={(e) => {
                      dispatch({
                        type: ACTIONS.UPDATE,
                        key: e.target.name,
                        value: e.target.value,
                      })
                    }}
                    id="b2bMin"
                    placeholder="Enter"
                  />
                  <span className="new-job-salary-gray-box">{currency}</span>
                </Flex>
              </Flex>
              <Flex col>
                <SmText>To</SmText>
                <Flex>
                  <SmallInput
                    type="number"
                    value={maxSalaryB2B}
                    name="maxSalaryB2B"
                    onChange={(e) => {
                      dispatch({
                        type: ACTIONS.UPDATE,
                        key: e.target.name,
                        value: e.target.value,
                      })
                    }}
                    id="b2bMax"
                    placeholder="Enter"
                  />
                  <span className="new-job-salary-gray-box">{currency}</span>
                </Flex>
              </Flex>
            </SmallInputContainer>
          </div>
          <div>
            <SwitchContainer>
              <SalaryLabel>Remote work</SalaryLabel>
              <Switch
                height={26}
                width={52}
                offColor="#E6E6E6"
                onColor="#FFCB4A"
                onChange={() => setIsRemote((prev) => !prev)}
                checked={isRemote}
                uncheckedIcon={false}
                checkedIcon={false}
                activeBoxShadow=""
              />
            </SwitchContainer>
            {isRemote && (
              <span className="new-job-salary-input-container">
                <FixedBorderInput
                  value={remotePossibility}
                  name="remotePossibility"
                  onChange={handleInput}
                  placeholder="0-100"
                  type="number"
                  min={0}
                  max={100}
                />
                <span className="new-job-salary-gray-box">%</span>
              </span>
            )}
          </div>
        </SalaryContainer>
        <div>
          <Button type="submit">CONTINUE</Button>
        </div>
      </FormContainer>
    </LeftContainer>
  )
}

export default NewJobOfferStep1

const SmText = styled.p`
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #3d3c3c;
  margin-bottom: 6px;
  margin-top: 14px;
`
const Flex = styled.span`
  display: flex;
  ${({ col }) => col && 'flex-direction: column;'};
  ${({ space }) => space && 'justify-content: space-between;'};
`
const SmallInputContainer = styled.span`
  justify-content: space-between;
`
const SwitchContainer = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`
const SalaryLabel = styled.label`
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #3d3c3c;
`
const SalaryGray = styled.p`
  font-size: 15px;
  line-height: 20px;
  color: #ababab;
`
const InputWithGrayBox = styled.input`
  background: #ffffff;
  border: 2px solid #ededed;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  color: #6b6b6b;
  height: 50px;
  padding: 0 20px;
  outline: none;
  flex: 1;
  &::placeholder {
    color: #ababab;
  }
  &:focus {
    border-color: #ffcb4a;
  }
  ${({ error }) =>
    error &&
    `
  border-color: #F34938 !important;
  `}
`
const FixedBorderInput = styled(InputWithGrayBox)`
  border-radius: 10px 0 0 10px;
`
const SmallInput = styled(FixedBorderInput)`
  width: 125px;
  flex: unset !important;
`

const SalaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  .new-job-salary-input-container {
    display: flex;
    align-items: center;
    .new-job-salary-gray-box {
      width: 50px;
      height: 50px;
      font-weight: 500;
      font-size: 16px;
      color: #ababab;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ededed;
      border-radius: 0px 10px 10px 0px;
    }
  }
`

import { generateMedia } from 'styled-media-query'

const media = generateMedia({
  sm: '450px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1580px',
})

export default media

import React, { useRef, useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { Link, useHistory } from 'react-router-dom'
import LoginIcon from '../Icons/LoginIcon.jsx'
import PopAuthButton from './PopAuthButton'
import HiddenLogin from './HiddenLogin'

const Signup = ({ switchLogin }) => {
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { signup, setAccount } = useAuth()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError('Passwords do not match')
    }
    try {
      setError('')
      setLoading(true)
      await signup(emailRef.current.value, passwordRef.current.value)

      const data = {
        login: emailRef.current.value,
      }
      const acc = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/account`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      )

      const accInfo = await acc.json()
      setAccount(accInfo)

      history.push('/account/company')
    } catch {
      setError('Failed to create an accout')
    }
    setLoading(false)
  }
  return (
    <span className="flex-login">
      <span className="center-login">
        <div className="login-form-box">
          <div className="login-form-box-line" />
          <div className="login-form-box-shape">
            <LoginIcon />
          </div>
          <div className="login-form">
            <h2>Sign Up</h2>
            {error && <span className="login-error">{error}</span>}
            <form onSubmit={handleSubmit}>
              <label htmlFor="email">E-mail</label>
              <input
                id="email"
                ref={emailRef}
                type="email"
                required
                placeholder="E-mail"
              ></input>
              <label htmlFor="password">Password</label>
              <input
                id="password"
                ref={passwordRef}
                type="password"
                required
                placeholder="Password"
              ></input>
              <label htmlFor="passwordConfirm">Confirm Password</label>
              <input
                id="passwordConfirm"
                ref={passwordConfirmRef}
                type="password"
                required
                placeholder="Confirm password"
              ></input>
              <button disabled={loading} type="submit">
                Sign Up
              </button>
            </form>
          </div>
          <div className="authlogin">
            Quick Sign Up
            <PopAuthButton/>
          </div>
        </div>
        <div className="login-bottom-text">
          Already have an account? <Link to="#" onClick={switchLogin}>Log In</Link>
        </div>
      </span>
      <HiddenLogin switchLogin={switchLogin} text="Log In" />
    </span>
  )
}

export default Signup
